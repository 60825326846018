<!--
 * @Description: 交易中心--交易单--交易明细
 * @Author: HuangCheng
 * @Date: 2020-03-12 18:37:25
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-15 16:50:02
 -->
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper" style="margin-bottom:50px">
      <a-form layout="inline">
        <a-row :gutter="24" style="margin-bottom:20px">
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="姓名">
              <a-input
                class="w-100"
                v-model="searchForm.payeeName"
                placeholder="请输入姓名"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="身份证号码">
              <a-input
                class="w-100"
                v-model="searchForm.payeeIdcardNo"
                placeholder="请输入身份证号码"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="手机号">
              <a-input
                class="w-100"
                v-model="searchForm.payeePhoneNo"
                placeholder="请输入手机号"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="第三方单号">
              <a-input
                class="w-100"
                v-model="searchForm.relationId"
                placeholder="请输入第三方单号"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24" style="margin-bottom:20px">
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="银行卡号">
              <a-input
                class="w-100"
                v-model="searchForm.payeeBankNo"
                placeholder="请输入银行卡号"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="认证状态">
              <a-select
                class="w-100"
                defaultValue=""
                placeholder="请选择认证状态"
                v-model="searchForm.authStatus"
                style="width: 200px"
              >
                <a-select-option
                  v-for="item in authStatus"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="签约状态">
              <a-select
                class="w-100"
                defaultValue=""
                placeholder="请选择签约状态"
                v-model="searchForm.signingStatus"
                style="width: 200px"
              >
                <a-select-option
                  v-for="item in signingStatus"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="付款状态">
              <a-select
                class="w-100"
                defaultValue=""
                placeholder="请选择付款状态"
                v-model="searchForm.payStatus"
                style="width: 200px"
              >
                <a-select-option
                  v-for="item in payStatus"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24" style="margin-bottom:20px">
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="创建时间">
              <a-range-picker v-model="date" @change="handleDateChange" />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="12">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="handleToSearchList"
                >查询</a-button
              >
              <a-button
                style="margin-left: 8px"
                icon="reload"
                @click="resetSearchForm"
                >重置</a-button
              >
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="tableColumns"
      :data="loadTradingList"
      :scroll="{ x: 2900 }"
      bordered
    >
      <span slot="payeeName" slot-scope="text, record">
        <a @click="jumpToPage(record)">{{ text }}</a>
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="handleDealRemark(record)">修改备注</a>
        <!-- <a-divider type="vertical" />
        <a @click="handleDealRow(record)">电子回单</a>
        <a-divider type="vertical" v-has="'user:payInfo'" />
        <a @click="handleSelect(record)" v-has="'user:payInfo'">付款信息</a>
        <a-divider
          type="vertical"
          v-has="'user:returnCash'"
          v-if="record.payStatus === '101421'"
        />
        <a
          @click="handleReturn(record)"
          v-has="'user:returnCash'"
          v-if="record.payStatus === '101421'"
          >退款</a
        > -->
      </span>
    </s-table>
    <!-- 电子回单 -->
    <a-modal
      title="电子回单"
      width="1000px"
      :footer="null"
      :visible="visibleReturn"
      @cancel="visibleReturn = false"
    >
      <div
        v-if="returnOrderLists.length > 0"
        class="electronic-return"
        id="printTest"
      >
        <div class="electronic-header">
          <div>
            <span>中国工商银行</span>
            <span>中国工商银行电子回单</span>
          </div>
        </div>
        <div class="electronic-nav">
          <span>电子回单号码：{{ currentReturnOrder.ebillSerialno }}</span>
          <span>生成日期：{{ currentReturnOrder.transDate }}</span>
        </div>
        <div>
          <table class="electronic-table">
            <tr>
              <td style="width: 27px" class="left-border center" rowspan="3">
                付款人
              </td>
              <td class="center">户名</td>
              <td colspan="3">{{ currentReturnOrder.payAcctName }}</td>
              <td style="width: 27px" class="center" rowspan="3">收款人</td>
              <td class="center">户名</td>
              <td>{{ currentReturnOrder.recAcctName }}</td>
            </tr>
            <tr>
              <td class="center">账号</td>
              <td colspan="3">{{ currentReturnOrder.payAccount }}</td>
              <td class="center">账号</td>
              <td colspan="3">{{ currentReturnOrder.recAccount }}</td>
            </tr>
            <tr>
              <td class="center">开户银行</td>
              <td colspan="3">{{ currentReturnOrder.payBankName }}</td>
              <td class="center">开户银行</td>
              <td colspan="3">{{ currentReturnOrder.recBankName }}</td>
            </tr>
            <tr>
              <td class="left-border center" colspan="2">金额</td>
              <td colspan="6">￥{{ currentReturnOrder.payAmt }}元</td>
            </tr>
            <tr>
              <td class="left-border center" colspan="2">摘要</td>
              <td colspan="3">{{ currentReturnOrder.summary }}</td>
              <td colspan="2" class="center">业务产品种类</td>
              <td>{{ currentReturnOrder.prodType }}</td>
            </tr>
            <tr>
              <td class="left-border center" colspan="2">用途</td>
              <td colspan="6">
                {{ currentReturnOrder.useCN }}
              </td>
            </tr>
            <tr>
              <td class="left-border center" colspan="2">交易流水号</td>
              <td colspan="3">{{ currentReturnOrder.tranSerialNo }}</td>
              <td colspan="2" class="center">时间戳</td>
              <td>{{ currentReturnOrder.timeStamp }}</td>
            </tr>
            <!-- <tr>
              <td class="left-border center" colspan="2">其它</td>
              <td colspan="6">
                备注信息：{{currentReturnOrder.remark}}<br>
                票据号码：{{currentReturnOrder.refundMsg}}
              </td>
            </tr> -->
            <tr>
              <td class="left-border" colspan="2" rowspan="2">
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    padding: 10px 0;
                  "
                >
                  <!-- <img style="width:96px;height:96px" src="../../../assets/tuzhang.gif" alt=""> -->
                </div>
              </td>
              <td colspan="6">备注：{{ currentReturnOrder.remark }}</td>
            </tr>
            <tr>
              <td colspan="6">验证码：{{ currentReturnOrder.ebillKey }}</td>
            </tr>
            <tr>
              <td class="left-border bottom-border center" colspan="2">
                记账网点
              </td>
              <td class="bottom-border">
                {{ currentReturnOrder.transNetCode }}
              </td>
              <td class="bottom-border center">记账柜员</td>
              <td class="bottom-border">
                {{ currentReturnOrder.transTellno }}
              </td>
              <td class="bottom-border center" colspan="2">记账日期</td>
              <td class="bottom-border">{{ currentReturnOrder.transDate }}</td>
            </tr>
          </table>
        </div>
        <div class="return-footer">
          <p>重要提示：</p>
          <p>
            1.如果您是收款方,请到工行网站www.icbc.com.cn电子回单验证处进行回单验证。
          </p>
          <p>2.本回单不作为收款方发货依据,并请勿重复记账。</p>
          <p>3.您可以选择发送邮件,将此电子回单发送给指定的接收人。</p>
        </div>
        <div class="pagenation" v-if="!ishide && total > 10">
          <a-pagination
            :default-current="1"
            :total="total"
            @change="onChange"
          />
        </div>
        <div class="operation" v-if="!ishide">
          <a-button @click="visibleReturn = false">取消</a-button>
          <a-button @click="hidebtn" type="primary" v-print="'#printTest'"
            >打印</a-button
          >
        </div>
      </div>
      <div v-else>
        <div class="empty">暂无数据</div>
      </div>
    </a-modal>
    <!-- 付款信息 -->
    <a-modal
      title="付款信息"
      width="100%"
      class="detail-modal"
      :footer="null"
      :visible="visibleDetailModal"
      @cancel="visibleDetailModal = false"
    >
      <!-- <selectDetail v-if="visibleDetailModal" :id="currentId" /> -->
    </a-modal>
    <!-- 修改备注 -->
    <a-modal
      title="修改备注"
      width="600px"
      destroyOnClose
      :visible="visibleExamineModalSh"
      @ok="handleRechargeModalOkSh"
      @cancel="handleExamineModalCancelSh"
    >
      <a-form :form="sendForm">
        <a-row class="form-row">
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="备注"
              :labelCol="{ lg: { span: 4 }, sm: { span: 5 } }"
              :wrapperCol="{ lg: { span: 20 }, sm: { span: 19 } }"
            >
              <a-textarea
                placeholder="请输入备注(仅限20字)"
                :maxLength="20"
                :auto-size="{ minRows: 3, maxRows: 5 }"
                v-decorator="[
                  'remark',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入备注',
                        whitespace: true,
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </a-card>
</template>
<script>
import moment from "moment";
import STable from "@/components/table/";
import AInput from "ant-design-vue/es/input/Input";
import { getArrItem } from "@/utils/util.js";
// import selectDetail from './fkDetail'
import {
  getEnterpriseTradeDetailedList,
  getWalletWithdrawalBankReceipt,
  tradeRefund,
  updateRemark,
} from "@/api/api";

export default {
  name: "TransactionDetail",
  components: {
    AInput,
    STable,
    // selectDetail,
  },
  data() {
    return {
      visibleExamineModalSh: false,
      record: "",
      date: [],
      visibleDetailModal: false,
      currentId: "",
      searchForm: {
        payeeName: "",
        payeeIdcardNo: "",
        payeePhoneNo: "",
        payeeBankNo: "",
        createTimeStart: "",
        createTimeEnd: "",
        relationId: "",
      },
      visibleReturn: false,
      authStatus: [
        {
          value: "102201",
          label: "未认证",
        },
        {
          value: "102211",
          label: "认证成功",
        },
        {
          value: "102231",
          label: "认证失败",
        },
      ],
      signingStatus: [
        {
          value: "102201",
          label: "未签约",
        },
        {
          value: "102211",
          label: "签约成功",
        },
        {
          value: "102231",
          label: "签约失败",
        },
      ],
      payStatus: [
        {
          value: "101401",
          label: "未付款",
        },
        {
          value: "101402",
          label: "付款中",
        },
        {
          value: "101411",
          label: "付款成功",
        },
        {
          value: "101421",
          label: "付款失败",
        },
        {
          value: "101422",
          label: "董监高验证失败",
        },
        {
          value: "101423",
          label: "付款失败，余额不足",
        },
        {
          value: "101424",
          label: "提现失败已退回",
        },
        {
          value: "101425",
          label: "付款失败，认证信息失败",
        },
        {
          value: "101426",
          label: "付款失败，用户未签约",
        },
        {
          value: "101427",
          label: "付款失败，用户交易被风控",
        },
      ],
      tableColumns: [
        {
          title: "姓名",
          dataIndex: "payeeName",
          align: "center",
          scopedSlots: { customRender: "payeeName" },
          fixed: "left",
          width: "100px",
        },
        {
          title: "ID",
          dataIndex: "id",
          align: "center",
          width: "200px",
        },
        {
          title: "身份证号码",
          align: "center",
          dataIndex: "payeeIdcardNo",
          width: "200px",
        },
        {
          title: "手机号",
          align: "center",
          dataIndex: "payeePhoneNo",
          width: "140px",
        },
        {
          title: "银行卡号",
          align: "center",
          dataIndex: "payeeBankNo",
          width: "220px",
        },
        {
          title: "银行名称",
          align: "center",
          dataIndex: "payeeBankName",
          width: "200px",
        },
        {
          title: "实际收入金额(元)",
          align: "center",
          dataIndex: "incomeAmount",
          customRender: (text) => text.toFixed(2),
          width: "200px",
        },
        {
          title: "费用金额(元)",
          align: "center",
          dataIndex: "feeAmount",
          customRender: (text) => text.toFixed(2),
          width: "180px",
        },
        {
          title: "费用扣点",
          align: "center",
          dataIndex: "feePoint",
          customRender: (text) => (text * 100).toFixed(2) + "%",
          width: "100px",
        },
        {
          title: "付款金额(元)",
          align: "center",
          dataIndex: "payAmount",
          customRender: (text) => text.toFixed(2),
          width: "200px",
        },
        {
          title: "认证状态",
          align: "center",
          dataIndex: "authStatus",
          customRender: (text) => getArrItem(text, this.authStatus),
          width: "100px",
        },
        {
          title: "认证错误原因",
          align: "center",
          dataIndex: "authErrorInfo",
          width: "200px",
        },
        {
          title: "签约状态",
          align: "center",
          dataIndex: "signingStatus",
          customRender: (text) => getArrItem(text, this.signingStatus),
          width: "100px",
        },
        {
          title: "付款状态",
          align: "center",
          dataIndex: "payStatus",
          customRender: (text) => getArrItem(text, this.payStatus),
          width: "160px",
        },
        {
          title: "付款失败原因",
          align: "center",
          dataIndex: "payErrorInfo",
          width: "200px",
        },
        {
          title: "备注",
          align: "center",
          dataIndex: "remark",
          width: "200px",
        },
        {
          title: "创建时间",
          align: "center",
          dataIndex: "createTime",
          width: "200px",
        },
        {
          title: "支付时间",
          align: "center",
          dataIndex: "payTime",
          width: "200px",
        },
        {
          title: "第三方单号",
          dataIndex: "relationId",
          align: "center",
          width: "200px",
        },
        {
          title: "操作",
          align: "center",
          dataIndex: "action",
          width: "250px",
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      currentReturnOrder: {
        ebillSerialno: "",
        PayAccount: "",
        PayAcctName: "",
        payBankName: "",
        recAccount: "",
        recAcctName: "",
        recBankName: "",
        payAmt: "",
        Summary: "",
        busType: "",
        useCN: "",
        tranSerialNo: "",
        timeStamp: "",
        remark: "",
        ebillKey: "",
        transNetCode: "",
        transTellno: "",
        TransDate: "",
        rePrintNum: "",
        prodType: "",
        bankSerialNo: "",
        refundMsg: "",
        repReserved2: "",
        postScript: "",
      },
      returnOrderLists: [1],
      total: 10,
      ishide: false,
      sendForm: this.$form.createForm(this),
      ticketLists: [
        "转账",
        "现金",
        "现金存入",
        "同城转账",
        "汇划收报",
        "跨行收报",
        "利息入账",
        "即时通",
        "对公收费",
        "法人理财",
        "基金",
        "债卷",
        "贵金属",
        "跨行发报",
        "汇划发报",
        "公益捐款",
        "结构性存款",
        "电子商务",
        "单位定期（通知）存款",
        "对公贷款",
        "代理业务",
        "银证业务",
        "银期业务",
        "银商业务",
        "银关业务",
        "银税业务",
        "银银合作",
        "银财业务",
        "全球现金管理业务",
        "通存通兑－个人汇款",
        "网银互联",
        "贷款发放",
        "贷款归还",
        "贷款红蓝冲借",
        "非信贷资产",
        "抵债资产",
        "垫款",
        "贵金属收费",
        "贷款付息",
        "委托贷款收费",
        "表外贷款手续费",
        "外汇业务",
        "特约商户POS",
        "保证金业务",
        "BSP和SLB",
        "单证中心",
        "综合收付款",
        "资金池",
        "养老金理财",
        "存款转换",
        "委托收款",
        "托收承付",
        "交易市场托管",
      ],
    };
  },
  props: {
    id: {
      type: String,
      default: "",
      required: false,
    },
  },
  methods: {
    // 修改备注
    handleDealRemark(record) {
      this.record = record;
      this.visibleExamineModalSh = true;
    },
    handleRechargeModalOkSh() {
      this.sendForm.validateFields((err, fieldsValue) => {
        if (err) {
          return;
        }
        let params = {};
        const description = this.sendForm.getFieldsValue();
        params = Object.assign({}, description, {
          id: this.record.id,
        });
        updateRemark(params).then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.handleExamineModalCancelSh();
            this.handleToSearchList();
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
    handleExamineModalCancelSh() {
      this.sendForm.resetFields();
      this.visibleExamineModalSh = false;
    },
    // 电子回单
    handleDealRow(record) {
      this.visibleReturn = true;
      getWalletWithdrawalBankReceipt(record.id).then((res) => {
        if (res.success) {
          this.returnOrderLists = res.result;
          if (this.returnOrderLists.length > 0) {
            this.returnOrderLists.forEach((item) => {
              item.receiptResult = JSON.parse(item.receiptResult);
              item.receiptResult.payAmt = (
                item.receiptResult.payAmt / 100
              ).toFixed(2);
              this.ticketLists.forEach((list, index) => {
                if (item.receiptResult.prodType === index) {
                  item.receiptResult.prodType = index + "：" + list;
                }
              });
            });
            this.currentReturnOrder = this.returnOrderLists[0].receiptResult;
            this.total = this.returnOrderLists.length * 10;
          }
        }
      });
    },
    // 分页
    onChange(pageNumber) {
      this.currentReturnOrder = this.returnOrderLists[
        pageNumber - 1
      ].receiptResult;
    },
    // 打印
    hidebtn() {
      this.ishide = true;
      setTimeout(() => {
        this.ishide = false;
      }, 10);
    },
    // 跳转
    jumpToPage(record) {
      this.$router.push({
        name: "freeManagement",
        params: { payeeName: record.payeeName },
      });
    },
    /**
     * @description: 创建时间段选择
     * @param : date date
     * @param : dateString 时间数组
     * @return: void
     * @Author: HuangCheng
     */
    handleDateChange(date, dateString) {
      this.searchForm.createTimeStart = `${dateString[0]} 00:00:00`;
      this.searchForm.createTimeEnd = `${dateString[1]} 23:59:59`;
    },
    /**
     * @description: 加载列表数据
     * @param : parameter 查询参数
     * @return: promise
     * @Author: HuangCheng
     */
    loadTradingList(parameter) {
      return getEnterpriseTradeDetailedList(
        Object.assign(parameter, {
          tradeId: this.id,
          ...this.searchForm,
        })
      ).then((res) => {
        if (!this.$store.state.user.userRules.includes("user:setStar")) {
          if (res.result.data.length > 0) {
            res.result.data.forEach((item) => {
              let phone = /(\d{3})\d{4}(\d{4})/;
              let bank = /(\d{3})\d{1,}(\d{4})/;
              let idcard = /(\d{3})\d{11}(\w{4})/;
              item.payeePhoneNo = item.payeePhoneNo.replace(phone, "$1****$2");
              item.payeeBankNo = item.payeeBankNo.replace(
                bank,
                "$1************$2"
              );
              item.payeeIdcardNo = item.payeeIdcardNo.replace(
                idcard,
                "$1***********$2"
              );
            });
          }
        }
        return res.result;
      });
    },
    /**
     * @description: 查询交易明细列表
     * @Author: HuangCheng
     */
    handleToSearchList() {
      this.$refs.table.refresh(true);
    },
    /**
     * @description: 重置搜索条件
     * @param :
     * @return:
     * @Author: HuangCheng
     */
    resetSearchForm() {
      this.searchForm = {
        payeeName: "",
        payeeIdcardNo: "",
        payeePhoneNo: "",
        payeeBankNo: "",
        createTimeStart: "",
        createTimeEnd: "",
        relationId: "",
      };
      this.date = [];
      this.handleToSearchList();
    },
    // 付款信息
    handleSelect(record) {
      this.currentId = record.id;
      this.visibleDetailModal = true;
    },
    // 退款
    handleReturn(record) {
      this.$confirm({
        title: "确认退款?",
        onOk: () => {
          tradeRefund({ id: record.id }).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.handleToSearchList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
        onCancel() {},
      });
    },
  },
};
</script>
<style lang="less">
// @import '~@/assets/less/index.less';
.detail-modal {
  top: 0;
  height: 100vh;
  .ant-modal-content {
    height: 100vh;
    .ant-modal-body {
      height: calc(100% - 55px);
      padding: 0;
    }
  }
}
</style>
<style lang="less" scoped>
.electronic-return {
  .electronic-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .electronic-header {
    display: flex;
    justify-content: center;
    margin: 0 0 30px 0;
    div {
      width: 500px;
      border-bottom: 2px solid #000;
      padding-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      span {
        margin-right: 20px;
        font-size: 24px;
        &:last-child {
          font-size: 16px;
        }
      }
    }
  }
  .electronic-table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    tr {
      td {
        border: 2px solid #000;
        border-top: 0;
        border-right: 0;
        font-size: 16px;
        padding: 0 5px;
        color: #000;
        &:last-child {
          border-right: 4px solid #000;
        }
      }
      &:first-child {
        td {
          border-top: 4px solid #000;
        }
      }
    }
    .left-border {
      border-left: 4px solid #000;
    }
    .bottom-border {
      border-bottom: 4px solid #000;
    }
    .center {
      text-align: center;
    }
  }
  .return-footer {
    margin: 20px 0 0 0;
    p {
      margin: 0 0 5px 0;
      font-size: 16px;
      color: #000;
    }
  }
  .pagenation {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
  }
  .operation {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 10px;
    }
  }
}
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #6d6767;
  height: 300px;
}
</style>