<template>
  <div v-if="raskDetailInfo">
    <div class="header"><span class="ret" @click="ret">任务管理/</span> <span>任务详情</span></div>
    <div class="header-one">
      <div class="rask-title">
        <div class="rt-left">
          <div class="rt-left-title">{{raskDetailInfo.title}}</div>
          <div class="rt-left-time">{{raskDetailInfo.createTime}}发布</div>
        </div>
        <div class="rt-right">
          <a-button v-if="raskDetailInfo.status==='13'" @click="raskEnd">结束报名</a-button>
          <a-button v-if="canCancel" @click="cancelRask" style="margin-left:12px">取消任务</a-button>
        </div>
      </div>
      <div class="rask-content">
        <a-row :gutter="24">
          <a-col :span="8" class="rc-mgb">任务类别：{{raskTypeName}}</a-col>
          <a-col :span="8" class="rc-mgb">报名时间：{{raskDetailInfo.enrollBeginDate}} 至 {{raskDetailInfo.enrollEndDate}}</a-col>
          <a-col :span="8" class="rc-mgb">任务时间：{{raskDetailInfo.workBeginDate}} 至 {{raskDetailInfo.workEndDate}}</a-col>
          <a-col :span="8" class="rc-mgb">预计人数：{{raskDetailInfo.needNum}}</a-col>
          <a-col :span="8" class="rc-mgb">预算金额：{{raskDetailInfo.budgetAmount===null?'--':raskDetailInfo.budgetAmount.toFixed(2)}}</a-col>
          <a-col :span="8" class="rc-mgb">
            任务状态：
            <span v-if="raskDetailInfo.status==='10'">待审核</span>
            <span v-if="raskDetailInfo.status==='11'">审核失败</span>
            <span v-if="raskDetailInfo.status==='13'">报名中</span>
            <span v-else-if="raskDetailInfo.status==='14'">报名截止</span>
            <span v-else-if="raskDetailInfo.status==='20'">进行中</span>
            <span v-else-if="raskDetailInfo.status==='30'">已完成</span>
            <span v-else-if="raskDetailInfo.status==='50'">已取消</span>
          </a-col>
          <a-col :span="8" class="rc-mgb">结算类型：{{raskDetailInfo.payMode==='10'?'单次结算':'多次结算'}}</a-col>
          <a-col :span="8" class="rc-mgb" v-if="raskDetailInfo.resultProvide!==null">上传类型：{{raskDetailInfo.resultProvide==='10'?'个人上传':'企业上传'}}</a-col>
          <a-col :span="8" class="rc-mgb">任务描述：{{raskDetailInfo.content}}</a-col>
        </a-row>
      </div>
    </div>
    <!-- <div class="search">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :lg="6" :md="8" :sm="12">
            <a-form-item label="任务名称">
              <a-input placeholder="请输入任务名称" />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="8" :sm="12">
            <div class="table-search-submitButtons">
              <a-button type="primary" @click="handleToSearchEnterprise">查询</a-button>
              <a-button style="margin-left: 8px;" icon="reload" @click="resetSearchEnterprise">重置</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </div> -->
    <div class="content">
      <a-tabs default-active-key="1" @change="callback">
        <!-- 报名人员 -->
        <a-tab-pane key="1" :tab="totalBmry">
          <div>
            <div class="bmry">
              <a-button type="primary" @click="plSign" :disabled="!hasSelected">批量用工</a-button>
              <a-button style="margin-left:20px" type="primary" :disabled="raskDetailInfo.status==='13'?false:true" @click="invite">邀请人员</a-button>
            </div>
            <s-table
              ref="bmryTable"
              style="border-top:1px solid #F0F0F0;margin-top:16px"
              :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
              :columns="columnsBmry"
              :data="dataBmry"
              :rowKey="(record) => record.id"
            >
              <span slot="action" slot-scope="text, record" v-if="raskDetailInfo.status!=='50'">
                <a @click="sign(record)" v-if="record.signStatus==='10'&&raskDetailInfo.status==='13'">用工</a>
                <a-divider type="vertical" v-if="record.signStatus==='10'&&raskDetailInfo.status==='13'" />
                <a @click="refuse(record)" v-if="record.signStatus==='10'&&raskDetailInfo.status==='13'">拒绝</a>
              </span>
              <span slot="userName" slot-scope="text,record">
                <a-popover title="">
                  <template slot="content">
                    <div class="pop-content">
                      <div>
                        <img class="img" :src="record.avatarUrl" alt="">
                      </div>
                      <div>
                        <div class="person"><span>{{record.userName}}</span><span>{{record.phoneNo}}</span></div>
                        <div class="construction">{{record.oneselfRemark}}</div>
                      </div>
                    </div>
                    <div class="tags">{{record.oneselfLable}}</div>
                  </template>
                  <a-button style="padding:0;" type="link">
                    {{record.userName}}
                  </a-button>
                </a-popover>
              </span>
              <span slot="signStatus" slot-scope="text">
                <div class="table-point" v-if="text==='10'"><span style="background:#1890FF"></span> 已报名</div>
                <div class="table-point" v-else-if="text==='92'"><span style="background:#52C41A"></span> 已拒绝</div>
                <div class="table-point" v-else><span style="background:#BFBFBF"></span> 已取消</div>
              </span>
            </s-table>
            <!-- 签约确认单 -->
            <a-modal
              title="用工确认单"
              :visible="visible"
              :confirm-loading="confirmLoading"
              @ok="handleOk"
              @cancel="handleCancel"
            >
              <ul class="model">
                <li>
                  <span class="span">签约人员：</span>
                  <div class="tag">
                    <a-tag v-for="(item,index) in bmryParams.selectedTotalBmry" :key="index">{{item.userName}}</a-tag>
                    <span>共{{bmryParams.selectedTotalBmry.length}}人</span>
                  </div>
                </li>
                <li>
                  <span class="span">任务金额：</span>
                  <div>
                    <div><a-input type="number" :maxLength="11" v-model="bmryParams.raskRash" style="width:200px" min="0" placeholder="请输入任务金额"></a-input></div>
                    <div class="mgt-5">
                      平均每人任务金额{{avregeCash}}元<br>
                      费用金额{{fyCash}}元<br>
                      <span>实际总金额为<span style="color:#FF4D4F">{{localPay}}元</span></span>
                    </div>
                  </div>
                </li>
                <li>
                  <span class="span">预付金额：</span>
                  <div>
                    <div><a-input :maxLength="11" v-model="bmryParams.prePay" @change="fiexed" :min="0" :max="100" :addonAfter="tjPrePay" type="number" suffix="%" style="width:100%" placeholder="预付金额"></a-input></div>
                    <div class="mgt-5">平均每人预付金额{{prePayCash}}元</div>
                  </div>
                </li>
              </ul>
            </a-modal>
            <!-- 发送邀请 -->
            <a-modal
              title="发送邀请"
              width="720px"
              :visible="visibleYq"
              :footer="null"
              @cancel="handleCancelYq"
            >
              <div class="fsyq-head">
                <div>
                  <div class="fsyq-title">请输入需要被邀请人的手机号码</div>
                  <div>
                    <a-input-search v-model="inviteUserPhone" style="width:360px;height:32px" placeholder="请输入手机号码" @search="onSearchInvite">
                      <a-button slot="enterButton">
                        搜索
                      </a-button>
                    </a-input-search>
                  </div>
                </div>
                <div class="user-info" v-if="hasInvitor">
                  <div class="img" v-if="currentItem">{{currentItem.userName.slice(0,1)}}</div>
                  <div v-if="currentItem">{{currentItem.userName}}</div>
                </div>
              </div>
              <div class="common-content" v-if="hasInvitor">
                <div class="cc-title">服务价格信息</div>
                <div class="cc-desc">请确认用工人员的服务价格</div>
                <div class="cc-content">
                  <span>任务金额</span>
                  <a-input v-model="rwje" :maxLength="11" min="0" @change="onChangeRwje" style="width:160px;margin-left:16px" type="number" suffix="元"></a-input>
                  <span style="margin-left:20px;">费用：{{fy}}元</span>
                  <span style="margin-left:20px;">总金额：<span style="color:#FF4D4F">{{allFy}}</span>元</span>
                </div>
                <div class="cc-content">
                  <span>预付金额</span>
                  <a-input :maxLength="11" :readOnly="yfje===0?false:true" min="0" @blur="tofixed" v-model="inputYfje" style="width:160px;margin:0 0 0 16px" type="number" suffix="元"></a-input>
                  <a-radio-group style="margin-left:20px" name="radioGroup" v-model="yfje"  @change="onChangeYfje">
                    <a-radio :value="0.1">
                      10%
                    </a-radio>
                    <a-radio :value="0.2">
                      20%
                    </a-radio>
                    <a-radio :value="0.3">
                      30%
                    </a-radio>
                    <a-radio :value="0.4">
                      40%
                    </a-radio>
                    <a-radio :value="0">
                      自定义
                    </a-radio>
                  </a-radio-group>
                </div>
                <div class="cc-footer">
                  <div class="ccf-left">
                    <div>预付款：<span>{{inputYfje}}<span style="font-size:20px">元</span></span></div>
                    <div>双方达成合作后付款</div>
                  </div>
                  <div class="ccf-right">
                    <div>尾款：<span>{{wk}}<span style="font-size:20px">元</span></span></div>
                    <div>任务完成后付款</div>
                  </div>
                </div>
              </div>
              <div class="no-invite" v-if="!hasInvitor&&stepTwoShowContentYq">
                <div>该用户暂未注册分时领英小程序平台</div>
                <div style="margin-top:20px"><a-button type="link" @click="sendDx">发送短信邀请</a-button></div>
              </div>
              <div class="common-opt">
                <a-button @click="handleCancelYq">取消</a-button>
                <a-button type="primary" style="margin-left:10px" :loading='confirmLoadingYq' :disabled="!hasInvitor" @click="handleOkYq">确认发送</a-button>
              </div>
            </a-modal>
          </div>
        </a-tab-pane>
        <!-- 签约人员 -->
        <a-tab-pane key="2" class="task" :tab="totalQyry" force-render>
          <s-table
            ref="qyryTable"
            style="border-top:1px solid #F0F0F0;margin-top:16px"
            :columns="columnsQyry"
            :data="dataQyry"
            :rowKey="(record) => record.id"
          >
            <span slot="action" slot-scope="text, record" v-if="raskDetailInfo.status!=='50'">
              <a v-if="record.signStatus==='20'" @click="cancelSign(record)">取消用工</a>
            </span>
            <span slot="signStatus" slot-scope="text">
              <div class="table-point" v-if="text==='20'"><span style="background:#1890FF"></span> 待确认</div>
              <div class="table-point" v-else-if="text==='30'"><span style="background:#52C41A"></span> 已确认</div>
              <div class="table-point" v-else-if="text==='93'"><span style="background:#F00"></span> 未接受</div>
              <div class="table-point" v-else><span style="background:#BFBFBF"></span> 已取消</div>
            </span>
          </s-table>
        </a-tab-pane>
        <!-- 付款信息 -->
        <a-tab-pane key="3" :tab="totalFkxx">
          <div>
            <div class="fkxx-title" v-if="notYfuJs"><a-icon theme="filled" style="color:#1890ff;margin-right:2px" type="info-circle" />提交付款后结算单可开启任务。</div>
            <div class="fkxx-title" v-else><a-icon theme="filled" style="color:#1890ff;margin-right:2px" type="info-circle" />等待财务人员审核付款。</div>
            <a-tabs type="card" @change="callbackFk" :animated="true">
              <!-- 预付款 -->
              <a-tab-pane key="1" tab="预付款">
                <div class="fkxx-card" v-if="notYfuJs && yfkPre">
                  <div class="fkxxc-title">
                    <span>预付款结算单</span>
                    <a-button type="primary" :disabled="(raskDetailInfo.status!=='50'?false:true)||disabledBtn1" @click="subYfk" :loading="tjjsLoading">提交结算</a-button>
                  </div>
                  <div class="fkxx-content">
                    <span>预付款总额：{{yfkPre.payAmount}}</span>
                    <span>付款人数：{{yfkPre.payNum}}</span>
                  </div>
                </div>
                <div class="fkxx-card" v-if="!notYfuJs && yfkPre">
                  <div class="fkxxc-nn">
                    <span class="fkxxc-nn-left"><a-icon type="check-circle" theme="filled" style="color:rgb(82 196 26);font-size:20px;margin-right:10px" />预付款结算单已提交</span>
                    <span class="fkxxc-nn-right" v-show="yfkLater.payStatus!=='30'">未付款</span>
                  </div>
                  <div class="fkxx-content">
                    <span>预付款总额：{{yfkLater.payAmount}}</span>
                    <span>付款人数：{{yfkLater.payNum}}</span>
                  </div>
                </div>
                <s-table
                  style="border-top:1px solid #F0F0F0;margin-top:16px"
                  ref="yfkTable"
                  :columns="columnsYfkjsd"
                  :data="dataYfkjsd"
                  :rowKey="(record) => record.id"
                >
                </s-table>
              </a-tab-pane>
              <!-- 尾款 -->
              <a-tab-pane key="2" tab="尾款">
                <div class="fkxx-card" v-if="notWkJs && wkPre">
                  <div class="fkxxc-title">
                    <span>尾款结算单</span>
                    <a-button type="primary" :disabled="(raskDetailInfo.status!=='50'?false:true)||disabledBtn2" @click="subWk" :loading="tjjsWkLoading">提交结算</a-button>
                    <a-button type="primary" v-if="raskDetailInfo.resultProvide==='20'" @click="uploadBtn" :disabled="raskDetailInfo.status==='20'?false:true">上传结果</a-button>
                  </div>
                  <div class="fkxx-content">
                    <span>尾款总额：{{wkPre.payAmount}}</span>
                    <span>付款人数：{{wkPre.payNum}}</span>
                  </div>
                </div>
                <div class="fkxx-card" v-if="!notWkJs && wkPre">
                  <div class="fkxxc-nn">
                    <span class="fkxxc-nn-left"><a-icon type="check-circle" theme="filled" style="color:rgb(82 196 26);font-size:20px;margin-right:10px" />尾款结算单已提交</span>
                    <span class="fkxxc-nn-right" v-show="wkLater.payStatus!=='30'">未付款</span>
                  </div>
                  <div class="fkxx-content">
                    <span>尾款总额：{{wkLater.payAmount}}</span>
                    <span>付款人数：{{wkLater.payNum}}</span>
                  </div>
                </div>
                <s-table
                  style="border-top:1px solid #F0F0F0;margin-top:16px"
                  ref="wkTable"
                  :columns="columnsWkjsd"
                  :data="dataWkjsd"
                  :rowKey="(record) => record.id"
                >
                </s-table>
                <a-modal
                  title="详情"
                  :visible="visibleTaskDetail"
                  @cancel="visibleTaskDetail=false"
                  width="1000px"
                  class="detail-modal"
                  :footer="null"
                >
                  <div v-if="raskDetailInfo.resultProvide==='20'" style="margin:0 0 20px 0;"><a-alert message="请您以认真严谨的态度，上传真实、有效、与任务相关的服务成果；服务成果包括但不限于：销售方本人在服务场景中的照片、设计策划稿件照片、汽车租赁的产权证、商品采购对上游的汇款凭证、专业技术服务对应的服务成果图片。" banner /></div>
                  <div>
                    <a-button type="primary" @click="plUpload" v-if="disabledBtn2">统一上传</a-button>
                    <a-button type="primary" disabled="true" v-else>统一上传</a-button>
                  </div>
                  <s-table
                    style="border-top:1px solid #F0F0F0;margin-top:16px"
                    ref="yfkTableDetail"
                    :columns="columnsWkjsds"
                    :data="dataWkjsds"
                    :rowKey="(record) => record.id"
                  >
                    <!-- <span slot="cycle" slot-scope="text, record">
                      <span>{{record.payPeriodStartTime}} ~ {{record.payPeriodEndTime}}</span>
                    </span> -->
                    <span slot="action" slot-scope="text, record">
                      <a @click="uploadResult(record)" v-if="record.payStatus==='10'&&raskDetailInfo.resultProvide&&raskDetailInfo.resultProvide==='20'">上传结果</a>
                    </span>
                  </s-table>
                  <a-modal title="上传任务结果" :confirmLoading="confirmLoadingUpload" width="600px" destroyOnClose :visible="visibleExamineModal" @ok="handleRechargeModalOk" @cancel="handleExamineModalCancel">
                    <a-form :form="examinForm">
                      <a-row class="form-row">
                        <a-col :lg="24" :md="24" :sm="24">
                          <a-form-item label="任务结果" :labelCol="{lg: {span: 4}, sm: {span: 5}}" :wrapperCol="{lg: {span: 20}, sm: {span: 19} }">
                            <a-upload
                              :action="url.frontUrl"
                              :multiple="true"
                              :headers="headers"
                              :file-list="fileList"
                              :data="uploadData"
                              :beforeUpload="beforeUpload"
                              @change="handleChange"
                            >
                              <a-button> <a-icon type="upload" /> 上传 </a-button>
                            </a-upload>
                          </a-form-item>
                        </a-col>
                        <a-col :lg="24" :md="24" :sm="24">
                          <a-form-item label="备注" :labelCol="{lg: {span: 4}, sm: {span: 5}}" :wrapperCol="{lg: {span: 20}, sm: {span: 19} }">
                            <a-textarea placeholder="请填写备注" v-decorator="['content', {rules: [{ required: false, message: '请填写备注', whitespace: true}]}]"></a-textarea>
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                  </a-modal>
                </a-modal>
              </a-tab-pane>
            </a-tabs>
          </div>
        </a-tab-pane>
        <!-- 任务审核 -->
        <a-tab-pane key="4" :tab="totalRwsh">
          <div style="margin:10px 0;"><a-alert message="请您严格审核自由职业者上传的服务成果，成果必须与发布的任务相关且真实有效，服务成果包括但不限于：销售方本人在服务场景中的照片、设计策划稿件照片、汽车租赁的产权证、商品采购对上游的汇款凭证、专业技术服务对应的服务成果图片。如服务成果不能提供客观图像资料，可能无法通过本平台开具发票。" banner /></div>
          <a-form layout="inline">
            <a-row :gutter="48">
              <a-col :lg="6" :md="8" :sm="12">
                <a-form-item label="用户名称">
                  <a-input style="width:200px" v-model="queryParam.userName" placeholder="请输入姓名"/>
                </a-form-item>
              </a-col>
              <a-col :lg="6" :md="8" :sm="24">
                <a-form-item label="审核状态">
                  <a-select style="width:200px" placeholder="请选择审核状态" v-model="queryParam.auditStatus">
                    <a-select-option value="10">企业待审核</a-select-option>
                    <a-select-option value="20">已通过</a-select-option>
                    <a-select-option value="30">已驳回</a-select-option>
                    <a-select-option value="11">平台待审核</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :lg="6" :md="8" :sm="12">
                <span class="table-page-search-submitButtons">
                  <a-button style="margin-left: 12px;" type="primary" @click="handleToSearchEnterprises">查询</a-button>
                  <a-button style="margin-left: 12px;" icon="reload" @click="resetSearchEnterprises">重置</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form> 
          <div>
            <s-table
            style="border-top:1px solid #F0F0F0;margin-top:16px;"
            ref="rwshTable"
            :columns="columnsDsh"
            :data="dataDsh"
            :rowKey="(record) => record.id"
          >
            <span slot="action" slot-scope="text, record" v-if="raskDetailInfo.status!=='50'">
              <a @click="pass(record)" v-if="record.auditStatus==='10'">通过</a>
              <a-divider type="vertical" v-if="record.auditStatus==='10'" />
              <a @click="noPass(record)" v-if="record.auditStatus==='10'">驳回</a>
            </span>
            <div slot="items" slot-scope="text, record">
              <a @click="showPic(record)">查看</a>
              <!-- <a :href="item.fileUrl" target="blink" v-for="(item,index) in record.items" :key="index">
                <img v-if="item.fileUrl.indexOf('png')!==-1||item.fileUrl.indexOf('jpg')!==-1||item.fileUrl.indexOf('JPEG')!==-1||item.fileUrl.indexOf('bmp')!==-1||item.fileUrl.indexOf('tif')!==-1||item.fileUrl.indexOf('gif')!==-1||item.fileUrl.indexOf('pcx')!==-1||item.fileUrl.indexOf('tga')!==-1||item.fileUrl.indexOf('exif')!==-1||item.fileUrl.indexOf('fpx')!==-1||item.fileUrl.indexOf('svg')!==-1||item.fileUrl.indexOf('psd')!==-1||item.fileUrl.indexOf('cdr')!==-1||item.fileUrl.indexOf('pcd')!==-1||item.fileUrl.indexOf('dxf')!==-1||item.fileUrl.indexOf('ufo')!==-1||item.fileUrl.indexOf('eps')!==-1||item.fileUrl.indexOf('ai')!==-1||item.fileUrl.indexOf('raw')!==-1||item.fileUrl.indexOf('WMF')!==-1||item.fileUrl.indexOf('webp')!==-1||item.fileUrl.indexOf('avif')!==-1" :src="item.fileUrl" alt="" style="width:25px;height:25px;margin-right:10px;margin-bottom:10px">
                <span v-if="item.fileUrl.indexOf('exl')!==-1||item.fileUrl.indexOf('xlsx')!==-1||item.fileUrl.indexOf('docx')!==-1||item.fileUrl.indexOf('mp4')!==-1||item.fileUrl.indexOf('ppt')!==-1">下载</span>
                <span v-if="item.fileUrl.indexOf('pdf')!==-1">预览</span>
              </a> -->
              <a-modal
                title="任务结果查看"
                :visible="visiblePicture"
                :footer="null"
                @cancel="visiblePicture=false"
              >
                <ul class="pic-select">
                  <li v-for="(item,index) in picList" :key="index"><a :href="item.fileUrl" target="_blank">{{item.fileName===null?`自由职业者任务成果${index+1}`:item.fileName}}</a></li>
                  <li>任务结果描述：{{currentContent}}</li>
                </ul>
                <!-- <a-carousel arrows>
                  <div
                    slot="prevArrow"
                    slot-scope="props"
                    class="custom-slick-arrow"
                    style="left: 10px;zIndex: 1"
                    :dots='position'
                  >
                    <a-icon type="left-circle" />
                  </div>
                  <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
                    <a-icon type="right-circle" />
                  </div>
                  <div v-for="(item,index) in picList" :key="index">
                    <img v-if="item.fileUrl.indexOf('png')!==-1||item.fileUrl.indexOf('jpg')!==-1||item.fileUrl.indexOf('jpeg')!==-1||item.fileUrl.indexOf('JPEG')!==-1||item.fileUrl.indexOf('bmp')!==-1||item.fileUrl.indexOf('tif')!==-1||item.fileUrl.indexOf('gif')!==-1||item.fileUrl.indexOf('pcx')!==-1||item.fileUrl.indexOf('tga')!==-1||item.fileUrl.indexOf('exif')!==-1||item.fileUrl.indexOf('fpx')!==-1||item.fileUrl.indexOf('svg')!==-1||item.fileUrl.indexOf('psd')!==-1||item.fileUrl.indexOf('cdr')!==-1||item.fileUrl.indexOf('pcd')!==-1||item.fileUrl.indexOf('dxf')!==-1||item.fileUrl.indexOf('ufo')!==-1||item.fileUrl.indexOf('eps')!==-1||item.fileUrl.indexOf('ai')!==-1||item.fileUrl.indexOf('raw')!==-1||item.fileUrl.indexOf('WMF')!==-1||item.fileUrl.indexOf('webp')!==-1||item.fileUrl.indexOf('avif')!==-1" :src="item.fileUrl" alt="" style="width:500px;height:400px;">
                    <div style="height:80px;line-height:80px;text-align:left;font-size:18px">任务结果描述：{{currentContent}}</div>
                  </div>
                </a-carousel> -->
              </a-modal>
            </div>
            </s-table>
            <a-modal
              title="驳回原因"
              :visible="visibleBh"
              @ok="handleSubmit"
              @cancel="handleCancelBh"
            >
              <a-form :form="formBh" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
                <a-form-item label="驳回原因">
                  <a-textarea
                    :autoSize="{ minRows: 3, maxRows: 5 }"
                    :maxLength="100"
                    v-decorator="['errorInfo', { rules: [{ required: true, message: '请输入驳回原因!' }] }]"
                  />
                </a-form-item>
              </a-form>
            </a-modal>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import STable from '@/components/table/'
import moment from 'moment'
import {
  raskDetail,
  raskCancel,
  raskEnd,
  getWorkList,
  raskAssign,
  refuseAssign,
  cancelAssign,
  getRaskTypeName,
  taskWorkerPayItem,
  settlementStatistical,
  getPayDetails,
  taskWorkerPaySubmit,
  taskWorkerResult,
  getByWorkerIds,
  getUserByPhone,
  invitation,
  sendInvitationSms,
  invitationRecommendUser,
  getFeePoint,
  enterpriseAdd,
  enterpriseUnifyAdd2
} from '@/api/api'
export default {
  name: 'raskDetail',
  components: {
    STable
  },
  data () {
    const cellStyle = {
      maxWidth: '120px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow:'ellipsis',
      cursor:'pointer',
    };
    return {
      queryParam: {
        userName: '',
        auditStatus: undefined
      },
      visiblePicture:false,
      visibleBh: false,
      columnsBmry: [
        {
          title: '姓名',
          dataIndex: 'userName',
          scopedSlots: { customRender: 'userName' },
        },
        {
          title: '联系电话',
          dataIndex: 'phoneNo',
          customRender: text => text===null?'--':text
        },
        {
          title: '来源',
          dataIndex: 'joinType',
          customRender: text => text=== '10' ? '报名' : '邀请'
        },
        {
          title: '用工状态',
          dataIndex: 'signStatus',
          customRender: text => {
            if (text === '10') {
              return '确认报名'
            }
            if (text === '91' || text === '92') {
              return '报名失败'
            }
            if (text === '20') {
              return '合作确认'
            }
            if (text === '30') {
              return '合作中'
            }
            if (text === '93' || text === '94' || text === '95' || text === '96') {
              return '合作失败'
            }
          }
        },
        {
          title: '原因',
          dataIndex: 'signStatus',
          customRender: text => {
            if (text === '10' || text === '20' || text === '30') {
              return '--'
            }
            if (text === '91') {
              return '用户取消报名'
            }
            if (text === '92') {
              return '企业拒绝'
            }
            if (text === '93') {
              return '工作者拒绝合作'
            }
            if (text === '94') {
              return '企业取消合作'
            }
            if (text === '95') {
              return '超时系统自动取消'
            }
            if (text === '96') {
              return '企业取消任务'
            }
          }
        },
        {
          title: '报名时间',
          dataIndex: 'createTime',
          customRender: text => text===null?'--':text
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        }
      ], //报名人员
      totalBmry: '报名人员',
      totalFkxx: '付款信息',
      totalRwsh: '任务审核',
      bmryParams: {
        selectedTotalBmry: [],
        prePay: 0,
        raskRash: 0
      },
      taxPoint: '',
      selectedRowKeys: [],
      confirmLoading: false,
      confirmLoadinginvite: false,
      visible: false,
      visibleinvite: false,
      canCancel: true,
      columnsQyry: [
        {
          title: '姓名',
          dataIndex: 'userName',
          customRender: text => text===null?'--':text
        },
        {
          title: '联系电话',
          dataIndex: 'phoneNo',
          customRender: text => text===null?'--':text
        },
        {
          title: '任务金额',
          dataIndex: 'signAmount',
          customRender: text => text === null?'--':text.toFixed(2)
        },
        {
          title: '用工状态',
          dataIndex: 'signStatus',
          scopedSlots: { customRender: 'signStatus' },
        },
        {
          title: '报名时间',
          dataIndex: 'createTime',
          customRender: text => text===null?'--':text
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        }
      ], //签约人员
      totalQyry: '用工人员',
      currentContent: '',
      columnsDsh: [
        {
          title: '提交时间',
          dataIndex: 'createTime',
          defaultSortOrder: 'descend',
          sorter: (a, b) => moment(a.createTime).unix() - moment(b.createTime).unix(),
          width: '120px'
        },
        {
          title: '姓名',
          dataIndex: 'userName',
          customRender: text => text===null?'--':text,
          width: '80px'
        },
        {
          title: '联系电话',
          dataIndex: 'phoneNo',
          customRender: text => text===null?'--':text,
          width: '100px'
        },
        {
          title: '任务结果',
          dataIndex: 'items',
          scopedSlots: { customRender: 'items' },
          width: '100px'
        },
        // {
        //   title: '任务结果描述',
        //   dataIndex: 'content',
        //   customRender: text => text===null?'--':text,
        //   width: '150px',
        //   customCell: () => {
        //       return {
        //         style: cellStyle
        //       }
        //     },
        //     customRender: (text) => <a-tooltip placement="left" title={text}>{text}</a-tooltip>
        // },
        {
          title: '审核状态',
          dataIndex: 'auditStatus',
          customRender: text => text === '10'?"企业待审核":text === '20'?"已通过":text === '11'?"平台待审核":"已驳回",
          width: '100px'
        },
        {
          title: '驳回原因',
          dataIndex: 'errorInfo',
          customRender: text => text===null?'--':text,
          width: '100px'
        },
        {
          title: '处理时间',
          dataIndex: 'auditTime',
          width: '120px'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width: '130px',
          fixed: 'right'
        }
      ], //任务审核
      columnsYfkjsd: [
        {
          title: '姓名',
          dataIndex: 'userName',
          width: '100px'
        },
        {
          title: '任务总额',
          dataIndex: 'signAmount',
          width: '100px',
          customRender: text => text === null?'--':text.toFixed(2)
        },
        {
          title: '预付款金额',
          dataIndex: 'payAmount',
          width: '100px',
          customRender: text => text === null?'--':text.toFixed(2)
        },
        {
          title: '报名时间',
          dataIndex: 'createTime',
          customRender: text => text===null?'--':text,
          width: '100px'
        },
        {
          title: '到账状态',
          dataIndex: 'payStatus',
          customRender: text => text==='10'? '未达到支付条件':text==='20'? '待支付':text==='30'? '支付处理中':'支付成功',
          width: '100px'
        }
      ], //预付款结算单
      yfkPre: null,
      yfkLater: null,
      notYfuJs: true,
      columnsWkjsd: [
        {
          title: '姓名',
          dataIndex: 'userName',
          width: '100px'
        },
        {
          title: '任务总额',
          dataIndex: 'signAmount',
          width: '100px',
          customRender: text => text === null?'--':text.toFixed(2)
        },
        {
          title: '尾款金额',
          dataIndex: 'payAmount',
          width: '100px',
          customRender: text => text === null?'--':text.toFixed(2)
        },
        {
          title: '报名时间',
          dataIndex: 'createTime',
          customRender: text => text===null?'--':text,
          width: '100px'
        },
        {
          title: '到账状态',
          dataIndex: 'payStatus',
          customRender: text => text==='10'? '未达到支付条件':text==='20'? '待支付':text==='30'? '支付处理中':'支付成功',
          width: '100px'
        }
      ], //尾款结算单
      wkPre: null,
      wkLater: null,
      notWkJs: true,
      raskDetailInfo: null,
      raskTypeName: '',
      raskId: '',
      formLayout: 'horizontal',
      formBh: this.$form.createForm(this),
      examinForm: this.$form.createForm(this),
      record: null,
      expand: false,
      tjjsLoading: false,
      tjjsWkLoading: false,
      invitePhone: '',
      inviteUserList: [],
      disabledBtn1:true,
      disabledBtn2:true,
      visibleYq: false,
      confirmLoadingYq: false,
      yfje: 0,
      rwje: 0,
      inputYfje: 0,
      hasInvitor: false, // 是否查询到邀请用户
      visibleYg: false, //确认用工
      currentItem: null,
      searchInviteUser: '',
      inviteUserPhone: '',
      stepTwoShowContentYq: false,
      picList: [],
      visibleTaskDetail: false,
      columnsWkjsds: [
        {
          title: '姓名',
          dataIndex: 'userName'
        },
        {
          title: '联系电话',
          dataIndex: 'phoneNo'
        },
        {
          title: '支付金额',
          dataIndex: 'payAmount',
          customRender: text => text === null?'--':text.toFixed(2)
        },
        {
          title: '付款状态',
          dataIndex: 'payStatus',
          customRender: text => text === '10' ? '等待上传任务结果' : text === '20' ? '待支付' : text === '30' ? '支付处理中' : text === '50' ? '取消付款' : text === '11' ? '已上传任务结果' : '支付成功',
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        }
      ],
      uploadRecord: '',
      visibleExamineModal: false,
      fileList: [],
      url: {
        frontUrl: window._CONFIG['domianURL'] + '/api/tax/common/file/upload',
      },
      uploadData: {
        fileType: '103206'
      },
      confirmLoadingUpload: false,
      isPl: false,
      plList: []
    }
  },
  computed: {
    // 费用
    fy () {
      if (this.rwje) {
        return (this.rwje*this.taxPoint).toFixed(2)
      } else {
        return 0.00
      }
    },
    // 总金额
    allFy () {
      if (this.rwje) {
        return (this.rwje*this.taxPoint+Number(this.rwje)).toFixed(2)
      } else {
        return 0.00
      }
    },
    wk () {
      if (this.inputYfje&&this.rwje) {
        return (this.rwje-this.inputYfje).toFixed(2)
      } else {
        return 0.00
      }
    },
    // 报名人员是否已选择
    hasSelected() {
      if (this.raskDetailInfo.status==='20'||this.raskDetailInfo.status==='30'||this.raskDetailInfo.status==='50') {
        return false
      }
      return this.selectedRowKeys.length > 0;
    },
    // 平均每人任务金额
    avregeCash () {
      if (this.bmryParams.selectedTotalBmry.length > 0) {
        return (this.bmryParams.raskRash / this.bmryParams.selectedTotalBmry.length).toFixed(2)
      } else {
        return 0.00
      }
    },
    // 费用
    fyCash () {
      if (this.bmryParams.selectedTotalBmry.length > 0) {
        console.log(this.bmryParams.raskRash,this.taxPoint)
        return (this.bmryParams.raskRash * this.taxPoint).toFixed(2)
      } else {
        return 0.00
      }
    },
    // 平均每人预付金额
    prePayCash () {
      if (this.bmryParams.selectedTotalBmry.length > 0) {
        return (this.bmryParams.raskRash * this.bmryParams.prePay / this.bmryParams.selectedTotalBmry.length / 100).toFixed(2)
      } else {
        return 0.00
      }
    },
    // 实际金额
    localPay () {
      if (this.bmryParams.selectedTotalBmry.length > 0) {
        return (this.bmryParams.selectedTotalBmry.length * this.avregeCash + (this.bmryParams.raskRash * this.taxPoint)).toFixed(2)
      } else {
        return 0.00
      }
      
    },
    // 统计
    tjPrePay () {
      let s = '预付款' + (this.prePayCash*this.selectedRowKeys.length).toFixed(2) + ' 剩余尾款' + ((this.avregeCash - this.prePayCash)*this.selectedRowKeys.length).toFixed(2)
      return s
    }
  },
  created () {
    this.raskId = window.sessionStorage.getItem('raskId')
    this.getRaskDetail()
    this.getYfkPre()
    this.getWkPre()
    this.getWkLater()
    this.getYfkLater()
    this.getPoint()
    this.dataYfkjsd()
    this.dataDsh()
  },
  watch:{
    fileList: {
      handler (newV) {
        // newV.forEach((item,index) => {
        //   if (item.fileName===undefined) {
        //     item.status='error'
        //   }
        // })
        this.fileList = newV
      },
      deep: true
    }
  },
  methods: {
    // 批量上传
    plUpload () {
      this.isPl = true
      this.visibleExamineModal = true
    },
    // 上传任务结果
    uploadBtn () {
      this.visibleTaskDetail = true
      this.$refs.yfkTableDetail.refresh(true)
    },
    dataWkjsds (parameter) {
      return taskWorkerPayItem(Object.assign({taskId:this.raskId,payType:'20',signStatus:'30'},parameter)).then(res => {
        // console.log(res)
        this.disabledBtn2 = res.result.data.length===0?false:true
        if (res.result.data&&res.result.data.length>0) {
          res.result.data.forEach(item=>{
            if (item.payStatus!=='10') {
              this.disabledBtn2 =false
            }
          })
        }
        return res.result
      })
    },
    uploadResult (record) {
      this.isPl = false
      this.uploadRecord = record
      this.visibleExamineModal = true
    },
    handleChange(info) {
      let fileList = [...info.fileList];
      // 1. Limit the number of uploaded files
      fileList = fileList.slice(-9);
      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = 'https://oss.zfycloud.com' + "/" + file.response.result;
          file.fileUrl = 'https://oss.zfycloud.com' + "/" + file.response.result;
          file.fileName = file.name;
        }
        return file;
      });
      this.fileList = fileList;
    },
    beforeUpload(file) {
      const isJpgOrPng = true;
      if (!isJpgOrPng) {
        this.$message.error('上传照片格式不正确!');
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过10MB!');
        return false
      }
      return isJpgOrPng && isLt2M;
    },
    handleRechargeModalOk() {
      this.examinForm.validateFields((err, fieldsValue) => {
        if (err) {
          return;
        }
        if (this.fileList.length===0) {
          this.$message.error('请上传任务结果！')
          return
        }
        let isUpload = true
        this.fileList.forEach(item=>{
          if (item.fileName===undefined) {
            item.status='error'
            isUpload = false
          }
        })
        if (!isUpload) {
          this.$message.error('上传文件中有错误文件，请删除后提交')
          return
        }
        this.confirmLoadingUpload = true
        const description = this.examinForm.getFieldsValue();
        if (this.isPl) {
          let params = {
            // payId: this.record.id,
            resultItems: this.fileList,
            content: description.content,
            taskId: this.raskId
          }
          enterpriseUnifyAdd2(Object.assign(params)).then((res) => {
            this.confirmLoadingUpload = false
            if (res.success) {
              this.$message.success(res.message);
              this.$refs.yfkTableDetail.refresh(true);
              this.$refs.wkTable.refresh(true);
              this.handleExamineModalCancel();
              this.visibleTaskDetail = false;
            } else {
              this.$message.error(res.message);
            }
          })
        } else {
          let params = {
            results:[
              {
                payItemId: this.uploadRecord.id,
                workerId: this.uploadRecord.workerId,
                resultItems: this.fileList,
                content: description.content
              }
            ],
            taskId: this.uploadRecord.taskId
          }
          enterpriseAdd(Object.assign(params)).then((res) => {
            this.confirmLoadingUpload = false
            if (res.success) {
              this.$message.success(res.message);
              this.$refs.yfkTableDetail.refresh(true);
              this.$refs.wkTable.refresh(true);
              this.handleExamineModalCancel();
              this.visibleTaskDetail = false;
            } else {
              this.$message.error(res.message);
            }
          })
        }
      })
    },
    handleExamineModalCancel() {
      this.examinForm.resetFields();
      this.fileList = [];
      this.confirmLoadingUpload = false;
      this.visibleExamineModal = false;
    },
    // 查询
    handleToSearchEnterprises () {
      this.$refs.rwshTable.refresh(true)
    },
    // 重置
    resetSearchEnterprises () {
      this.queryParam = {
        userName: '',
        auditStatus: undefined
      }
      this.handleToSearchEnterprises()
    },
    // 查看图片
    showPic(record) {
      this.currentContent = record.content
      this.picList = record.items
      this.visiblePicture=true
    },
    // 获取费率
    getPoint () {
      getFeePoint().then(res =>  {
        // console.log(res)
        this.taxPoint = res.result
      })
    },
    // 获取任务类别名称
    getRaskTypeName (id) {
      getRaskTypeName(id).then(res => {
        if (res.success) {
          this.raskTypeName = res.result.industryName
        }
      })
    },
    // 获取任务详情
    getRaskDetail () {
      raskDetail({id:this.raskId}).then(res => {
        // console.log('任务详情',res)
        if (res.success) {
          this.raskDetailInfo = res.result
          if (this.raskDetailInfo.status==='20'||this.raskDetailInfo.status==='30'||this.raskDetailInfo.status==='50') {
            this.canCancel = false
          }
          this.getRaskTypeName(this.raskDetailInfo.taskType)
        }
      })
    },
    // 取消任务
    cancelRask (record) {
      let that = this
      this.$confirm({
        title: '确定取消该任务?',
        // content: h => <div style="color:red;">Some descriptions</div>,
        onOk() {
          raskCancel(that.raskId).then(res => {
            if (res.success) {
              that.$message.success(res.message)
              that.ret()
            } else {
              that.$message.error(res.message)
            }
          })
        },
        onCancel() {
        }
      });
    },
    // 结束报名
    raskEnd (record) {
      let that = this
      this.$confirm({
        title: '确定结束报名?',
        // content: h => <div style="color:red;">Some descriptions</div>,
        onOk() {
          raskEnd(that.raskId).then(res => {
            if (res.success) {
              that.$message.success(res.message)
              that.ret()
            } else {
              that.$message.error(res.message)
            }
          })
        },
        onCancel() {
        }
      });
    },
    // 返回任务详情
    ret () {
      window.sessionStorage.setItem('submenu','raskManage')
      this.$router.push('/raskManage')
    },
    // 查询
    handleToSearchEnterprise () {},
    // 重置
    resetSearchEnterprise () {},
    // 选择标签页
    callback(key) {
      if (key === '3') {
        this.getRaskDetail()
        this.getYfkPre()
        this.getWkPre()
        this.getWkLater()
        this.getYfkLater()
        setTimeout(()=>{
          this.$refs.yfkTable.refresh(true)
          this.$refs.wkTable.refresh(true)
        },100)
      } else if (key === '1' || key === '2') {
        this.getRaskDetail()
        setTimeout(()=>{
          this.$refs.bmryTable.refresh(true)
          this.$refs.qyryTable.refresh(true)
        },100)
      } else if (key === '4') {
        this.getRaskDetail()
        setTimeout(()=>{
          this.$refs.rwshTable.refresh(true)
        },100)
      }
    },
    // 获取报名人员getBmryList
    dataBmry (parameter) {
      return getWorkList(Object.assign({taskId:this.raskId,signStatus:null},parameter)).then(res => {
        // console.log(res)
        if (res.success) {
          this.totalBmry = '报名人员(' + res.result.totalCount + ')'
        }
        return res.result
      })
    },
    // 获取签约人员getQyryList
    dataQyry (parameter) {
      return getWorkList(Object.assign({taskId:this.raskId,signStatus:'20'},parameter)).then(res => {
        if (res.success) {
          this.totalQyry = '用工人员(' + res.result.totalCount + ')'
          if(res.result.data.length>0) {
            res.result.data.forEach(item=>{
              if(item.signStatus==='30') {
                this.canCancel = false
              }
            })
          }
        }
        // console.log(res)
        return res.result
      })
    },
    // 获取预付款列表getYfkList
    dataYfkjsd (parameter) {
      return taskWorkerPayItem(Object.assign({taskId:this.raskId,payType:'10',payStatus:'10'},parameter)).then(res => {
        this.disabledBtn1 = res.result.length===0?true:false
        if (res.success) {
          this.totalFkxx = '付款信息(' + res.result.totalCount + ')'
        }
        return res.result
      })
    },
    // 获取尾款列表getWkList
    dataWkjsd (parameter) {
      return taskWorkerPayItem(Object.assign({taskId:this.raskId,payType:'20',payStatus:'10'},parameter)).then(res => {
        this.disabledBtn2 = res.result.length===0?true:false
        return res.result
      })
    },
    // 获取预付款统计
    getYfkPre () {
      settlementStatistical({taskId: this.raskId,payType:'10'}).then(res => {
        if (res.success) {
          this.yfkPre = res.result
        }
      })
    },
    getYfkLater () {
      getPayDetails({taskId: this.raskId,payType:'10'}).then(res => {
        // console.log(res)
        if (res.success) {
          this.yfkLater = res.result
          this.notYfuJs = this.yfkLater === null ? true : false
        }
      })
    },
    // 获取尾款统计
    getWkPre () {
      settlementStatistical({taskId: this.raskId,payType:'20'}).then(res => {
        if (res.success) {
          this.wkPre = res.result
        }
      })
    },
    getWkLater () {
      getPayDetails({taskId: this.raskId,payType:'20'}).then(res => {
        if (res.success) {
          this.wkLater = res.result
          this.notWkJs = this.wkLater === null ? true : false
        }
      })
    },
    // 获取审核列表getShList
    dataDsh (parameter) {
      return getByWorkerIds(Object.assign({taskId:this.raskId},parameter,this.queryParam)).then(res => {
        if (res.success) {
          this.totalRwsh = '任务审核(' + res.result.totalCount + ')'
        }
        return res.result
      })
    },
    // 报名人员操作
    onSelectChange(selectedRowKeys,selectedRows) {
      let sek = []
      let ser = []
      selectedRows.forEach((item,index) => {
        if (item.signStatus==='10') {
          sek.push(item.id)
          ser.push(item)
        }
      })
      this.selectedRowKeys = sek;
      this.bmryParams.selectedTotalBmry = ser
    },
    // 签约
    sign(record) {
      this.selectedRowKeys.length = 1
      this.bmryParams.selectedTotalBmry = []
      this.visible = true
      this.bmryParams.selectedTotalBmry[0] = record
    },
    // 拒绝
    refuse(record) {
      let that = this
      this.$confirm({
        title: '确定拒绝用工?',
        // content: h => <div style="color:red;">Some descriptions</div>,
        onOk() {
          refuseAssign({id:record.id}).then(res => {
            if (res.success) {
              that.getRaskDetail()
              that.$refs.bmryTable.refresh(true)
              that.$refs.qyryTable.refresh(true)
              that.$message.success(res.message)
            } else {
              that.$message.error(res.message)
            }
          })
        },
        onCancel() {
        }
      });
    },
    // 批量签约
    plSign () {
      this.visible = true
    },
    fiexed(e) {
      this.bmryParams.prePay = Number(this.bmryParams.prePay).toFixed(0)
    },
    handleOk () {
      if (this.bmryParams.raskRash===0) {
        this.$message.error('请输入预付款金额')
        return
      }
      if (this.bmryParams.prePay<0||this.bmryParams.prePay>100) {
        this.$message.error('预付金额请输入0~100之间的整数')
        return
      }
      this.confirmLoading = true
      let params = {
        id: this.raskId,
        models: []
      }
      this.bmryParams.selectedTotalBmry.forEach(item => {
        if (item.signStatus==='10') {
          params.models.push({
            advanceAmount: this.prePayCash,
            balanceAmount: (this.avregeCash - this.prePayCash).toFixed(2),
            id: item.id,
          })
        }
      })
      raskAssign(params).then(res => {
        this.confirmLoading = false
        if (res.success) {
          this.$message.success(res.message)
          this.getRaskDetail()
          this.$refs.bmryTable.refresh(true)
          this.$refs.qyryTable.refresh(true)
          this.handleCancel()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleCancel () {
      this.visible = false
    },
    // 邀请
    invite () {
      this.visibleYq = true
    },
    tofixed () {
      this.inputYfje = Number(this.inputYfje).toFixed(2)
    },
    // 搜索
    onSearchInvite (value) {
      if ((/^(?:(?:\+|00)86)?1[3-9]\d{9}$/).test(this.inviteUserPhone)) {
        getUserByPhone(this.inviteUserPhone).then(res => {
          if (res.result.length>0&&res.success) {
            this.currentItem = res.result[0]
            this.hasInvitor = true
          } else {
            this.hasInvitor = false
            this.stepTwoShowContentYq = true
          }
        })
      } else {
        this.$message.error('手机号格式不正确')
      }
    },
    // 任务金额
    onChangeRwje () {
      if (this.rwje!==0) {
        this.inputYfje = (this.rwje * this.yfje).toFixed(2)
      }
    },
    // 单选预付金额
    onChangeYfje () {
      if (this.yfje!==0) {
        this.inputYfje = (this.yfje * this.rwje).toFixed(2)
      }
    },
    // 发送短信
    sendDx () {
      if ((/^(?:(?:\+|00)86)?1[3-9]\d{9}$/).test(this.inviteUserPhone)) {
        sendInvitationSms(this.inviteUserPhone).then(res => {
          if (res.success) {
            this.$message.success(res.message)
            this.handleCancelYq()
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        this.$message.error('手机号格式不正确')
      }
    },
    // 确认发送
    handleOkYq () {
      if (this.rwje===0||this.rwje==='0') {
        this.$message.error('请输入任务金额')
        return
      }
      if (this.inputYfje&&this.inputYfje<0) {
        this.$message.error('请输入预付金额')
        return
      }
      if (Number(this.inputYfje)>Number(this.rwje)) {
        this.$message.error('预算金额不能大于任务金额')
        return
      }
      this.confirmLoadingYq = true
      invitationRecommendUser(Object.assign(this.currentItem,{taskId:this.raskId,advanceAmount:this.inputYfje,balanceAmount:this.wk})).then(res => {
        this.confirmLoadingYq = false
        if (res.success) {
          this.$message.success(res.message)
          this.$refs.qyryTable.refresh(true)
          this.$refs.bmryTable.refresh(true)
          this.handleCancelYq()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 取消邀请
    handleCancelYq () {
      this.visibleYq = false
      this.inviteUserPhone = ''
      this.rwje = 0
      this.inputYfje = 0
      this.yfje = 0
      this.hasInvitor = false
      this.stepTwoShowContentYq = false
    },

    // 签约人员
    // 取消签约
    cancelSign (record) {
      let that = this
      this.$confirm({
        title: '确定取消用工?',
        // content: h => <div style="color:red;">Some descriptions</div>,
        onOk() {
          cancelAssign({id:record.id}).then(res => {
            if (res.success) {
              that.getRaskDetail()
              that.$message.success(res.message)
              that.$refs.qyryTable.refresh(true)
            } else {
              that.$message.error(res.message)
            }
          })
        },
        onCancel() {
        }
      });
    },

    // 付款信息
    callbackFk (key) {
    },
    // 提交预付款
    subYfk () {
      this.tjjsLoading = true
      taskWorkerPaySubmit({taskId:this.raskId,payType:'10'}).then(res => {
        this.tjjsLoading = false
        if (res.success) {
          this.$message.success(res.message)
          this.getRaskDetail()
          this.$refs.yfkTable.refresh(true)
          this.getYfkLater()
          if (res.result) {
            window.sessionStorage.setItem('submenu','/settlementDetail')
            window.sessionStorage.setItem('taskInfo',JSON.stringify(res.result)) 
            window.location.reload(true)
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 提交尾款
    subWk () {
      this.tjjsWkLoading = true
      taskWorkerPaySubmit({taskId:this.raskId,payType:'20'}).then(res => {
        this.tjjsWkLoading = false
        if (res.success) {
          this.$message.success(res.message)
          this.getRaskDetail()
          this.$refs.wkTable.refresh(true)
          this.getWkLater()
          if (res.result) {
            window.sessionStorage.setItem('submenu','/settlementDetail')
            window.sessionStorage.setItem('taskInfo',JSON.stringify(res.result))
            window.location.reload(true)
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //  待审核
    //  通过
    pass (record) {
      let that = this
      this.$confirm({
        title: '确定通过?',
        // content: h => <div style="color:red;">Some descriptions</div>,
        onOk() {
          taskWorkerResult({id:record.id,auditStatus:'20'}).then(res => {
            if (res.success) {
              that.$message.success(res.message)
              that.$refs.rwshTable.refresh(true)
            } else {
              that.$message.error(res.message)
            }
          })
        },
        onCancel() {
        }
      });
    },
    // 驳回
    noPass (record) {
      this.record = record
      this.visibleBh = true
    },
    handleSubmit(e) {
      e.preventDefault();
      this.formBh.validateFields((err, values) => {
        if (!err) {
          let params = Object.assign(values,{id:this.record.id,auditStatus: '30'})
          taskWorkerResult(params).then(res => {
            if (res.success) {
              this.$message.success(res.message)
              this.$refs.rwshTable.refresh(true)
              this.handleCancelBh()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      });
    },
    handleCancelBh () {
      this.visibleBh = false
      this.formBh.resetFields()
    },
  }
}
</script>
<style scoped lang="less">
.header{
  color: #000000;
  font-size: 14px;
  margin-bottom: 20px;
  .ret{
    color: rgba(0,0,0,0.65);
    cursor: pointer;
  }
}
.header-one{
  padding: 27px 24px;
  border: 1px solid #F0F0F0;
  margin-bottom: 16px;
  // border-left: 0;
  // border-right: 0;
  // border-bottom: 0;
}
.rask-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .rt-left{
    display: flex;
    align-items: flex-end;
    .rt-left-title{
      font-size: 20px;
      color: rgba(0, 0, 0, 0.85);
      margin-right: 16px;
    }
    .rt-left-time{
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
.rask-content{
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  .rc-mgb{
    margin-top: 24px;
    white-space: nowrap;
    &:last-child{
      width: 100%;
      text-align: justify;
      white-space: normal;
    }
    span{
      color:#1890FF;
    }
  }
}
.search{
  width: 100%;
  padding: 16px 0;
  // display: flex;
  align-items: center;
}
.model{
  padding: 0;
  list-style: none;
  li{
    display: flex;
    align-items: flex-start;
    margin-bottom:20px;
    .span{
      width: 80px;
    }
    .mgt-5{
      margin-top: 5px;
    }
    .tag{
      width: 360px;
      display: flex;
      flex-wrap: wrap;
      span{
        margin-bottom: 10px;
      }
    }
  }
}
.fkxx-title{
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin: 10px 0 25px 0;
}
.fkxx-card{
  width: 500px;
  // height: 182px;
  padding: 20px 24px;
  border: 1px solid #F0F0F0;
  .fkxxc-title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;
  }
  .fkxxc-nn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .fkxxc-nn-left{
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: bold;
    }
    .fkxxc-nn-right{
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      align-items: center;
      &::before{
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #F5222D;
        border-radius: 50%;
        content: '';
        margin-right: 5px;
      }
    }
  }
  .fkxx-content{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 30px;
    color: rgba(0, 0, 0, 0.85);
    span{
      width: 160px;
    }
  }
}
.table-point{
  display: flex;
  align-items: center;
  // justify-content: center;
  span{
    width: 4px;
    height: 4px;
    background: #1890FF;
    border-radius: 50%;
    margin-right: 4px;
  }
}
.shrw{
  list-style: none;
  padding: 0;
  li{
    margin: 0 0 20px 0;
  }
}
.common-img{
  width: 26px;
  height: 30px;
  margin: 0 10px 0 0;
}
.showExpand{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-list{
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  li{
    margin: 10px 10px 0 0;
  }
}
.bmry{
  display: flex;
  justify-content: space-between;
}
.fsyq-head{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .fsyq-title{
    font-size: 14px;
    color: rgba(32, 32, 32, 0.65);
    margin: 0 0 14px 0;
  }
  .user-info{
    margin: 24px 0;
    .img{
      width: 100px;
      height: 100px;
      border-radius: 3px;
      margin-bottom: 8px;
      text-align: center;
      line-height: 100px;
      font-size: 40px;
      background: #DDDDDD;
      border-radius: 50%;
    }
    div{
      text-align: center;
      color: rgba(32, 32, 32, 0.65);
      font-size: 14px;
    }
  }
}
.common-content{
  .cc-title{
    padding: 18px 0;
    border-bottom: 1px solid #F0F0F0;
    font-size: 18px;
    color: #000000;
  }
  .cc-desc{
    font-size: 14px;
    color: rgba(32, 32, 32, 0.65);
    margin: 24px 0 0 0;
  }
  .cc-content{
    display: flex;
    align-items: center;
    align-items: center;
    font-size: 16px;
    color: #333333;
    margin:24px 0 0 0;
  }
  .cc-footer{
    margin: 24px 0;
    padding: 20px 80px;
    width: 640px;
    // height: 95px;
    background: #FAFAFA;
    border-radius: 3px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    .ccf-left,.ccf-right{
      color: #333333;
      font-size: 16px;
      span{
        font-size: 24px;
        color: #FF4D4F;
      }
      div{
        &:last-child{
          margin: 10px 0 0 0;
          font-size: 14px;
          color: rgba(32, 32, 32, 0.65);
        }
      }
    }
    .ccf-right{
      margin-left: 100px;
    }
  }
}
.common-opt{
  text-align: center;
  margin: 20px 0 20px 0;
}
.no-invite{
  margin: 17px 0 77px 0;
  text-align: center;
  font-size: 14px;
  color: rgba(32, 32, 32, 0.65);
}
.pop-content{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 16px 0;
  border-bottom: 1px solid #E1E4E8;
  .img{
    width: 60px;
    height: 60px;
    margin-right: 10px;
    border-radius: 50%;
  }
  .person{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 10px;
    span{
      &:last-child{
        font-size: 12px;
        color: rgba(0, 0, 0, 0.65);
        margin-left: 10px;
      }
    }
  }
  .construction{
    width: 230px;
    text-align: justify;
    font-size: 14px;
  }
}
.pic-select{
  padding: 0;
  margin: 0;
  list-style: none;
  li{
    margin: 10px 0;
  }
}
</style>
<style scoped>
/* For demo */
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 500px;
  line-height: 160px;
  /* background: #364d79; */
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 35px;
  height: 35px;
  font-size: 35px;
  color: #fff;
  background-color: rgba(31, 45, 61, 1);
  border-radius: 50%;
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.ant-carousel >>> .slick-dots{
  display: none !important;
}
</style>
