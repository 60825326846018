<!--
 * @Description: 交易中心--交易单
 * @Author: HuangCheng
 * @Date: 2020-03-12 17:54:56
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-21 16:09:01
-->
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper" style="margin-bottom: 50px;max-width:1350px">
      <a-form layout="inline">
        <a-row :gutter="24" style="margin-bottom:20px">
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="企业名称">
              <a-input
                class="w-100"
                v-model="searchForm.enterpriseName"
                placeholder="请输入企业名称"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="账户类型">
              <a-select
                class="w-100"
                style="width: 200px"
                defaultValue=""
                placeholder="请选择账户类型"
                v-model="searchForm.payWalletType"
              >
                <a-select-option
                  v-for="item in payType"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="订单号">
              <a-input
                class="w-100"
                v-model="searchForm.id"
                placeholder="请输入订单号"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="批次号">
              <a-input
                class="w-100"
                v-model="searchForm.batchId"
                placeholder="请输入批次号"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24" style="margin-bottom:20px">
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="数据来源">
              <a-select
                class="w-100"
                style="width: 200px"
                defaultValue=""
                placeholder="请选择"
                v-model="searchForm.sourceNo"
              >
                <a-select-option value="10000002">数字领英</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="订单状态">
              <a-select
                class="w-100"
                style="width: 200px"
                defaultValue=""
                placeholder="请选择状态"
                v-model="searchForm.status"
              >
                <a-select-option
                  v-for="item in tradeStatus"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="第三方单号">
              <a-input
                class="w-100"
                v-model="searchForm.relationId"
                placeholder="请输入第三方单号"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24" style="margin-bottom:20px">
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="创建时间">
              <a-range-picker v-model="date" @change="handleDateChange" />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="完成时间">
              <a-range-picker
                v-model="finishDate"
                @change="handleFinishDateChange"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24" style="margin-bottom:20px">
          <a-col :lg="12" :md="12" :sm="12">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="handleToSearchList"
                >查询</a-button
              >
              <a-button
                style="margin-left: 8px"
                icon="reload"
                @click="resetSearchForm"
                >重置</a-button
              >
              <a-button
                type="primary"
                v-has="'tradingCenter:export'"
                style="margin-left: 8px"
                @click="handleExportXls('交易单报表')"
                >导出Excel</a-button
              >
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div style="max-width:1300px">
    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="tableColumns"
      :data="loadTradingList"
      bordered
      :scroll="{ x: 1300 }"
    >
      <span slot="action" slot-scope="text, record">
        <a @click="handleDealRow(record)" v-has="'tradingCenter:jymx'"
          >交易明细</a
        >
        <!-- <a-divider type="vertical" v-has="'tradingCenter:jymx'"/>
        <a @click="raskDetail(record)" v-has="'tradingCenter:rwxq'">任务详情</a>
        <a-divider type="vertical" v-has="'tradingCenter:rwxq'"/>
        <a @click="sureOrder(record)" v-has="'tradingCenter:qrd'">确认单</a> -->
      </span>
    </s-table>
</div>
    <!-- 交易明细 -->
    <a-modal
      title="交易明细"
      width="100%"
      class="detail-modal"
      :footer="null"
      :visible="visibleDetailModal"
      @cancel="visibleDetailModal = false"
      :dialog-style="{ top: '0px' }"
    >
      <TransactionDetail v-if="visibleDetailModal" :id="currentId" />
    </a-modal>
    <a-modal
      :title="modalTitle"
      width="1000px"
      :visible="visibleRask"
      :footer="null"
      @ok="handleRask"
      @cancel="handleCancel"
    >
      <!-- 任务详情 -->
      <ul
        class="sure-order"
        v-if="isRask === true && raskContent !== null"
        layout="inline"
      >
        <li>
          <span>任务标题：</span>
          <div v-if="!isupdateTaskDetail">{{ raskContent.title }}</div>
          <div v-else>
            <a-input v-model="raskContent.title" placeholder="请输入任务标题" />
          </div>
        </li>
        <li>
          <span>行业类别：</span>
          <div v-if="!isupdateTaskDetail">
            {{ raskContent.industryTypeText }}
          </div>
          <div style="width: 100%" v-else>
            <a-cascader
              v-model="taskTypeVm"
              style="width: 50%"
              :options="taskTypeLists"
              placeholder="请选择"
              @change="onChange"
            />
          </div>
        </li>
        <li>
          <span>任务描述：</span>
          <div v-if="!isupdateTaskDetail">
            {{ raskContent.industryContent }}
          </div>
          <div style="width: 100%; height: 100px" v-else>
            <a-textarea
              style="width: 100%; height: 100px"
              v-model="raskContent.industryContent"
              :row="6"
              placeholder="请输入任务描述"
            />
          </div>
        </li>
        <li>
          <span>任务时间：</span>
          <div v-if="!isupdateTaskDetail">
            {{ raskContent.taskStartDate }} ~ {{ raskContent.taskFinishDate }}
          </div>
          <div style="width: 100%" v-else>
            <a-range-picker
              v-model="raskDate"
              style="width: 30%"
              @change="handleRaskDateChange"
            />
          </div>
        </li>
        <li>
          <div class="raskOpt">
            <a-button @click="handleRask">取消</a-button>
            <a-button
              type="primary"
              style="margin-left: 10px"
              @click="updateTaskDetail"
              v-if="!isupdateTaskDetail"
              >修改</a-button
            >
            <a-button
              type="primary"
              style="margin-left: 10px"
              @click="sureUpdateTaskDetail"
              v-else
              >确认</a-button
            >
          </div>
        </li>
      </ul>
      <div v-if="isRask === true && raskContent === null">
        <div class="empty">暂无数据</div>
      </div>
      <!-- 确认单 -->
      <div
        class="modal-details"
        v-if="isRask === false && sureOrderContent !== null"
      >
        <div style="color: red">
          声明：甲方对乙方提供任务/项目的结果的真实性、完整性、准确性确认无误，甲方对乙方众包人员的绩效公允性、合理性认可。
        </div>
        <div>甲方：{{ sureOrderContent.clientName }}</div>
        <div style="margin-bottom: 20px">乙方：四川省智赋云科技有限公司</div>
        <div style="font-weight: bold">客户基本信息</div>
        <ul class="sure-order extra-sure-order">
          <li>
            <span>客户名称：</span>
            <div>{{ sureOrderContent.clientName }}</div>
          </li>
          <li>
            <span>联系人：</span>
            <div>{{ sureOrderContent.contacts }}</div>
          </li>
          <li>
            <span>手机号码：</span>
            <div>{{ sureOrderContent.phoneNo }}</div>
          </li>
          <li>
            <span>邮箱：</span>
            <div>{{ sureOrderContent.email }}</div>
          </li>
          <li>
            <span>交付日期：</span>
            <div>{{ sureOrderContent.deliveryDate }}</div>
          </li>
          <li>
            <span>客户地址：</span>
            <div>{{ sureOrderContent.clientAddress }}</div>
          </li>
        </ul>
        <div style="font-weight: bold">任务/项目信息</div>
        <ul class="sure-order extra-sure-order">
          <li>
            <span>项目名称：</span>
            <div v-if="!isupdateOrderDetail">
              {{ sureOrderContent.projectName }}
            </div>
            <div v-else>
              <a-input
                v-model="sureOrderContent.projectName"
                placeholder="请输入项目名称"
              />
            </div>
          </li>
          <li>
            <span>工作内容：</span>
            <div v-if="!isupdateOrderDetail">
              {{ sureOrderContent.workContent }}
            </div>
            <div v-else>
              <a-input
                v-model="sureOrderContent.workContent"
                placeholder="请输入工作内容"
              />
            </div>
          </li>
          <li>
            <span>服务结果：</span>
            <div v-if="!isupdateOrderDetail">
              {{ sureOrderContent.serviceResults }}
            </div>
            <div v-else>
              <a-input
                v-model="sureOrderContent.serviceResults"
                placeholder="请输入服务结果"
              />
            </div>
          </li>
          <li>
            <span>客户意见：</span>
            <div>确认一致。</div>
          </li>
        </ul>
        <a-table
          :columns="columnsOrder"
          :data-source="dataOrder"
          :rowKey="(record) => record.id"
        >
          <template slot="footer">
            <div
              style="
                display: flex;
                justify-content: space-between;
                margin: 0 55px;
                font-weight: bold;
              "
            >
              <span>总计(元)：</span>
              <span>{{ sureOrderContent.totalAmount }}</span>
            </div>
          </template>
        </a-table>
        <div class="orderUpdate" style="margin-top: 20px">
          <a-button @click="handleRask">取消</a-button>
          <a-button
            type="primary"
            style="margin-left: 10px"
            @click="updateOrderDetail"
            v-if="!isupdateOrderDetail"
            >修改</a-button
          >
          <a-button
            type="primary"
            style="margin-left: 10px"
            @click="sureupdateOrderDetail"
            v-else
            >确认</a-button
          >
        </div>
      </div>
      <div v-if="isRask === false && sureOrderContent === null">
        <div class="empty">暂无数据</div>
      </div>
    </a-modal>
  </a-card>
</template>
<script>
import moment from "moment";
import STable from "@/components/table/";
import AInput from "ant-design-vue/es/input/Input";
import { getArrItem } from "@/utils/util.js";
import TransactionDetail from "./components/TransactionDetail.vue";
import {
  getEnterpriseTradeMainList,
  getqueryTradeTask,
  queryTradeConfirm,
  // exportTradingExcel,
  taskType,
  updateTradeTask,
  getTradeTaskTemplate,
  updateTradeConfirm,
  getAgentEnterpriseTradeMainList,
} from "@/api/api";

export default {
  name: "TransactionTicket",
  components: {
    AInput,
    STable,
    TransactionDetail,
  },
  data() {
    return {
      searchForm: {
        id: "",
        batchId: this.$route.query.batchId || "",
        enterpriseId: this.$route.params.id || "",
        enterpriseName: this.$route.params.name || this.$route.query.name || "",
        createTimeStart: "",
        createTimeEnd: "",
        finishTimeEnd: "",
        finishTimeStart: "",
        status: undefined,
        payWalletType: undefined,
        relationId: "",
        sourceNo: undefined,
      },
      payType: [
        {
          value: "100401",
          label: "银行账户",
        },
        {
          value: "100402",
          label: "支付宝账户",
        },
        {
          value: "100403",
          label: "微信账户",
        },
      ],
      tradeStatus: [
        {
          value: "101301",
          label: "待提交",
        },
        {
          value: "101305",
          label: "审核待确认",
        },
        {
          value: "101311",
          label: "支付待确认",
        },
        {
          value: "101321",
          label: "支付处理中",
        },
        {
          value: "101331",
          label: "处理完成",
        },
        {
          value: "101341",
          label: "处理失败",
        },
        {
          value: "101342",
          label: "处理失败-超时过期",
        },
        {
          value: "101343",
          label: "处理失败-关闭订单",
        },
      ],
      tableColumns: [
        {
          title: "订单号",
          align: "center",
          dataIndex: "id",
          key: "1",
          width: "180px",
        },
        {
          title: "批次号",
          align: "center",
          dataIndex: "batchId",
          key: "2",
          width: "320px",
        },
        {
          title: "企业名称",
          align: "center",
          dataIndex: "enterpriseName",
          key: "3",
          width: "220px",
        },
        {
          title: "支付账户类型",
          align: "center",
          dataIndex: "payWalletType",
          customRender: (text) => getArrItem(text, this.payType),
          key: "4",
          width: "180px",
        },
        // {
        //   title: '费用扣点',
        //   align: 'center',
        //   dataIndex: 'costPoint',
        //   customRender: (text) => (text*100).toFixed(2) + '%'
        // },
        {
          title: "人数",
          align: "center",
          dataIndex: "personNum",
          key: "5",
          width: "100px",
        },
        {
          title: "成功笔数",
          align: "center",
          dataIndex: "successNum",
          key: "6",
          width: "100px",
        },
        {
          title: "失败笔数",
          align: "center",
          dataIndex: "failNum",
          key: "7",
          width: "100px",
        },
        {
          title: "付款金额(元)",
          align: "center",
          dataIndex: "amount",
          // customRender: (text) => text === null ? '0.00' : text.toFixed(2),
          key: "8",
          width: "180px",
        },
        {
          title: "订单状态",
          align: "center",
          dataIndex: "status",
          customRender: (text) => getArrItem(text, this.tradeStatus),
          key: "9",
          width: "180px",
        },
        {
          title: "来源",
          align: "center",
          dataIndex: "sourceNo",
          width: "100px",
          customRender: (text) =>
            text === "10000001"
              ? "结算平台"
              : text === "10000002"
              ? "数字领英"
              : "",
        },
        {
          title: "第三方单号",
          align: "center",
          dataIndex: "relationId",
          width: "200px",
        },
        {
          title: "创建时间",
          align: "center",
          dataIndex: "createTime",
          width: "200px",
          key: "10",
        },
        {
          title: "完成时间",
          align: "center",
          dataIndex: "endTime",
          width: "170px",
          key: "11",
        },
        {
          title: "操作",
          align: "center",
          dataIndex: "action",
          width: "250px",
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      date: [],
      finishDate: [],
      currentId: "",
      orderStatus: "",
      visibleDetailModal: false,
      visibleRask: false,
      raskContent: "",
      isRask: false,
      modalTitle: "",
      sureOrderContent: null,
      columnsOrder: [
        {
          title: "姓名",
          align: "center",
          dataIndex: "name",
        },
        {
          title: "付款金额",
          align: "center",
          dataIndex: "payAmount",
        },
        {
          title: "费用金额",
          align: "center",
          dataIndex: "feeAmount",
        },
        {
          title: "状态",
          align: "center",
          dataIndex: "status",
          customRender: (text) => {
            if (text === "0") {
              return "已验收";
            } else {
              return "未验收";
            }
          },
        },
        {
          title: "总金额",
          align: "center",
          dataIndex: "totalAmount",
        },
      ],
      dataOrder: [],
      isupdateTaskDetail: false,
      isupdateOrderDetail: false,
      taskTypeLists: [],
      raskDate: [],
      taskTypeVm: [],
      Id: "",
    };
  },
  created() {
    // console.log(this.$route)
    if (
      this.$route.query.startDate !== "" &&
      this.$route.query.startDate !== undefined
    ) {
      this.date = [
        moment(this.$route.query.startDate),
        moment(this.$route.query.finishDate),
      ];
      this.searchForm.createTimeStart =
        this.$route.query.startDate + " 00:00:00";
      this.searchForm.createTimeEnd = this.$route.query.finishDate + "23:59:59";
    }
  },
  methods: {
    /**
     * @description: 创建时间段选择
     * @param : date date
     * @param : dateString 时间数组
     * @return: void
     * @Author: HuangCheng
     */
    handleDateChange(date, dateString) {
      this.searchForm.createTimeStart = `${dateString[0]} 00:00:00`;
      this.searchForm.createTimeEnd = `${dateString[1]} 23:59:59`;
    },
    handleFinishDateChange(date, dateString) {
      this.searchForm.finishTimeStart = `${dateString[0]} 00:00:00`;
      this.searchForm.finishTimeEnd = `${dateString[1]} 23:59:59`;
    },
    /**
     * @description: 加载列表数据
     * @param : parameter 查询参数
     * @return: promise
     * @Author: HuangCheng
     */
    loadTradingList(parameter) {
      return getAgentEnterpriseTradeMainList(
        Object.assign(parameter, this.searchForm)
      ).then((res) => {
        // if (!this.$store.state.user.userRules.includes('user:setStar')) {
        //   if (res.result.data.length>0) {
        //     res.result.data.forEach(item=>{
        //       let phone=/(.{3}).{2,}(\d{3})/
        //       let enterpriseName = /(.{2}).{1,}(.{2})/
        //       let tradeDetail = /(.{4}).{1,}/
        //       let addr = /.{1,}/
        //       let account = /.{1,}/
        //       let userName = /(.{1}).{1,}/
        //       let email = /(.{3}).{1,}/
        //       let idcard = /(\d{3})\d{11}(\w{4})/
        //       item.batchId = item.batchId.replace(tradeDetail, "$1******")
        //       item.enterpriseName = item.enterpriseName.replace(enterpriseName, "$1******$2")
        //       // item.contactsAddress = item.contactsAddress.replace(addr, "******")
        //       // item.contactsPhone = item.contactsPhone.replace(phone, "$1*******$2")
        //       item.amount = String(item.amount).replace(account, "***")
        //       // item.applyAmount = String(item.applyAmount).replace(account, "***")
        //       // item.userNikeName = item.userNikeName.replace(userName, "$1**")
        //       // item.userName = item.userName.replace(userName, "$1**")
        //       // item.idcardNo = item.idcardNo.replace(idcard, "$1***********$2")
        //     })
        //   }
        // } else {
        //   if (res.result.data.length>0) {
        //     res.result.data.forEach(item=>{
        //       item.amount = Number(item.amount).toFixed(2)
        //     })
        //   }
        // }
        return res.result;
      });
    },
    /**
     * @description: 查看详情
     * @param : record
     * @return: void
     * @Author: HuangCheng
     */
    handleDealRow(record) {
      this.currentId = record.id;
      this.orderStatus = record.status;
      this.visibleDetailModal = true;
    },
    /**
     * @description: 查询交易单列表
     * @Author: HuangCheng
     */
    handleToSearchList() {
      this.$refs.table.refresh(true);
    },
    /**
     * @description: 重置搜索条件
     * @param :
     * @return:
     * @Author: HuangCheng
     */
    resetSearchForm() {
      this.searchForm = {
        enterpriseName: "",
        idcardNo: "",
        countDate: "",
        createTimeStart: "",
        createTimeEnd: "",
        finishTimeEnd: "",
        finishTimeStart: "",
        status: undefined,
        payWalletType: undefined,
        relationId: "",
        sourceNo: undefined,
      };
      this.date = [];
      this.finishDate = [];
      this.handleToSearchList();
    },
    // 任务详情
    raskDetail(record) {
      this.modalTitle = "任务详情";
      this.isRask = true;
      this.visibleRask = true;
      this.isupdateTaskDetail = false;
      getqueryTradeTask(record.id).then((res) => {
        if (res.success) {
          this.raskContent = res.result;
          this.raskDate = [
            moment(this.raskContent.taskStartDate),
            moment(this.raskContent.taskFinishDate),
          ];
        }
      });
    },
    // 确认单
    sureOrder(record) {
      this.modalTitle = "客户服务确认单";
      this.isRask = false;
      this.visibleRask = true;
      this.isupdateOrderDetail = false;
      queryTradeConfirm(record.id).then((res) => {
        if (res.success && res.result) {
          this.Id = res.result.id;
          this.sureOrderContent = res.result;
          if (this.sureOrderContent !== null) {
            this.sureOrderContent = JSON.parse(this.sureOrderContent.content);
            this.dataOrder = this.sureOrderContent.details;
            this.sureOrderContent.deliveryDate = moment(
              this.sureOrderContent.deliveryDate
            ).format("YYYY-MM-DD");
          }
        } else {
          this.sureOrderContent = null;
        }
      });
    },
    handleRask() {
      this.visibleRask = false;
    },
    handleCancel() {
      this.visibleRask = false;
      this.isRask = false;
    },
    // 导出文件
    handleExportXls(fileName) {
      if (!fileName || typeof fileName != "string") {
        fileName = "导出文件";
      }
      let param = { ...this.searchForm };
      // if(this.selectedRowKeys && this.selectedRowKeys.length>0){
      //   param['selections'] = this.selectedRowKeys.join(",")
      // }
      exportTradingExcel(param).then((data) => {
        if (!data) {
          this.$message.warning("文件下载失败");
          return;
        } else {
          this.$message.success("导出成功");
        }
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(new Blob([data]), fileName + ".xls");
        } else {
          let url = window.URL.createObjectURL(new Blob([data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName + ".xls");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
      });
    },
    // 选择任务类别
    onChange(value, selectedOptions) {
      // console.log(value,selectedOptions,this.taskTypeVm);
      this.raskContent.industryType = value[1];
      this.raskContent.industryTypeText = selectedOptions[1].label;
      getTradeTaskTemplate(value[1]).then((res) => {
        this.raskContent.industryContent = res.result.content;
      });
    },
    // 选择时间
    handleRaskDateChange(date, dateString) {
      this.raskContent.taskStartDate = dateString[0];
      this.raskContent.taskFinishDate = dateString[1];
    },
    // 修改任务详情
    updateTaskDetail() {
      this.isupdateTaskDetail = true;
      taskType().then((res) => {
        // console.log(res)
        this.taskTypeLists = res.result;
        this.taskTypeLists.forEach((item) => {
          item.value = item.id;
          item.label = item.industryName;
          item.children.forEach((li) => {
            li.value = li.id;
            li.label = li.industryName;
            if (this.raskContent.industryType === li.id) {
              this.taskTypeVm = [item.id, li.id];
            }
          });
        });
      });
    },
    // 确认修改
    sureUpdateTaskDetail() {
      if (this.raskContent.title === "") {
        this.$message.error("任务标题不能为空");
      } else if (this.raskContent.industryTypeText === "") {
        this.$message.error("任务类别不能为空");
      } else if (this.raskContent.industryContent === "") {
        this.$message.error("任务描述不能为空");
      } else if (
        this.raskContent.taskStartDate === "" ||
        this.raskContent.taskFinishDate === ""
      ) {
        this.$message.error("任务时间不能为空");
      } else {
        this.raskContent.taskStartDate += " 00:00:00";
        this.raskContent.taskFinishDate += " 00:00:00";
        updateTradeTask(this.raskContent).then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.visibleRask = false;
            this.isRask = false;
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    // 修改确认单
    updateOrderDetail() {
      this.isupdateOrderDetail = true;
    },
    // 确认修改
    sureupdateOrderDetail() {
      if (this.sureOrderContent.projectName === "") {
        this.$message.error("项目名称不能为空");
      } else if (this.sureOrderContent.workContent === "") {
        this.$message.error("工作内容不能为空");
      } else if (this.sureOrderContent.serviceResults === "") {
        this.$message.error("服务结果不能为空");
      } else {
        updateTradeConfirm(
          Object.assign({ id: this.Id }, this.sureOrderContent)
        ).then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.visibleRask = false;
            this.isRask = false;
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
  },
};
</script>
<style lang="less">
// @import '~@/assets/less/index.less';
.detail-modal {
  top: 0;
  height: 100vh;
  .ant-modal-content {
    height: 100vh;
    .ant-modal-body {
      height: calc(100% - 55px);
      padding: 0;
    }
  }
}
</style>
<style lang="less" scoped>
.sure-order {
  list-style: none;
  padding: 0;
  li {
    display: flex;
    margin: 20px 0;
    span {
      width: 75px;
      white-space: nowrap;
    }
  }
}
.extra-sure-order {
  background: #fafafa;
}
.modal-details {
  height: 500px;
  padding: 0 20px;
  overflow-y: scroll;
}
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #6d6767;
  height: 300px;
}
.electronic-return {
  .electronic-nav {
    display: flex;
    justify-content: space-between;
  }
  .electronic-table {
    width: 100%;
    tr {
      td {
        border: 2px solid #000;
        border-top: 0;
        border-right: 0;
        &:last-child {
          border-right: 4px solid #000;
        }
      }
      &:first-child {
        td {
          border-top: 4px solid #000;
        }
      }
    }
    .left-border,
    .left-bottom {
      border-left: 4px solid #000;
    }
  }
}
.raskOpt,
.orderUpdate {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>