<!--
 * @Description: 发票中心--企业发票(新)
 * @Author: HuangCheng
 * @Date: 2020-03-12 10:46:47
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-15 17:48:21
 -->
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper" style="margin-bottom:50px;max-width:1350px;">
      <a-form layout="inline">
        <a-row :gutter="24" style="margin-bottom:20px">
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="发票单号">
              <a-input v-model="searchForm.id" placeholder="请输入发票单号" />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="企业名称">
              <a-input
                v-model="searchForm.enterpriseName"
                placeholder="请输入企业名称"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="状态">
              <a-select
                style="width: 200px"
                defaultValue=""
                placeholder="请选择状态"
                v-model="searchForm.status"
              >
                <a-select-option
                  v-for="item in invoiceStatus"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :lg="8" :md="8" :sm="12">
            <a-form-item label="申请时间">
              <a-range-picker v-model="date" @change="handleDateChange" />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="12">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="handleToSearchList"
                >查询</a-button
              >
              <a-button
                style="margin-left: 8px"
                icon="reload"
                @click="resetSearchForm"
                >重置</a-button
              >
              <a-button
                type="primary"
                style="margin-left: 8px"
                icon="export"
                v-has="'invoiceCenter:xqyfpdc'"
                @click="exportDetail('发票明细')"
                >导出</a-button
              >
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
<div style="max-width:1300px">
    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="tableColumns"
      :data="loadAccountList"
      bordered
      :scroll="{ x: 1300 }"
      style="width:1500px;margin-top:20px"
    >
      <span slot="action" slot-scope="text, record">
        <a @click="checkDetail(record)" v-has="'invoiceCenter:xqyfpck'"
          >查看详情</a
        >
        <a-divider
          v-has="'invoiceCenter:xqyfpfs'"
          v-if="record.invoiceType === '10' && record.status !== '102822'"
          type="vertical"
        />
        <a
          v-has="'invoiceCenter:xqyfpfs'"
          v-if="record.invoiceType === '10' && record.status !== '102822'"
          @click="sendEmail(record)"
          >发送</a
        >
        <template
          v-if="record.status === '102801'"
          v-has="'invoiceCenter:xqyfpsqch'"
        >
          <a-divider type="vertical" />
          <a @click="reReturn(record)">撤销申请</a>
        </template>
        <!-- <template v-if="record.status === '102811'">
          <a-divider type="vertical" />
          <a @click="handleDealRowSend(record)">寄送</a>
        </template> -->
      </span>
    </s-table>
</div>
    <!-- 详情 -->
    <a-modal
      title="详情"
      width="100%"
      class="detail-modal"
      :footer="null"
      :visible="visibleDetailModal"
      @cancel="visibleDetailModal = false"
      :dialog-style="{ top: '0px' }"
    >
      <BusinessDetail
        @refresh="handleToSearchList"
        v-if="visibleDetailModal"
        :id="currentId"
      />
    </a-modal>
  </a-card>
</template>
<script>
import STable from "@/components/table/";
import AInput from "ant-design-vue/es/input/Input";
import { getArrItem } from "@/utils/util.js";
import BusinessDetail from "./components/NewBusinessDetail.vue";
import {
  getApplyInvoiceTradeList,
  handleInvoiceApply,
  sendBusinessInvoice,
  sendInvoice,
  repealApply,
  exportReportInvoiceList,
  getChanneConfirmInvoiceList,
} from "@/api/api";

export default {
  name: "BusinessInvoice",
  components: {
    AInput,
    STable,
    BusinessDetail,
  },
  data() {
    const cellStyle = {
      maxWidth: "120px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      cursor: "pointer",
    };
    return {
      date: [],
      searchForm: {
        enterpriseName: "",
        startDate: "",
        finishDate: "",
      },
      invoiceStatus: [
        {
          value: "102801",
          label: "已申请",
        },
        {
          value: "102811",
          label: "处理中",
        },
        {
          value: "102821",
          label: "处理完成",
        },
        {
          value: "102822",
          label: "处理完成已发送",
        },
        {
          value: "102831",
          label: "已撤销",
        },
      ],
      tableColumns: [
        {
          title: "申请单号",
          dataIndex: "id",
          align: "center",
          width: "180px",
          customCell: () => {
            return {
              style: cellStyle,
            };
          },
          customRender: (text) => (
            <a-tooltip placement="top" title={text}>
              {text}
            </a-tooltip>
          ),
        },
        {
          title: "批次号",
          align: "center",
          dataIndex: "batchId",
          width: "260px",
        },
        {
          title: "企业名称",
          align: "center",
          dataIndex: "enterpriseName",
          width: "150px",
        },

        {
          title: "开票金额",
          align: "center",
          width: "90px",
          dataIndex: "invoiceAmount",
          align: "center",
        },
        {
          title: "任务名称",
          align: "center",
          width: "90px",
          dataIndex: "title",
          align: "center",
        },

        {
          title: "操作",
          align: "center",
          dataIndex: "action",
          width: "210px",
          scopedSlots: { customRender: "action" },
          fixed: "right",
        },
      ],
      currentId: "",
      visibleDetailModal: false,
      form: this.$form.createForm(this, { name: "fapiao" }),
      loadingBusinessLicence: false,
      visibleInvoice: false,
      confirmLoading: false,
      record: "",
    };
  },
  methods: {
    // 导出发票明细
    exportDetail(fileName) {
      if (!fileName || typeof fileName != "string") {
        fileName = "导出文件";
      }
      let param = { ...this.searchForm };
      exportReportInvoiceList(param).then((data) => {
        if (!data) {
          this.$message.warning("文件下载失败");
          return;
        } else {
          this.$message.success("导出成功");
        }
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(new Blob([data]), fileName + ".xls");
        } else {
          let url = window.URL.createObjectURL(new Blob([data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName + ".xls");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
      });
    },
    /**
     * @description: 创建时间段选择
     * @param : date date
     * @param : dateString 时间数组
     * @return: void
     * @Author: HuangCheng
     */
    handleDateChange(date, dateString) {
      this.searchForm.startDate = `${dateString[0]} 00:00:00`;
      this.searchForm.finishDate = `${dateString[1]} 23:59:59`;
    },
    /**
     * @description: 加载列表数据
     * @param : parameter 查询参数
     * @return: promise
     * @Author: HuangCheng
     */
    loadAccountList(parameter) {
      return getChanneConfirmInvoiceList(
        Object.assign(parameter, this.searchForm)
      ).then((res) => {
        // if (!this.$store.state.user.userRules.includes('user:setStar')) {
        //   if (res.result.data.length>0) {
        //     res.result.data.forEach(item=>{
        //       let phone=/(.{3}).{2,}(\d{3})/
        //       let enterpriseName = /(.{2}).{1,}(.{2})/
        //       let tradeDetail = /(.{4}).{1,}/
        //       let addr = /.{1,}/
        //       let account = /.{1,}/
        //       let userName = /(.{1}).{1,}/
        //       let email = /(.{3}).{1,}/
        //       let idcard = /(\d{3})\d{11}(\w{4})/
        //       // item.batchId = item.batchId.replace(tradeDetail, "$1******")
        //       item.enterpriseName =item.enterpriseName===null?item.enterpriseName:item.enterpriseName.replace(enterpriseName, "$1******$2")
        //       // item.contactsAddress = item.contactsAddress.replace(addr, "******")
        //       // item.contactsPhone = item.contactsPhone.replace(phone, "$1*******$2")
        //       item.actualInvoiceAmount = String(item.actualInvoiceAmount).replace(account, "***")
        //       // item.applyAmount = String(item.applyAmount).replace(account, "***")
        //       // item.userNikeName = item.userNikeName.replace(userName, "$1**")
        //       // item.userName = item.userName.replace(userName, "$1**")
        //       // item.idcardNo = item.idcardNo.replace(idcard, "$1***********$2")
        //     })
        //   }
        // } else {
        //   if (res.result.data.length>0) {
        //     res.result.data.forEach(item=>{
        //       item.actualInvoiceAmount = (item.actualInvoiceAmount).toFixed(2)
        //     })
        //   }
        // }
        return res.result;
      });
    },
    /**
     * @description: 查看详情
     * @param : record
     * @return: void
     * @Author: HuangCheng
     */
    checkDetail(record) {
      this.currentId = record.id;
      this.visibleDetailModal = true;
    },
    /**
     * @description: 处理发票
     * @param : record 当前行数据
     * @param : val 处理类型
     * @return: void
     * @Author: HuangCheng
     */
    handleDealRow(record, val) {
      const that = this;
      const type = val === "deal" ? "处理" : val === "send" ? "寄送" : "";
      this.$confirm({
        title: type,
        content: (h) => (
          <div>
            {type}单号<span style="color:red;">“{record.id}”</span>?
          </div>
        ),
        onOk() {
          if (val === "deal") {
            // 处理中
            return new Promise((resolve, reject) => {
              handleInvoiceApply(record.id).then((res) => {
                if (res.success) {
                  that.$message.success(`“${record.id}”正在处理中`);
                  that.handleToSearchList();
                  resolve();
                } else {
                  that.$message.error(res.message);
                  reject();
                }
              });
            }).catch(() => console.log("Oops errors!"));
          } else if (val === "send") {
            // 已寄送
            return new Promise((resolve, reject) => {
              sendBusinessInvoice(record.id).then((res) => {
                if (res.success) {
                  that.$message.success(`“${record.id}”已寄送`);
                  that.handleToSearchList();
                  resolve();
                } else {
                  that.$message.error(res.message);
                  reject();
                }
              });
            });
          }
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    },
    // 申请撤回
    reReturn(record) {
      let that = this;
      this.$confirm({
        title: "确定撤销",
        onOk() {
          return new Promise((resolve, reject) => {
            repealApply({ id: record.id }).then((res) => {
              if (res.success) {
                that.$message.success(res.message);
                that.handleToSearchList();
                resolve();
              } else {
                that.$message.error(res.message);
                reject();
              }
            });
          }).catch(() => console.log("Oops errors!"));
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    },
    // 寄送发票
    handleDealRowSend(record) {
      // console.log(record)
      this.record = record;
      this.visibleInvoice = true;
      this.form.resetFields();
    },
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log(values)
          sendBusinessInvoice(
            Object.assign({ id: this.record.id }, values)
          ).then((res) => {
            // console.log(res)
            if (res.success) {
              this.$message.success(res.message);
              this.handleToSearchList();
              this.confirmLoading = true;
              setTimeout(() => {
                this.visibleInvoice = false;
                this.confirmLoading = false;
              }, 1000);
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    handleCancel(e) {
      this.visibleInvoice = false;
      this.form.resetFields();
    },
    /**
     * @description: 查询企业发票列表
     * @Author: HuangCheng
     */
    handleToSearchList() {
      this.$refs.table.refresh(true);
    },
    /**
     * @description: 重置搜索条件
     * @param :
     * @return:
     * @Author: HuangCheng
     */
    resetSearchForm() {
      this.searchForm = {
        enterpriseName: "",
        startDate: "",
        finishDate: "",
      };
      this.date = [];
      this.handleToSearchList();
    },
    // 发送邮件
    sendEmail(record) {
      let that = this;
      this.$confirm({
        title: "确认发送?",
        onOk: () => {
          sendInvoice({ id: record.id }).then((res) => {
            // console.log(res)
            if (res.success) {
              that.$message.success(res.message);
              that.handleToSearchList();
            } else {
              that.$message.error(res.message);
            }
          });
        },
        onCancel() {},
      });
    },
  },
};
</script>
<style lang="less">
.detail-modal {
  top: 0;
  height: 100vh;
  .ant-modal-content {
    height: 100vh;
    .ant-modal-body {
      height: calc(100% - 55px);
      padding: 0;
    }
  }
}
</style>