<!--
 * @Description: 详情
 * @Author: HuangCheng
 * @Date: 2020-03-12 10:46:47
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-21 14:43:40
 -->
<template>
  <a-card :bordered="false">
    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.batchId"
      :columns="tableColumns"
      :data="loadAccountList"
      bordered
    >
      <span slot="headPortrait" slot-scope="text,record">
        <img v-if="record.headPortrait!==null" style="width:50px;height:50px" :src="text" alt="">
      </span>
    </s-table>
  </a-card>
</template>
<script>
  import STable from '@/components/table/';
  import AInput from "ant-design-vue/es/input/Input";
  import {
    getFkDetail,
    getInvoiceTradesAmountList
  } from '@/api/api';

  export default {
    name: "BusinessInvoice",
    components: {
      AInput,
      STable,
    },
    props: {
      id: {
        type: Object,
        required: false
      },
    },
    data () {
      const cellStyle = {
        maxWidth: '120px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow:'ellipsis',
        cursor:'pointer'
      };
      return {
        date: [],
        queryParam: {
          enterpriseName: this.id.enterpriseAuthName
        },
        // 表头
        tableColumns: [
          {
            title: '批次号',
            align: 'center',
            dataIndex: 'batchId',
          },
          {
            title: '付款余额',
            align: 'center',
            dataIndex: 'payAmount',
          },
          {
            title: '手续费金额',
            align: 'center',
            dataIndex: 'feeAmount',
          },
          {
            title: '实际收入金额',
            align: 'center',
            dataIndex: 'incomeAmount',
          },
          {
            title: '支付日期',
            align: 'center',
            dataIndex: 'orderPayTime',
          }
        ],
        loadAccountList: (parameter) => {
          return getInvoiceTradesAmountList(Object.assign(parameter, this.queryParam))
            .then(res => {
              // if (!this.$store.state.user.userRules.includes('user:setStar')) {
              //   if (res.result.data.length>0) {
              //     res.result.data.forEach(item=>{
              //       let phone=/(\d{3})\d{4}(\d{4})/
              //       let enterpriseName = /(.{2}).{1,}(.{2})/
              //       let account = /.{1,}/
              //       let userName = /(.{1}).{1,}/
              //       let bank = /(\d{3})\d{1,}(\d{4})/
              //       let idcard = /(\d{3})\d{11}(\w{4})/
              //       item.enterpriseAuthName = item.enterpriseAuthName.replace(enterpriseName, "$1******$2")
              //       item.payAmount = String(item.payAmount).replace(account, "***")
              //       item.feeAmount = String(item.feeAmount).replace(account, "***")
              //       item.incomeAmount = String(item.incomeAmount).replace(account, "***")
              //     })
              //   }
              // } else {
              //   if (res.result.data.length>0) {
              //     res.result.data.forEach(item=>{
              //       item.payAmount = item.payAmount.toFixed(2)
              //       item.feeAmount = item.feeAmount.toFixed(2)
              //       item.incomeAmount = item.incomeAmount.toFixed(2)
              //     })
              //   }
              // }
              return res.result
            })
        },
        form: this.$form.createForm(this, { name: 'fapiao' }),
        record: '',
        currentId: '',
        visibleDetail: false,
      }
    },
    methods: {
      /**
       * @description: 查看详情
       * @param : record
       * @return: void
       * @Author: HuangCheng
       */
      checkDetail(record) {
        // console.log(record)
      },
      /**
       * @description: 查询企业发票列表
       * @Author: HuangCheng
       */
      handleToSearchList() {
        this.$refs.table.refresh(true);
      },
      /**
       * @description: 重置搜索条件
       * @param : 
       * @return: 
       * @Author: HuangCheng
       */
      resetSearchForm() {
        this.searchForm = {
          accountType: undefined,
        };
        this.date = [];
        this.handleToSearchList();
      },
    },
  }
</script>
<style lang="less">
.detail-modal {
  top: 0;
  height: 100vh;
  .ant-modal-content {
    height: 100vh;
    .ant-modal-body {
      height: calc(100% - 55px);
      padding: 0;
    }
  }
}
</style>