<template>
  <div class="container">
    <div class="header">确认发票</div>
    <div class="desc"><span style="display:inline-block;width:2px;height:14px;background:#1890ff;margin-right:5px"></span> 请选择任务开票金额</div>
    <div>
      <s-table
        ref="qyryTable"
        style="border-top:1px solid #F0F0F0;margin-top:16px"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columnsQyry"
        :data="dataQyry"
        :rowKey="(record) => record.id"
      >
        <span slot="action" slot-scope="text, record" v-if="raskDetailInfo.status!=='50'">
          <a v-if="record.signStatus==='20'" @click="cancelSign(record)">取消用工</a>
        </span>
        <span slot="signStatus" slot-scope="text">
          <div class="table-point" v-if="text==='20'"><span style="background:#1890FF"></span> 待确认</div>
          <div class="table-point" v-else-if="text==='30'"><span style="background:#52C41A"></span> 已确认</div>
          <div class="table-point" v-else-if="text==='93'"><span style="background:#F00"></span> 未接受</div>
          <div class="table-point" v-else><span style="background:#BFBFBF"></span> 已取消</div>
        </span>
      </s-table>
    </div>
    <div class="desc"><span style="display:inline-block;width:2px;height:14px;background:#1890ff;margin-right:5px"></span> 确认开票信息</div>
    <div class="desc"><a-icon theme="filled" style="color:#1890ff;margin-right:4px" type="info-circle" />请核实发票信息是否正确，如果不正确请联系客户经理进行修改。</div>
    <a-alert style="margin-bottom:10px" v-if="params.invoiceType==='20'&&sjrInfo" :message="'邮寄地址：'+sjrInfo.contactsName+'/'+sjrInfo.contactsPhone+'/'+sjrInfo.contactsAddress" type="success" />
    <a-alert style="margin-bottom:10px" v-if="params.invoiceType!=='20'&&sjrInfo" :message="'邮寄地址：'+userEmail" type="success" />
    <ul class="content" v-if="baseInfo">
      <li>
        <span class="left">发票抬头：</span>
        <span>{{baseInfo.name}}</span>
      </li>
      <li>
        <span class="left">税务登记证号：</span>
        <span>{{baseInfo.creditCode}}</span>
      </li>
      <li>
        <span class="left">开户银行：</span>
        <span>{{baseInfo.bankName}}</span>
      </li>
      <li>
        <span class="left">注册场所地址：</span>
        <span>{{baseInfo.address}}</span>
      </li>
      <li>
        <span class="left">注册电话：</span>
        <span>{{baseInfo.phone}}</span>
      </li>
      <li>
        <span class="left">开票金额：</span>
        <span>{{TicketAccount}}元</span>
      </li>
      <li>
        <span class="left">发票类型：</span>
        <a-select v-model="params.invoiceType" style="width: 190px">
          <a-select-option value="10">
            增值税普通发票
          </a-select-option>
          <a-select-option value="20">
            增值税专用发票
          </a-select-option>
        </a-select>
      </li>
      <li>
        <span class="left">发票样式：</span>
        <span v-if="params.invoiceType==='10'">电子发票</span>
        <span v-else>纸质发票</span>
      </li>
      <li>
        <span class="left">发票内容：</span>
        <a-select v-model="params.taskType" placeholder="请选择发票内容" style="width: 190px">
          <a-select-option :value="item.invoiceId" v-for="(item,index) in options" :key="index">
            {{item.invoiceName}}
          </a-select-option>
        </a-select>
      </li>
      <li style="align-items:flex-start">
        <span class="left">备注：</span>
        <a-textarea v-model="params.remark" placeholder="请填写开票说明" style="width:350px;height:84px"></a-textarea>
      </li>
      <!-- <li>
        <span class="left">邮寄地址：</span>
        <span>{{sjrInfo.contactsAddress}}</span>
      </li> -->
      <li>
        <span class="left"></span>
        <div>
          <a-button type="primary" :loading='loading' @click="submit">提交</a-button>
          <a-button style="margin-left:8px" @click="cancel">取消</a-button>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import Vue from 'vue'
import STable from '@/components/table/'
import {
  ticketInfos,
  getRaskType,
  submitTicket,
  getInvoiceMailed,
  getInvoiceByIndustryId,
  getUserBindEmail,
  taskWorkerPayList,
  confirmInvoice,
  getInvoiceInfoByEnterpriseIdChannel,
  getOrderList,
  getInvoiceByIndustryId2,
  getInvoiceMailedByEnterpriseId,
} from '@/api/api'
export default {
  components: {
    STable
  },
  data () {
    return {
      ticketInfo: null,
      baseInfo:null,
      options: [],
      params: {
        taskType: undefined,
        remark: '',
        invoiceType: '20'
      },
      sjrInfo: null,
      loading: false,
      userEmail: null,
      columnsQyry: [
        {
          title: '任务名称',
          dataIndex: 'title',
          customRender: text => text===null?'--':text
        },
        {
          title: '支付笔数',
          dataIndex: 'payNum',
          customRender: text => text===null?'--':text
        },
        {
          title: '支付金额',
          dataIndex: 'payAmount',
          customRender: text => text===null?'--':text.toFixed(2)
        },
        {
          title: '费用金额',
          dataIndex: 'payFeeAmount',
          customRender: text => text === null?'--':text.toFixed(2)
        },
        {
          title: '支付总金额',
          dataIndex: 'payTotalAmount',
          customRender: text => text === null?'--':text.toFixed(2)
        },
        {
          title: '付款周期开始时间',
          dataIndex: 'payPeriodStartTime',
          customRender: text => text===null?'--':text
        },
        {
          title: '付款周期结束时间',
          dataIndex: 'payPeriodEndTime',
          customRender: text => text===null?'--':text
        },
        {
          title: '付款时间',
          dataIndex: 'payTime',
          customRender: text => text===null?'--':text
        }
      ],
      selectedRowKeys: [],
      currentSelectedKeys: []
    }
  },
  created () {
    this.ticketInfo = JSON.parse(window.sessionStorage.getItem('ticketInfo'))
    this.getInvoiceMailed()
    this.getContent()
    this.getTicketBaseInfo()
  },
  computed: {
    TicketAccount () {
      if (this.currentSelectedKeys.length===0) {
        return 0
      } else {
        let res = 0
        this.currentSelectedKeys.forEach(item=>{
          res += item.payTotalAmount
        })
        return res
      }
    }
  },
  methods: {
    // 表格操作
    onSelectChange(selectedRowKeys,selectedRows) {
      let sek = []
      let ser = []
      selectedRows.forEach((item,index) => {
        sek.push(item.id)
        ser.push(item)
      })
      this.selectedRowKeys = sek;
      this.currentSelectedKeys = JSON.parse(JSON.stringify(ser))
      // console.log(this.currentSelectedKeys)
    },
    // 获取getQyryList
    dataQyry (parameter) {
      return getOrderList(Object.assign({taskId:this.ticketInfo.taskId,invoiceStatus:'10'},parameter)).then(res => {
        if (res.success) {
          if(res.result.data.length>0) {
            res.result.data.forEach(item=>{
              if(item.signStatus==='30') {
                this.canCancel = false
              }
            })
          }
        }
        // console.log(res)
        return res.result
      })
    },
    // 获取发票内容
    getContent () {
      let params = {
        enterpriseId:this.ticketInfo.enterpriseId,
        industryId:this.ticketInfo.taskType
      }
      getInvoiceByIndustryId2(params).then(res => {
        this.options = res.result
      })
    },
    // 查询收件人信息
    getInvoiceMailed () {
      getInvoiceMailedByEnterpriseId(this.ticketInfo.enterpriseId).then(res => {
        if (res.success) {
          this.sjrInfo = res.result
        }
      })
    },
    // 获取发票信息
    getTicketBaseInfo () {
      getInvoiceInfoByEnterpriseIdChannel(this.ticketInfo.enterpriseId).then(res => {
        if(res.success) {
          this.baseInfo = res.result
          getUserBindEmail().then(res => {
            // console.log(res)
            if(res.success) {
              this.userEmail = res.message
            }
          })
        }
      })
    },
    // 提交
    submit () {
      if (this.params.taskType===undefined) {
        this.$message.info('请选择发票内容')
        return
      }
      if (this.currentSelectedKeys.length===0) {
        this.$message.info('请选择需要开票的任务')
        return
      }
      if(!this.sjrInfo){
        this.$message.info('收件人信息有误')
        return
      }
      this.loading = true
      let params = {}
      let invoiceIds = []
      this.currentSelectedKeys.forEach(item=>{
        invoiceIds.push(item.orderBatchId)
      })
      invoiceIds = Array.from(new Set(invoiceIds)).join(',')
      if (this.params.invoiceType==='10') {
        params = {
          actualInvoiceAmount: this.TicketAccount,
          applyAmount: this.TicketAccount,
          bankName: this.baseInfo.bankName,
          bankNo: this.baseInfo.bankNo,
          batchId: invoiceIds,
          contactPhone: this.baseInfo.phone,
          contactsAddress: this.sjrInfo.contactsAddress,
          contactsName: this.sjrInfo.contactsName,
          contactsPhone: this.sjrInfo.contactsPhone,
          creditCode: this.baseInfo.creditCode,
          enterpriseId: this.baseInfo.enterpriseId,
          invoiceName: this.baseInfo.name,
          invoiceType: this.params.invoiceType,
          oweTicketAmount: 0,
          registerAddress: this.baseInfo.address,
          remark: this.params.remark,
          scopeId: this.params.taskType,
          taskId: this.ticketInfo.taskId,
          sendEmail: this.userEmail,
          payId: this.selectedRowKeys
        }
      } else {
        params = {
          actualInvoiceAmount: this.TicketAccount,
          applyAmount: this.TicketAccount,
          bankName: this.baseInfo.bankName,
          bankNo: this.baseInfo.bankNo,
          batchId: invoiceIds,
          contactPhone: this.baseInfo.phone,
          contactsAddress: this.sjrInfo.contactsAddress,
          contactsName: this.sjrInfo.contactsName,
          contactsPhone: this.sjrInfo.contactsPhone,
          creditCode: this.baseInfo.creditCode,
          enterpriseId: this.baseInfo.enterpriseId,
          invoiceName: this.baseInfo.name,
          invoiceType: this.params.invoiceType,
          oweTicketAmount: 0,
          registerAddress: this.baseInfo.address,
          remark: this.params.remark,
          scopeId: this.params.taskType,
          payId: this.selectedRowKeys
        }
      }
      confirmInvoice(params).then(res => {
        this.loading = false
        if(res.success) {
          this.$message.success(res.message)
          this.cancel()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 取消
    cancel () {
      window.sessionStorage.setItem('submenu','businessInvoice')
      this.$router.push('/businessInvoice')
    }
  }
}
</script>
<style scoped lang="less">
.container{
  font-size: 14px;
  color: #202020;
  padding: 24px;
}
.header{
  font-size: 24px;
  color: #202020;
}
.desc{
  margin: 20px 0;
  display: flex;
  align-items: center;
}
.content{
  padding: 0;
  list-style: none;
  li{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .left{
      width: 100px;
      text-align: right;
    }
    &:last-child{
      align-items: flex-start;
    }
  }
}
</style>