<!--
 * @Descripttion: 
 * @version: 
 * @Author: xyh
 * @Date: 2021-04-13 10:29:31
 * @LastEditTime: 2021-04-14 13:57:55
-->
<template>
  <div class="detail_config">
    <!-- 面包屑 -->
    <div class="bread">
      <span class="txt_e" @click="toEnterpriseManage">企业管理</span>
      <a-icon type="right" :style="{ color: '#DEDEDE' }" />
      <span class="txt_d">详情配置</span>
    </div>
    <!-- 基本信息 -->
    <div class="basic_info">
      <div class="edit_section">
        <commonTitle title="基本信息" />
        <div class="edit_btn">
          <span class="edit">编辑</span>
          <!-- <a-button type="primary">保存</a-button> -->
        </div>
      </div>
      <div class="info_box">
        <div class="row mar_bott_18">
          <div class="col">
            <span class="type">企业编号：</span
            ><span class="desc">{{ detailInfo.id }}</span>
          </div>
          <div class="col">
            <span class="type">联系人：</span
            ><span class="desc">{{ detailInfo.enterpriseContacts }}</span>
          </div>
          <div class="col">
            <span class="type">企业状态：</span><span class="desc">{{detailInfo.status === '101101' ? '不正常/未审核' : detailInfo.status === '101102' ? '不正常/停用' : detailInfo.status === '101111' ? '正常/审核成功' : ''}}</span>
          </div>
        </div>
        <div class="row mar_bott_18">
          <div class="col">
            <span class="type">企业名称：</span
            ><span class="desc">{{detailInfo.enterpriseName}}</span>
          </div>
          <div class="col">
            <span class="type">联系电话：</span
            ><span class="desc">{{detailInfo.enterpriseContacts}}</span>
          </div>
          <div class="col">
            <span class="type">纳税人类型：</span
            ><span class="desc">{{taxpayerTypeText[detailInfo.taxpayerType]}}</span>
          </div>
        </div>
        <div class="row mar_bott_18">
          <div class="col">
            <span class="type">创建时间：</span
            ><span class="desc">2021-04-09 17:11:28</span>
          </div>
          
        </div>
      </div>
    </div>

    <!-- 合同信息 -->
    <div class="contract_info">
      <ContractInfo :enterpriseId="enterpriseId" />
    </div>

    <!-- 企业认证 -->
    <div class="enterprise_auth">
      <div class="edit_section">
        <commonTitle title="企业认证" />
        <div class="edit_btn">
          <!-- <span class="edit">编辑</span> -->
          <a-button type="primary">保存</a-button>
        </div>
      </div>
      <div class="info_box">
        <a-form-model
          ref="companyIdentifyRule"
          :model="companyIdentifyParams"
          :rules="companyIdentifyRules"
          layout="inline"
        >
          <a-form-model-item
            label="工商注册名称"
            prop="realName"
            ref="realName"
          >
            <a-input
              v-model="companyIdentifyParams.realName"
              @blur="
                () => {
                  $refs.realName.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item
            label="企业信用代码"
            prop="realCode"
            ref="realCode"
          >
            <a-input
              v-model="companyIdentifyParams.realCode"
              @blur="
                () => {
                  $refs.realCode.onFieldBlur();
                }
              "
            />
          </a-form-model-item>

          <a-form-model-item label="过期时间" prop="remarkDate">
            <a-date-picker
              :allowClear="false"
              v-model="companyIdentifyParams.remarkDate"
              :value="timeValue"
              @change="changeTime"
              style="width: 100%"
            />
          </a-form-model-item>
          <div style="margin-top: 30px; padding-right: 15px">
            <span style="color: #ff0000">资料上传：</span>
            <a-form-model-item label="" prop="enterpriseLicense" required>
              <a-upload
                name="avatar"
                listType="picture-card"
                class="avatar-uploader"
                :action="url.frontUrl"
                :headers="headers"
                :showUploadList="false"
                :beforeUpload="beforeUpload"
                @change="handleChange($event, 'enterpriseLicense')"
                v-decorator="[
                  'enterpriseLicense',
                  { rules: [{ required: true, message: '请上传营业执照!' }] },
                ]"
              >
                <img
                  class="picture-common"
                  v-if="enterpriseLicense"
                  :src="enterpriseLicense"
                  alt="avatar"
                />
                <div v-else>
                  <a-icon :type="loadingBusinessLicence ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">营业执照</div>
                </div>
              </a-upload>
            </a-form-model-item>
            <a-form-model-item label="">
              <div class="upload_box">
                <a-upload
                  name="企业现场照片"
                  listType="picture-card"
                  class="avatar-uploader"
                  :action="url.frontUrl"
                  :headers="headers"
                  :showUploadList="false"
                  :beforeUpload="beforeUpload"
                  @change="handleChange($event, 'sitePhotos1')"
                >
                  <img
                    class="picture-common"
                    v-if="sitePhotos1"
                    :src="sitePhotos1"
                    alt="avatar"
                  />
                  <div v-else>
                    <a-icon :type="loadingidCardFront ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">企业现场照片</div>
                  </div>
                </a-upload>
                <a-upload
                  name="企业现场照片"
                  listType="picture-card"
                  class="avatar-uploader"
                  :action="url.frontUrl"
                  :headers="headers"
                  :showUploadList="false"
                  :beforeUpload="beforeUpload"
                  @change="handleChange($event, 'sitePhotos2')"
                >
                  <img
                    class="picture-common"
                    v-if="sitePhotos2"
                    :src="sitePhotos2"
                    alt="avatar"
                  />
                  <div v-else>
                    <a-icon :type="loadingidCardOpsite ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">企业现场照片</div>
                  </div>
                </a-upload>
              </div>
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
    </div>

    <!-- 开票范围 -->
    <div class="invoice_range">
      <div class="edit_section">
        <commonTitle title="开票范围" />
        <div class="edit_btn">
          <span class="edit" @click="openInvoiceRangeModal">添加开票范围</span>
          <!-- <a-button type="primary">保存</a-button> -->
        </div>
      </div>
      <div class="info_box">
        <s-table
          ref="table"
          size="default"
          :rowKey="(record, index) => index"
          :columns="scopeColumns"
          :data="loadScopeList"
          :pagination="pagination"
          bordered
        >
          <span
            slot="action"
            slot-scope="text, record"
            v-if="record.status === '100201'"
          >
            <a @click="freeStaff(record)">冻结</a>
          </span>
          <span slot="action" slot-scope="text, record" v-else>
            <a @click="returnOk(record)">解冻</a>
          </span>
        </s-table>

        <!-- 添加 -->
        <a-modal
          title="添加"
          :visible="addInvoiceRangeModal"
          @ok="handleScopeModalOk"
          @cancel="handleScopeModalCancel"
        >
          <a-form :form="addScopeForm" class="config-form">
            <a-form-item
              label="开票范围"
              :labelCol="{ lg: { span: 4 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 20 }, sm: { span: 17 } }"
            >
              <a-select
                style="width: 100%"
                placeholder="请选择开票范围"
                showSearch
                :defaultActiveFirstOption="false"
                :filterOption="false"
                @search="handleSearchSelectCompany"
                mode="multiple"
                @change="handleChangeSelectCompany"
                v-decorator="[
                  'businessScopeId',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择开票范围',
                        type: 'array',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in scopeRateList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.businessScope }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-form>
        </a-modal>
      </div>
    </div>

    <!-- 配置信息 -->
    <div class="config_info">
      <div class="edit_section">
        <commonTitle title="配置信息" />
        <div class="edit_btn">
          <span class="edit" @click="handleConfigSubmit">{{modalType === 'detail' ? '修改' : '提交'}}</span>
          
        </div>
      </div>
      <div class="info_box">
          <!-- <a-form :form="configForm" class="config-form">
          
          
          <a-row class="form-row" :gutter="16">
            <a-col :lg="8" :md="12" :sm="24">
              <a-form-item label="企业绑定手机号" :labelCol="configLabelCol" :wrapperCol="configWrapperCol">
                <span v-if="modalType === 'detail'">{{configDetail.bind_phone}}</span>
                <a-input v-else placeholder="请输入企业绑定手机号"
                  v-decorator="[
                  'bind_phone', {rules: [{validator: validatePhone},{ required: true, message: '请输入企业绑定联系人', whitespace: true}]}]"
                />
              </a-form-item>
            </a-col>
            <a-col :lg="8" :md="12" :sm="24">
              <a-form-item label="企业绑定邮箱" :labelCol="configLabelCol" :wrapperCol="configWrapperCol">
                <span v-if="modalType === 'detail'">{{configDetail.bind_mail}}</span>
                <a-input v-else placeholder="请输入企业绑定邮箱"
                  v-decorator="[
                  'bind_mail', {rules: [{type:'email',message:'邮箱格式不正确'},{ required: true, message: '请输入企业绑定邮箱', whitespace: true}]}]"
                />
              </a-form-item>
            </a-col>
            <a-col :lg="8" :md="12" :sm="24">
              <a-form-item label="单笔限额" :labelCol="configLabelCol" :wrapperCol="configWrapperCol">
                <span v-if="modalType === 'detail'">{{configDetail.quotas_bank}}</span>
                <a-input v-else placeholder="请输入单笔限额"
                  v-decorator="['quotas_bank', {rules: [{ required: true, message: '请输入单笔限额', whitespace: true}]}]" />
              </a-form-item>
            </a-col>
          </a-row>
          
          
        </a-form> -->
      </div>
    </div>

    <!-- 开票信息 -->
    <div class="invoice_info">
      <div class="edit_section">
        <commonTitle title="开票信息" />
        <div class="edit_btn">
          <span class="edit">编辑</span>
          <!-- <a-button type="primary">保存</a-button> -->
        </div>
      </div>
      <div class="info_box">
        <div class="row">
          <div class="col mar_bott_18">
            <span class="type">户名：</span
            ><span class="desc">测试有限公司</span>
          </div>
          <div class="col">
            <span class="type">纳税人识别号：</span
            ><span class="desc">2341513516213616</span>
          </div>
          <div class="col">
            <span class="type" style="color: #ff0000">开户行：</span
            ><span class="desc" style="color: #ff0000">-</span>
          </div>
        </div>
        <div class="row mar_bott_18">
          <div class="col">
            <span class="type">账号：</span
            ><span class="desc">60203161313134682</span>
          </div>
        </div>
        <div class="email_tab">
          <EmailInfo
            :enterpriseId="enterpriseId"
            style="width: 100%"
          ></EmailInfo>
        </div>
      </div>
    </div>

    <!-- 费用配置 -->
    <div class="fee_config">
      <div class="edit_section">
        <commonTitle title="费用配置" />
        <div class="edit_btn">
          <span class="edit" @click="handleSubmitEditCharge" v-if="allowAuthor"
            >添加费用配置</span
          >
          <!-- <a-button type="primary">保存</a-button> -->
        </div>
      </div>
      <div class="info_box">
        <div style="display: flex; align-items: center; margin: 0 0 10px 0">
          <span>代理商：</span>
          <span v-if="dlsInfo" style="margin-right: 20px">{{
            dlsInfo.userName
          }}</span>
          <span v-else style="margin-right: 20px">--</span>
          <span>结算方式：</span>
          <span v-if="dlsInfo">{{
            dlsInfo.settlementMode === "100402" ? "汇总结算" : "单笔结算"
          }}</span>
          <span v-else style="margin-right: 20px">--</span>
          <!-- <a-button type="primary" style="margin-left:10px" @click="handleSubmitEditCharge" v-if="allowAuthor">新增</a-button> -->
          <a-button
            type="primary"
            @click="freeAthour"
            style="margin-left: 10px"
            v-if="!allowAuthor"
            >审核</a-button
          >
        </div>
        <div style="margin-bottom: 30px" v-if="dlsInfo">
          <h1>费用：</h1>
          <table class="tbl" cellpadding="0" cellspacing="0">
            <tr>
              <td>类型</td>
              <td>起始费用(元)</td>
              <td>
                结束费用(元)
                <a-tooltip>
                  <template slot="title"> 包含结束费用 </template>
                  <a-button
                    style="color: orange"
                    type="link"
                    shape="circle"
                    icon="info-circle"
                  ></a-button>
                </a-tooltip>
              </td>
              <td>费率(%)</td>
            </tr>
            <tr v-for="(item, index) in checkFeeResult" :key="index">
              <td>阶梯{{ index + 1 }}</td>
              <td>{{ item.ruleBegin }}</td>
              <td>{{ item.ruleEnd }}</td>
              <td>{{ item.feePoint }}</td>
            </tr>
          </table>
        </div>
        <div
          style="margin-bottom: 30px"
          v-if="dlsInfo && dlsInfo.settlementMode === '100401'"
        >
          <h1>提成：</h1>
          <table class="tbl" cellpadding="0" cellspacing="0">
            <tr>
              <td>类型</td>
              <td>起始费用(元)</td>
              <td>
                结束费用(元)
                <a-tooltip>
                  <template slot="title"> 包含结束费用 </template>
                  <a-button
                    style="color: orange"
                    type="link"
                    shape="circle"
                    icon="info-circle"
                  ></a-button>
                </a-tooltip>
              </td>
              <td>提成(%)</td>
            </tr>
            <tr v-for="(item, index) in checkFeeResult" :key="index">
              <td>阶梯{{ index + 1 }}</td>
              <td>{{ item.ruleBegin }}</td>
              <td>{{ item.ruleEnd }}</td>
              <td>{{ item.salesPoint }}</td>
            </tr>
          </table>
        </div>
        <a-modal
          title="新增"
          width="1100px"
          :confirm-loading="confirmLoadingFee"
          destroyOnClose
          :visible="visibleExamineModalFee"
          @ok="handleRechargeModalOkFee"
          @cancel="handleExamineModalCancelFee"
        >
          <!-- <div style="margin:0 0 20px 0">
            <span>代理商：</span>
            <a-select
              placeholder="请选择代理商"
              style="width: 80%"
              v-model="selectDls"
              @change="changeDls"
            >
              <a-select-option v-for="item in salesmanLists" :key="item" :value="item.userId">{{item.userName}}--{{item.settlementMode==='100402'?'汇总结算':'单笔结算'}}</a-select-option>
            </a-select>
          </div> -->
          <table class="tbl" cellpadding="0" cellspacing="0">
            <tr>
              <td>类型</td>
              <td>起始费用(元)</td>
              <td>
                结束费用(元)
                <a-tooltip>
                  <template slot="title"> 包含结束费用 </template>
                  <a-button
                    style="color: orange"
                    type="link"
                    shape="circle"
                    icon="info-circle"
                  ></a-button>
                </a-tooltip>
              </td>
              <td>费率(%)</td>
              <td>提成(%)</td>
              <td>操作</td>
            </tr>
            <tr v-for="(item, index) in chargeLists" :key="index">
              <td>阶梯{{ index + 1 }}</td>
              <td>
                <a-input
                  class="input"
                  type="number"
                  placeholder="请输入起始费用"
                  v-model="item.ruleBegin"
                />
              </td>
              <td>
                <a-input
                  class="input"
                  type="number"
                  placeholder="请输入结束费用"
                  v-model="item.ruleEnd"
                />
              </td>
              <td>
                <a-input
                  class="input"
                  type="number"
                  placeholder="请输入费用利率"
                  v-model="item.feePoint"
                />
              </td>
              <td>
                <a-input
                  class="input"
                  type="number"
                  placeholder="请输入提成"
                  :disabled="allowInput"
                  v-model="item.salesPoint"
                />
              </td>
              <td>
                <div class="operation">
                  <a-button
                    type="dashed"
                    icon="delete"
                    shape="circle"
                    v-show="index === chargeLists.length - 1 && index > 0"
                    @click="delCharge(index, item)"
                    style="margin-right: 10px"
                  ></a-button>
                  <a-button
                    type="primary"
                    icon="plus"
                    shape="circle"
                    v-show="index === chargeLists.length - 1"
                    @click="addCharge(index)"
                  ></a-button>
                </div>
              </td>
            </tr>
          </table>
        </a-modal>
        <!-- 审核 -->
        <a-modal
          title="审核"
          width="1100px"
          :visible="visibleVerfiy"
          :confirm-loading="confirmLoadingVerfiy"
          @ok="handleOkVerfiy"
          @cancel="handleCancelVerfiy"
        >
          <div style="margin-bottom: 30px" v-if="dlsInfo">
            <h4>费用：</h4>
            <table class="tbl" cellpadding="0" cellspacing="0">
              <tr>
                <td>类型</td>
                <td>起始费用(元)</td>
                <td>
                  结束费用(元)
                  <a-tooltip>
                    <template slot="title"> 包含结束费用 </template>
                    <a-button
                      style="color: orange"
                      type="link"
                      shape="circle"
                      icon="info-circle"
                    ></a-button>
                  </a-tooltip>
                </td>
                <td>费率(%)</td>
              </tr>
              <tr v-for="(item, index) in checkAuditFeeResult" :key="index">
                <td>阶梯{{ index + 1 }}</td>
                <td>{{ item.ruleBegin }}</td>
                <td>{{ item.ruleEnd }}</td>
                <td>{{ item.feePoint }}</td>
              </tr>
            </table>
          </div>
          <div
            style="margin-bottom: 30px"
            v-if="dlsInfo && dlsInfo.settlementMode !== '100402'"
          >
            <h4>提成：</h4>
            <table class="tbl" cellpadding="0" cellspacing="0">
              <tr>
                <td>类型</td>
                <td>起始费用(元)</td>
                <td>
                  结束费用(元)
                  <a-tooltip>
                    <template slot="title"> 包含结束费用 </template>
                    <a-button
                      style="color: orange"
                      type="link"
                      shape="circle"
                      icon="info-circle"
                    ></a-button>
                  </a-tooltip>
                </td>
                <td>提成(%)</td>
              </tr>
              <tr v-for="(item, index) in checkAuditFeeResult" :key="index">
                <td>阶梯{{ index + 1 }}</td>
                <td>{{ item.ruleBegin }}</td>
                <td>{{ item.ruleEnd }}</td>
                <td>{{ item.salesPoint }}</td>
              </tr>
            </table>
          </div>
          <a-radio-group name="radioGroup" v-model="selectRadio">
            <a-radio value="102211"> 通过 </a-radio>
            <a-radio value="102231"> 未通过 </a-radio>
          </a-radio-group>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import commonTitle from "../components/commonTitle";
import ContractInfo from "./components/ContractInfo";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import STable from "@/components/table/";
import EmailInfo from "./components/email";
import {
  getEnterpriseBaseInfo,
  getEnterpriseConfig,
  updateEnterpriseConfig,
  getEnterpriseScopeList,
  addEnterpriseBusinessScope,
  getBusinessScopeBox,
  freezeEnterpriseBusinessScope,
  updateCompanyBankInfo,
  updateCompanyIndentifyInfo,
  uploadFile,
  getCompanyIndentifyInfo,
  addCompanyBankInfo,
  getCompanyBankInfo,
  addRebateRules,
  updataRebateRules,
  getEnterpriseInvoiceMailed,
  saveEnterpriseInvoiceMailed,
  taxEnterpriseServiceRuleItemDel,
  taxEnterpriseServiceRuleItemEdit,
  feeAudit,
  getDlsInfo,
  feeAdd,
  feeQuery,
  getAgentBox,
  getUserLoginInfoNowAccount,
} from "@/api/api";
export default {
  name: 'raskDetail',
  components: {
    commonTitle,
    ContractInfo,
    STable,
    EmailInfo,
  },
  data() {
    return {
      enterpriseId: "1379686563335159809", //企业id
      taxpayerType: [{
          value: '102401',
          label: '一般纳税人'
        }, {
          value: '102411',
          label: '小规模纳税人'
        }],
      taxpayerTypeText:{
          '102401':'一般纳税人',
          '102411':'小规模纳税人',

      },
      companyIdentifyParams: {
        enterpriseId: "", // 企业编码
        realName: "", // 企业工商注册名称
        realCode: "", // 企业信用代码
        expirationDate: null, // 过期时间
        remarkDate: null,
        sitePhotos2: "", // 身份证反面
        sitePhotos1: "", // 身份证正面
        enterpriseLicense: "", // 营业执照
      }, // 企业认证参数
      companyIdentifyRules: {
        realName: [{ message: "请输入工商注册名称", trigger: "blur" }],
        realCode: [{ message: "请输入企业信用代码", trigger: "blur" }],
        remarkDate: [{ message: "请输入过期时间", trigger: "change" }],
        enterpriseLicense: [{ message: "请上传营业执照", trigger: "change" }],
        sitePhotos1: [{ message: "请上传身份证照片", trigger: "change" }],
      },
      url: {
        frontUrl: window._CONFIG["domianURL"] + "/api/tax/common/file/upload",
      }, //上传文件的地址
      headers: { "X-Access-Token": Vue.ls.get(ACCESS_TOKEN) },
      scopeColumns: [
        // {
        //   title: '序号',
        //   customRender:(text,record,index)=>`${index+1}`
        // },
        {
          title: "开票ID",
          align: "center",
          dataIndex: "businessScopeId",
        },
        {
          title: "开票内容",
          align: "center",
          dataIndex: "businessScopeName",
        },
        {
          title: "服务费扣点",
          align: "center",
          dataIndex: "taxRate",
          customRender: (text) => text * 100 + "%",
        },
        {
          title: "状态",
          align: "center",
          dataIndex: "status",
          customRender: (text) =>
            text === "100201" ? "正常" : text === "100202" ? "停用" : "",
        },
        {
          title: "创建时间",
          align: "center",
          dataIndex: "createTime",
        },
        {
          title: "操作",
          align: "center",
          dataIndex: "action",
          width: "150px",
          scopedSlots: { customRender: "action" },
        },
      ],
      pagination: {},
      addInvoiceRangeModal: false, //添加开票范围模态框状态
      scopeRateList: [], //开票范围
      addScopeForm: this.$form.createForm(this), // 添加开票范围表单
      allowAuthor: false,
      visibleExamineModalFee: false, //添加费用配置状态
      detailInfo: {
        id: "",
        enterpriseName: "",
        status: "",
        enterpriseContacts: "",
        enterprisePhone: "",
        createTime: "",
      },
      bankInfoParams: {
        bankName: "", // 银行名称
        enterpriseId: "", // 企业编码
        payeeBankNo: "", // 银行卡号
        payeeName: "", // 开户人
        subBankName: "", // 开户行
        id: "",
      },
      rebateParams: {
        rebatesState: undefined,
        rebatesCycle: undefined,
      },
      rebateRules: {
        rebatesState: [
          { required: true, message: "请选择是否返利", trigger: "change" },
        ],
        rebatesCycle: [
          { required: true, message: "请选择返利周期", trigger: "change" },
        ],
      },
      selectRebate: false,
      rebateId: 0,
      chargeId: 0,
      dlsInfo: null,
       // 配置信息
      configForm: this.$form.createForm(this),
      modalType: 'detail',
      configLabelCol: {
          lg: {span: 10},
          md: {span: 12}
        },
        configWrapperCol: {
          lg: {span: 14},
          md: {span: 12}
        },
    };
  },
  created() {
      console.log('======================');
      console.log(this.$route);
      console.log('======================');
    // this.enterpriseId = this.$route.query.id;
    this.enterpriseId = window.sessionStorage.getItem('enterpriseId')
    this.getEnterpriseBaseInfo();
    this.getEnterpriseConfig();
    this.getRebateRules();
    this.getDlsInfos();
  },
  mounted() {},
  methods: {
      toEnterpriseManage(){
          window.localStorage.setItem('submenu','raskManage')
    //   this.$router.push('/rask')
          this.$router.push('raskManage')
      },
    /**
     * @description: 获取企业详情
     * @Author: HuangCheng
     */
    getEnterpriseBaseInfo() {
      getEnterpriseBaseInfo(this.enterpriseId).then((res) => {
        if (res.success) {
          this.detailInfo = res.result;
          // // console.log('企业详情', this.detailInfo);
          this.bankInfoParams.payeeName = this.detailInfo.enterpriseContacts;
          this.bankInfoParams.enterpriseId = this.detailInfo.id;
          this.companyIdentifyParams.enterpriseId = this.detailInfo.id;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    /**
     * @description: 获取企业经营范围列表
     * @param : parameter 查询参数
     * @return: promise
     * @Author: HuangCheng
     */
    loadScopeList(parameter) {
      return getEnterpriseScopeList(
        Object.assign(parameter, {
          enterpriseId: this.enterpriseId,
        })
      ).then((res) => {
        this.pagination = {
          pageSize: res.result.pageSize,
          current: res.result.pageNo,
        };
        return res.result;
      });
    },
    /**
       * @description: 获取企业配置信息
       * @return: void
       * @Author: HuangCheng
       */
      getEnterpriseConfig() {
        getEnterpriseConfig(this.enterpriseId).then((res) => {
          // // console.log('企业配置信息', res)
          if (res.success) {
            this.configDetail = res.result;
            // this.configDetail.quotas_bank = Number(this.configDetail.quotas_bank).toFixed(2)
          } else {
            this.$message.error(res.message);
          }
        })
      },
      
      /**
       * @description: 配置信息按钮点击事件
       * @param : 
       * @return: 
       * @Author: HuangCheng
       */
      handleConfigSubmit() {
        const description = this.configForm.getFieldsValue();
        if (this.modalType === 'detail') {
          this.modalType = 'edit';
          const data = this.configDetail;
          setTimeout(() => {
            this.configForm.setFieldsValue({
              remind_type: data.remind_type,
              remind_email: data.remind_email,
              remind_phone: data.remind_phone,
              account_balance_remind_email: data.account_balance_remind_email,
              account_balance_remind_phone: data.account_balance_remind_phone,
              account_balance_remind_type: data.account_balance_remind_type,
              bank_balance_remind_pric: data.bank_balance_remind_pric,
              bill_remind_email: data.bill_remind_email,
              bind_mail: data.bind_mail,
              bind_phone: data.bind_phone,
              early_warning_remind_email: data.early_warning_remind_email,
              quotas_bank: data.quotas_bank,
              quotas_wx: data.quotas_wx,
              quotas_zfb: data.quotas_zfb,
              recharge_remind_email: data.recharge_remind_email,
              recharge_remind_phone: data.recharge_remind_phone,
              recharge_remind_type: data.recharge_remind_type,
              service_charge_bear: data.service_charge_bear,
              service_charge_point: data.service_charge_point,
              tax_remind_email: data.tax_remind_email,
              wx_balance_remind_pric: data.wx_balance_remind_pric,
              zfb_balance_remind_pric: data.zfb_balance_remind_pric
            });
          }, 0)
          return;
        }
        this.configForm.validateFields((err, values) => {
          if (err) {
            return;
          }
          const description = this.configForm.getFieldsValue();
          description.bind_mail = description.bind_mail.trim();
        //   this.loading = true;
          updateEnterpriseConfig(this.enterpriseId, description).then((res) => {
            // this.loading = false;
            if (res.success) {
              this.modalType = 'detail';
              this.getEnterpriseConfig();
              this.$message.success('修改成功');
            } else {
              this.$message.error(res.message);
            }
          });
        });
      },
    //选择开票范围
    handleChangeSelectCompany(value) {},
    handleSearchSelectCompany(value) {
      setTimeout(
        getBusinessScopeBox({
          businessScope: value,
        }).then((res) => {
          if (res.success) {
            this.scopeRateList = [];
            this.scopeRateList = res.result;
          } else {
            this.$message.error(res.message);
          }
        }),
        1000
      );
    },
    openInvoiceRangeModal() {
      this.addInvoiceRangeModal = true;
      getBusinessScopeBox({
        businessScope: "",
      }).then((res) => {
        if (res.success) {
          this.scopeRateList = res.result;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    /**
     * @description: 添加开票范围
     * @return: void
     * @Author: HuangCheng
     */
    handleScopeModalOk() {
      this.addScopeForm.validateFields((err, fieldsValue) => {
        if (err) {
          return;
        }
        const description = this.addScopeForm.getFieldsValue();
        // description.businessScopeId = description.businessScopeId.join(',');
        // // console.log(description)
        addEnterpriseBusinessScope(
          Object.assign(description, {
            enterpriseId: this.enterpriseId,
            taxRate: 0.06,
            ...description,
          })
        ).then((res) => {
          if (res.success) {
            this.handleToSearchScope();
            this.$message.success("添加成功");
            this.addScopeForm.resetFields();
            this.addInvoiceRangeModal = false;
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
    //开票范围取消
    handleScopeModalCancel() {
      this.addInvoiceRangeModal = false;
      this.addScopeForm.resetFields();
    },
    /**
     * @description: 查询企业开票范围
     * @Author: HuangCheng
     */
    handleToSearchScope() {
      this.$refs.table.refresh(true);
    },
    /**
     * @description: 冻结
     * @param : record 当前行数据
     * @return: void
     * @Author: HuangCheng
     */
    freeStaff(record) {
      const that = this;
      this.$confirm({
        title: `冻结`,
        content: (h) => (
          <div>
            确定冻结<span style="color:red;">“{record.businessScopeName}”</span>
            ?
          </div>
        ),
        onOk() {
          freezeEnterpriseBusinessScope({
            id: record.id,
            status: "100202",
          }).then((res) => {
            if (res.success) {
              that.$message.success(`“${record.businessScopeName}”已冻结`);
              that.handleToSearchScope();
            } else {
              that.$message.error(res.message);
            }
          });
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    },
    // 解冻
    returnOk(record) {
      const that = this;
      this.$confirm({
        title: `冻结`,
        content: (h) => (
          <div>
            确定解冻<span style="color:red;">“{record.businessScopeName}”</span>
            ?
          </div>
        ),
        onOk() {
          return new Promise((resolve, reject) => {
            freezeEnterpriseBusinessScope({
              id: record.id,
              status: "100201",
            }).then((res) => {
              if (res.success) {
                that.$message.success(`“${record.businessScopeName}”已解冻`);
                that.handleToSearchScope();
                resolve();
              } else {
                that.$message.error(res.message);
                reject();
              }
            });
          });
        },
        onCancel() {
          // console.log('Cancel');
        },
        class: "test",
      });
    },
    // 获取企业费用配置信息
    getRebateRules() {
      feeQuery({ enterpriseId: this.enterpriseId, status: "102211" }).then(
        (res) => {
          if (res.success) {
            if (res.result != null) {
              this.checkFeeResult = res.result;
              this.checkFeeResult.forEach((item) => {
                item.salesPoint = (item.salesPoint * 100).toFixed(4);
                item.feePoint = (item.feePoint * 100).toFixed(2);
              });
            }
          }
        }
      );
      feeQuery({ enterpriseId: this.enterpriseId, status: "102201" }).then(
        (res) => {
          if (res.success && res.result) {
            this.allowAuthor = false;
            this.checkAuditFeeResult = res.result;
            this.checkAuditFeeResult.forEach((item) => {
              item.salesPoint = (item.salesPoint * 100).toFixed(4);
              item.feePoint = (item.feePoint * 100).toFixed(2);
            });
          } else {
            this.allowAuthor = true;
          }
        }
      );
    },
    // 获取代理商信息
    getDlsInfos() {
      getDlsInfo(this.enterpriseId).then((res) => {
        if (res.success) {
          this.dlsInfo = res.result;
          this.selectDls = res.result.userId;
          this.allowInput =
            this.dlsInfo.settlementMode === "100402" ? true : false;
        }
      });
    },
  },
};
</script>

<style lang="less">
.tbl {
  width: 1000px;
  border: 1px solid #ccc;
  border-radius: 5px;
  border-collapse: separate;
  > tr {
    > td {
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding: 10px;
      &:last-child {
        border-right: 0;
      }
    }
    &:first-child {
      td:first-child {
        border-top: 0;
        border-left: 0;
      }
      td:last-child {
        border-top: 0;
        border-right: 0;
      }
    }
    &:last-child {
      td {
        border-bottom: 0;
      }
      td:first-child {
        border-left: 0;
      }
      td:last-child {
        border-right: 0;
      }
    }
  }
}
.upload_box {
  display: flex;
}
.detail_config {
  padding-bottom: 50px;
  .bread {
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #dedede;
    box-sizing: border-box;
    padding-left: 35px;
    margin-bottom: 30px;
    .txt_e {
      color: #347cf5;
      font-size: 14px;
      padding-right: 18px;
    }
    .txt_d {
      color: #333333;
      font-size: 14px;
      padding-left: 15px;
    }
  }
  .basic_info {
    padding-left: 38px;
    padding-right: 80px;
    margin-bottom: 50px;
    .edit_section {
      display: flex;
      justify-content: space-between;
      .edit_btn {
        .edit {
          color: #347cf5;
          font-size: 14px;
        }
      }
    }
    .info_box {
      padding-left: 18px;
      .row {
        display: flex;
        .col {
          width: 33.33333%;
          font-size: 14px;
          .type {
            color: #737373;
          }
          .desc {
            color: #262626;
          }
        }
      }
      .mar_bott_18 {
        margin-bottom: 18px;
      }
    }
  }
  .contract_info {
    padding-left: 38px;
    padding-right: 80px;
    margin-bottom: 50px;
    .edit_section {
      display: flex;
      justify-content: space-between;
      .edit_btn {
        .edit {
          color: #347cf5;
          font-size: 14px;
        }
      }
    }
    .info_box {
      padding-left: 18px;
    }
  }
  .enterprise_auth {
    padding-left: 38px;
    padding-right: 80px;
    margin-bottom: 50px;
    .edit_section {
      display: flex;
      justify-content: space-between;
      .edit_btn {
        .edit {
          color: #347cf5;
          font-size: 14px;
        }
      }
    }
    .info_box {
      padding-left: 18px;
    }
  }
  .invoice_range {
    padding-left: 38px;
    padding-right: 80px;
    margin-bottom: 50px;
    .edit_section {
      display: flex;
      justify-content: space-between;
      .edit_btn {
        .edit {
          color: #347cf5;
          font-size: 14px;
        }
      }
    }
    .info_box {
      padding-left: 18px;
    }
  }
  .invoice_info {
    padding-left: 38px;
    padding-right: 80px;
    margin-bottom: 50px;
    .edit_section {
      display: flex;
      justify-content: space-between;
      .edit_btn {
        .edit {
          color: #347cf5;
          font-size: 14px;
        }
      }
    }
    .info_box {
      padding-left: 18px;
      .row {
        display: flex;
        .col {
          width: 33.33333%;
          font-size: 14px;
          .type {
            color: #737373;
          }
          .desc {
            color: #262626;
          }
        }
      }
      .mar_bott_18 {
        margin-bottom: 18px;
      }
    }
  }
  .fee_config {
    padding-left: 38px;
    padding-right: 80px;
    margin-bottom: 50px;
    .edit_section {
      display: flex;
      justify-content: space-between;
      .edit_btn {
        .edit {
          color: #347cf5;
          font-size: 14px;
        }
      }
    }
    .info_box {
      padding-left: 18px;
    }
  }
  .config_info {
    padding-left: 38px;
    padding-right: 80px;
    margin-bottom: 50px;
    .edit_section {
      display: flex;
      justify-content: space-between;
      .edit_btn {
        .edit {
          color: #347cf5;
          font-size: 14px;
        }
      }
    }
    .info_box {
      padding-left: 18px;
    }
  }
}
</style>