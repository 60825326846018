<!--
 * @Description: 任务结果管理
 * @Author: HuangCheng
 * @Date: 2020-03-12 10:46:47
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-07 16:52:46
 -->
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :lg="6" :md="8" :sm="12">
            <a-form-item label="企业名称">
              <a-input defaultValue="" placeholder="请填入企业名称" v-model="searchForm.enterpriseName">
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="8" :sm="12">
            <a-form-item label="姓名">
              <a-input defaultValue="" placeholder="请填入姓名" v-model="searchForm.userName">
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="8" :sm="12">
            <a-form-item label="状态">
              <a-select style="width: 100%" defaultValue="" placeholder="请选择状态" v-model="searchForm.auditStatus">
                <a-select-option value="10">企业待审核</a-select-option>
                <a-select-option value="11">平台待审核</a-select-option>
                <a-select-option value="20">已通过</a-select-option>
                <a-select-option value="30">已驳回</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="12">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="handleToSearchList">查询</a-button>
              <a-button style="margin-left: 8px" icon="reload" @click="resetSearchForm">重置</a-button>
              <a-button type="primary" :loading="exportLoading" style="margin-left: 8px" icon="export" v-has="'taskManage:export'" @click="exportDetail('任务结果')">导出</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="tableColumnsJg"
      :data="loadAccountListJg"
      bordered
    >
      <div slot="items" slot-scope="text, record">
        <a @click="showPic(record)">查看</a>
      </div>
      <span slot="action" slot-scope="text, record">
        <a @click="handleDetail(record)" v-has="'taskResultManage:detail'">任务详情</a>
        <a-divider type="vertical" v-has="'taskManage:edit'"/>
        <a @click="handleDealEdit(record)" v-has="'taskManage:edit'">编辑</a>
        <a-divider type="vertical" v-if="record.auditStatus==='11'" v-has="'taskManage:shenhe'"/>
        <a @click="handleDealRow(record)" v-if="record.auditStatus==='11'" v-has="'taskManage:shenhe'">审核</a>
      </span>
    </s-table>
    <!-- 图片查看 -->
    <a-modal
      title="任务结果查看"
      :visible="visiblePicture"
      :footer="null"
      @cancel="visiblePicture=false"
    >
      <ul class="pic-select">
        <li v-for="(item,index) in picList" :key="index"><a :href="item.fileUrl" target="_blank">{{item.fileName===null?`自由职业者任务成果${index+1}`:item.fileName}}</a></li>
        <li>任务结果描述：{{currentContent}}</li>
      </ul>
      <!-- <a-carousel @goTo="goTo" arrows>
        <div
          slot="prevArrow"
          slot-scope="props"
          class="custom-slick-arrow"
          style="left: 10px;zIndex: 1"
          :dots='position'
        >
          <a-icon type="left-circle" />
        </div>
        <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
          <a-icon type="right-circle" />
        </div>
        <div v-for="(item,index) in picList" :key="index">
          <img v-if="item.fileUrl.indexOf('png')!==-1||item.fileUrl.indexOf('jpg')!==-1||item.fileUrl.indexOf('jpeg')!==-1||item.fileUrl.indexOf('JPEG')!==-1||item.fileUrl.indexOf('bmp')!==-1||item.fileUrl.indexOf('tif')!==-1||item.fileUrl.indexOf('gif')!==-1||item.fileUrl.indexOf('pcx')!==-1||item.fileUrl.indexOf('tga')!==-1||item.fileUrl.indexOf('exif')!==-1||item.fileUrl.indexOf('fpx')!==-1||item.fileUrl.indexOf('svg')!==-1||item.fileUrl.indexOf('psd')!==-1||item.fileUrl.indexOf('cdr')!==-1||item.fileUrl.indexOf('pcd')!==-1||item.fileUrl.indexOf('dxf')!==-1||item.fileUrl.indexOf('ufo')!==-1||item.fileUrl.indexOf('eps')!==-1||item.fileUrl.indexOf('ai')!==-1||item.fileUrl.indexOf('raw')!==-1||item.fileUrl.indexOf('WMF')!==-1||item.fileUrl.indexOf('webp')!==-1||item.fileUrl.indexOf('avif')!==-1" :src="item.fileUrl" alt="" style="width:500px;height:500px;">
        </div>
      </a-carousel> -->
    </a-modal>
    <!-- 审核 -->
    <a-modal
      title="审核"
      :visible="visibleDetail"
      @cancel="handleExamineModalCancel"
      @ok="handleRechargeModalOk"
      width="500px"
    >
      <a-form :form="examinForm">
        <a-row class="form-row">
          <a-col :lg="24" :md="24" :sm="24" style="display:flex">
            <a-form-item label="" style="width:100%">
              <a-radio-group width="300px" @change="radioChange" v-decorator="['auditStatus',{rules: [{ required: true, message: '请选择审核结果'}]}]">
                <a-radio value="10">
                  通过
                </a-radio>
                <a-radio value="30">
                  拒绝
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24" v-if="isError">
            <a-form-item label="" :labelCol="{lg: {span: 4}, sm: {span: 5}}" :wrapperCol="{lg: {span: 20}, sm: {span: 19} }">
              <a-textarea placeholder="请输入不通过原因" style="width: 100%"
                v-decorator="[
                  'errorInfo',{rules: [{ required: true, message: '请输入不通过原因', whitespace: true}]}
                ]">
              </a-textarea>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <!-- 编辑 -->
    <a-modal
      title="编辑"
      :visible="visibleDetailBj"
      @cancel="handleExamineModalCancelBj"
      @ok="handleRechargeModalOkBj"
      width="500px"
    >
      <div class="clearfix">
        <a-upload
          :beforeUpload="beforeUpload"
          :action="url.frontUrl"
          :multiple="true"
          :headers="headers"
          :file-list="fileList"
          :data="uploadData"
          @change="handleChange"
        >
          <a-button> <a-icon type="upload" /> 上传 </a-button>
        </a-upload>
        <!-- <a-upload
          :action="url.frontUrl"
          list-type="picture-card"
          :file-list="fileList"
          :data="uploadData"
          :headers="headers"
          :confirmLoading="confirmLoadings"
          @preview="handlePreview"
          @change="handleChange"
        >
          <div v-if="fileList.length < 9">
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传
            </div>
          </div>
        </a-upload>
        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancels">
          <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal> -->
      </div>
    </a-modal>
    <!-- 详情 -->
    <a-modal
      title="任务详情"
      :visible="visibleTaskDetail"
      @cancel="visibleTaskDetail=false"
      width="100%"
      class="detail-modal"
      :footer="null"
    >
      <Detail @refresh="handleToSearchList" v-if="visibleTaskDetail" :id="currentId" />
    </a-modal>
  </a-card>
</template>
<script>
  import STable from '@/components/table/';
  import AInput from "ant-design-vue/es/input/Input";
  import Detail from './components/taskResultDetail.vue';
  import { ACCESS_TOKEN } from "@/store/mutation-types";
  import Vue from 'vue'
  import {
    // getTaskResult,
    // taskAuthor,
    // updateResult,
    // exportTaskResult
  } from '@/api/api';
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  export default {
    name: "BusinessInvoice",
    components: {
      AInput,
      STable,
      Detail
    },
    data () {
      const cellStyle = {
        maxWidth: '120px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow:'ellipsis',
        cursor:'pointer'
      };
      return {
        searchForm: {
          auditStatus: undefined,
          enterpriseName: '',
          userName: '',
        },
        date: [],
        tableColumnsJg: [
          {
            title: '企业名称',
            dataIndex: 'enterpriseName',
            width: '180px',
            align: 'center'
          },
          {
            title: '提交时间',
            dataIndex: 'createTime',
            width: '120px',
            align: 'center'
          },
          {
            title: '姓名',
            dataIndex: 'userName',
            customRender: text => text===null?'--':text,
            width: '80px',
            align: 'center'
          },
          {
            title: '联系电话',
            dataIndex: 'phoneNo',
            customRender: text => text===null?'--':text,
            width: '100px',
            align: 'center'
          },
          {
            title: '任务结果',
            dataIndex: 'items',
            scopedSlots: { customRender: 'items' },
            width: '100px',
            align: 'center'
          },
          {
            title: '任务结果描述',
            dataIndex: 'content',
            customRender: text => text===null?'--':text,
            width: '150px',
            align: 'center',
            customCell: () => {
                return {
                  style: cellStyle
                }
              },
              customRender: (text) => <a-tooltip placement="left" title={text}>{text}</a-tooltip>
          },
          {
            title: '审核状态',
            dataIndex: 'auditStatus',
            customRender: text => text === '10'?"企业待审核":text === '20'?"已通过":text === '11'?"平台待审核":"已驳回",
            width: '100px',
            align: 'center'
          },
          {
            title: '驳回原因',
            dataIndex: 'errorInfo',
            customRender: text => text===null?'--':text,
            width: '100px',
            align: 'center'
          },
          {
            title: '处理时间',
            dataIndex: 'auditTime',
            width: '120px',
            align: 'center'
          },
          {
            title: '操作',
            align: 'center',
            dataIndex: 'action',
            width: '150px',
            scopedSlots: { customRender: 'action' },
          },
        ],
        exportLoading: false,
        examinForm: this.$form.createForm(this),
        form: this.$form.createForm(this, { name: 'fapiao' }),
        record: '',
        visibleDetail: false,
        confirmLoading: false,
        currentId: '',
        visiblePicture: false,
        picList: [],
        isError: false,
        visibleTaskDetail:false,
        visibleDetailBj: false,
        url: {
          frontUrl: window._CONFIG['domianURL'] + '/api/tax/common/file/upload',
        },
        uploadData: {
          fileType: '103206'
        },
        previewVisible: false,
        previewImage: '',
        fileList: [],
        confirmLoadings: false,
        currentContent: ''
      }
    },
    watch:{
      fileList: {
        handler (newV) {
          // newV.forEach((item,index) => {
          //   if (item.fileName===undefined) {
          //     item.status='error'
          //   }
          // })
          this.fileList = newV
        },
        deep: true
      }
    },
    methods: {
      // 导出任务结果
      exportDetail (fileName){
        this.exportLoading = true
        if(!fileName || typeof fileName != "string"){
          fileName = "导出文件"
        }
        let param = {...this.searchForm};
        exportTaskResult(param).then((data)=>{
          if (!data) {
            this.exportLoading = false
            this.$message.warning("文件下载失败")
            return
          } else {
            this.exportLoading = false
            this.$message.success('导出成功')
          }
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(new Blob([data]), fileName+'.xls')
          }else{
            let url = window.URL.createObjectURL(new Blob([data]))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName+'.xls')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link) //下载完成移除元素
            window.URL.revokeObjectURL(url) //释放掉blob对象
          }
        })
      },
      // 编辑
      handleDealEdit (record) {
        this.record = record
        this.visibleDetailBj = true
        this.fileList = []
        record.items.forEach((item,index)=>{
          item.name = item.fileName===null?`自由职业者任务成果${index+1}`:item.fileName
          item.url = item.fileUrl
          item.uid = item.id
          item.status = 'done'
          this.fileList.push(item)
        })
      },
      handleCancels() {
        this.previewVisible = false;
      },
      async handlePreview(file) {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
          console.log(file)
        }
        this.previewImage = file.url || file.preview;
        this.previewVisible = true;
      },
      beforeUpload(file) {
        const isJpgOrPng = true;
        if (!isJpgOrPng) {
          this.$message.error('上传照片格式不正确!');
          return false
        }
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
          this.$message.error('上传文件大小不能超过10MB!');
          return false
        }
        return isJpgOrPng && isLt2M;
      },
      handleChange({ fileList }) {
        this.fileList = fileList;
        this.fileList.forEach(item=>{
          if (item.response) {
            item.url = 'https://oss.zfycloud.com' + "/" + item.response.result
            item.fileName = item.name
            item.fileUrl = item.url
          }
        })
      },
      handleRechargeModalOkBj () {
        this.confirmLoadings = true
        let isUpload = true
        this.fileList.forEach(item=>{
          if (item.fileName===undefined) {
            item.status='error'
            isUpload = false
          }
        })
        if (!isUpload) {
          this.$message.error('上传文件中有错误文件，请删除后提交')
          return
        }
        updateResult({id:this.record.id,resultItems:this.fileList}).then(res => {
          this.confirmLoadings = false
          if (res.success) {
            this.$message.success(res.message)
            this.handleToSearchList()
            this.handleExamineModalCancelBj()
          } else {
            this.$message.error(res.message)
          }
        })
      },
      handleExamineModalCancelBj () {
        this.confirmLoadings = false
        this.visibleDetailBj = false
      },
      // 单选框
      radioChange (e) {
        console.log(e.target.value)
        this.isError = e.target.value==='30'?true:false
      },
      // 详情
      handleDetail (record) {
        this.currentId = record
        this.visibleTaskDetail = true
      },
      // 查看图片
      showPic(record) {
        this.picList = record.items
        console.log(this.picList)
        this.currentContent = record.content
        this.visiblePicture=true
      },
      // 审核
      handleDealRow (record) {
        this.record = record
        this.visibleDetail = true
      },
      handleRechargeModalOk() {
        this.examinForm.validateFields((err, fieldsValue) => {
          if (err) {
            return;
          }
          const description = this.examinForm.getFieldsValue();
          taskAuthor(Object.assign(description,{id:this.record.id})).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.handleExamineModalCancel();
              this.handleToSearchList();
            } else {
              this.$message.error(res.message);
            }
          })
        })
      },
      handleExamineModalCancel() {
        this.examinForm.resetFields();
        this.visibleDetail = false;
      },
      // 选择时间段
      handleDateChange(date, dateString) {
        this.searchForm.createStartTime = `${dateString[0]} 00:00:00`;
        this.searchForm.createFinishTime = `${dateString[1]} 23:59:59`;
      },
      // 加载列表数据
      loadAccountListJg(parameter) {
        return getTaskResult(Object.assign(parameter, this.searchForm))
          .then(res => {
            // console.log('-->',res)
            return res.result
          })
      },
      // 查看详情
      checkDetail(record) {
        this.currentId = record
        this.visibleDetail = true
      },
      handleCancel () {
        this.visibleDetail = false
      },
      // 查询企业发票列表
      handleToSearchList() {
        this.$refs.table.refresh(true);
      },
      // 重置搜索条件
      resetSearchForm() {
        this.searchForm = {
          auditStatus: undefined,
          enterpriseName: '',
          userName: '',
        };
        this.date = [];
        this.handleToSearchList();
      },
    },
  }
</script>
<style lang="less" scoped>
.pic-select{
  padding: 0;
  margin: 0;
  list-style: none;
  li{
    margin: 10px 0;
  }
}
</style>
<style lang="less">
.detail-modal {
  top: 0;
  height: 100vh;
  .ant-modal-content {
    height: 100vh;
    .ant-modal-body {
      height: calc(100% - 55px);
      padding: 0;
    }
  }
}
</style>
<style scoped>
/* For demo */
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 500px;
  line-height: 160px;
  /* background: #364d79; */
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 35px;
  height: 35px;
  font-size: 35px;
  color: #fff;
  background-color: rgba(31, 45, 61, 1);
  border-radius: 50%;
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.ant-carousel >>> .slick-dots{
  display: none !important;
}
</style>
<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>