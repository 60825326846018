<template>
  <div style="padding:24px;">
    <!-- <div class="title">付款管理</div> -->
    <commonTitle title="审核付款"/>
    <!-- 搜索 -->
    <div class="search">
      <span>任务名称：</span>
      <a-input style="width:260px" v-model="queryParams.title" placeholder="请输入任务名称"/>
      <div class="table-search-submitButtons">
        <a-button type="primary" @click="handleToSearchEnterprise">查询</a-button>
        <a-button style="margin-left: 8px;" icon="reload" @click="resetSearchEnterprise">重置</a-button>
      </div>
    </div>
    <div class="content">
      <s-table
        style="border-top:1px solid #F0F0F0;margin-top:16px"
        ref="raskTable"
        :columns="raskColumns"
        :data="raskData"
        @change="onRaskChange"
        :rowKey="(record) => record.id"
      >
        <span slot="payStatus" slot-scope="text">
          <div class="table-point" v-if="text==='10'"><span></span>提交成功</div>
          <div class="table-point" v-if="text==='20'"><span></span>复核成功</div>
          <div class="table-point" v-if="text==='30'"><span></span>付款成功</div>
          <div class="table-point" v-if="text==='40'"><span style="background:#52C41A"></span>待提交</div>
          <div class="table-point" v-if="text==='50'"><span style="background:#52C41A"></span>未达到支付条件</div>
          <div class="table-point" v-if="text==='60'"><span style="background:#52C41A"></span>取消支付</div>
        </span>
        <span slot="action" slot-scope="text, record">
          <!-- <a @click="cancelRask(record)">审核</a>
          <a-divider type="vertical" />
          <a @click="fuheRask(record)">复核</a>
          <a-divider type="vertical" /> -->
          <a @click="raskDetail(record)" v-if="record.payAmount!==0">详情</a>
        </span>
      </s-table>
    </div>
  </div>
</template>
<script>
import STable from '@/components/table/'
import {
  getOrderList,
  taskWorkerPayList,
  getChannelPayList,
} from '@/api/api'
import moment from 'moment'
import commonTitle from "../components/commonTitle";
export default {
  components: {
    STable,commonTitle
  },
  data () {
    return {
      raskColumns: [
        {
          title: '创建时间',
          dataIndex: 'createTime',
          defaultSortOrder: 'descend',
          sorter: (a, b) => moment(a.createTime).unix() - moment(b.createTime).unix(),
          width: '200px'
        },
        {
          title:'企业名称',
          dataIndex:'enterpriseName'
        },
        {
          title: '任务名称',
          dataIndex: 'title',
        },
        {
          title: '付款类型',
          dataIndex: 'payType',
          customRender: text => text==='10' ? '预付款' : text==='20' ? '尾款' : '其他'
        },
        {
          title: '付款人数',
          dataIndex: 'payNum',
        },
        {
          title: '付款金额',
          dataIndex: 'payAmount',
          customRender: text => text === null?'--':text.toFixed(2)
        },
        {
          title: '费用金额',
          dataIndex: 'payFeeAmount',
          customRender: text => text === null?'--':text.toFixed(2)
        },
        {
          title: '总金额',
          dataIndex: 'payTotalAmount',
          customRender: text => text === null?'--':text.toFixed(2)
        },
        {
          title: '付款状态',
          dataIndex: 'payStatus',
          scopedSlots: { customRender: 'payStatus' },
        },
        // {
        //   title: '当前状态',
        //   dataIndex: 'payStatus',
        // },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        }
      ],
      pagenation: {
        showSizeChanger: true,
        pageNo: 1,
        pageSize: 10
      },
      queryParams: {
        title: ''
      }
    }
  },
  methods: {
    // 获取订单列表
    raskData (parameter) {
      return getChannelPayList(Object.assign(parameter,this.queryParams,{listType: 1})).then(res => {
        return res.result
      })
    },
    // 查询
    handleToSearchEnterprise () {
      this.$refs.raskTable.refresh(true)
    },
    // 重置
    resetSearchEnterprise () {
      this.queryParams = {
        title: ''
      }
      this.handleToSearchEnterprise()
    },
    // 表格改变
    onRaskChange (pagination, filters, sorter) {
      // console.log('params', pagination, filters, sorter)
    },
    // 审核
    cancelRask (record) {},
    // 复核
    fuheRask (record) {},
    // 详情
    raskDetail (record) {
      window.localStorage.setItem('submenu','settlementDetail')
      window.sessionStorage.setItem('taskInfo',JSON.stringify(record))
      this.$router.push('/settlementDetail')
    },
  }
}
</script>
<style scoped lang="less">
.title{
  font-size: 24px;
  color: #202020;
  margin-bottom: 26px;
}
.search{
  margin-top: 16px;
  display: flex;
  align-items: center;
}
.table-search-submitButtons{
  display: flex;
  align-items: center;
  height: 40px;
  margin-left: 30px;
}
.table-point{
  display: flex;
  align-items: center;
  // justify-content: center;
  span{
    width: 4px;
    height: 4px;
    background: #1890FF;
    border-radius: 50%;
    margin-right: 4px;
  }
}
</style>