<template>
  <section class="business-detail">
    <a-menu style="width: 126px;height: 100%" mode="vertical" :defaultSelectedKeys="[type]" @click="handleMenuClick">
      <a-menu-item key="1" v-has="'invoiceNewCenter:qyfpckjbxx'">基本信息</a-menu-item>
      <a-menu-item key="5" v-has="'invoiceNewCenter:qyfpckfpxx'">开票信息</a-menu-item>
      <a-menu-item key="2" v-has="'invoiceNewCenter:qyfpckwlxx'">物流信息</a-menu-item>
      <a-menu-item key="4" v-has="'invoiceNewCenter:qyfpckscfp'">上传发票</a-menu-item>
      <a-menu-item key="3" v-has="'invoiceNewCenter:qyfpckfpmx'">发票明细</a-menu-item>
    </a-menu>
    <section class="detail-content">
      <!-- 基本信息 -->
      <div v-show="type === '1'">
        <a-form class="detail-form">
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="申请单号">
            <span>{{detailInfo.id}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="企业名称">
            <span>{{detailInfo.invoiceName}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="申请金额">
            <span>{{detailInfo.applyAmount}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="实际可开票金额">
            <span>{{detailInfo.actualInvoiceAmount}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="欠票金额">
            <span>{{detailInfo.oweTicketAmount}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="发票数量">
            <span>{{detailInfo.invoiceNum}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="物流公司">
            <span>{{detailInfo.logisticsCompany==='100101'?'京东':detailInfo.logisticsCompany==='100102'?'顺丰':detailInfo.logisticsCompany==='100103'?'德邦':''}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="物流单号">
            <span>{{detailInfo.logisticsCode}}</span>
          </a-form-item>
          <!-- <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="联系电话">
            <span>{{detailInfo.contactsPhone}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="寄送地址">
            <span>{{detailInfo.contactsAddress}}</span>
          </a-form-item> -->
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="状态">
            <div v-if="!isUpdate">
              <span>{{detailInfo.status | getArrItem(invoiceStatus)}}</span>
              <a-button style="margin-left:10px" type="primary" @click="updateStatus">修改</a-button>
            </div>
            <div v-else style="display:flex">
              <a-select placeholder="请选择状态" style="width:300px" v-model="ticketStatus">
                <a-select-option v-for="item in invoiceStatus" :value="item.value" :key="item.value">{{item.label}}</a-select-option>
              </a-select>
              <a-button style="margin-left:10px" type="primary" @click="sureUpdateStatus">确认</a-button>
              <a-button style="margin-left:10px" @click="isUpdate=false">取消</a-button>
            </div>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="申请时间">
            <span>{{detailInfo.createTime}}</span>
          </a-form-item>
        </a-form>
      </div>
      <!-- 物流信息 -->
      <div v-show="type === '2'">
        <a-form :form="logicticsForm" :label-col="{ span: 2 }" :wrapper-col="{ span: 12 }" @submit="handleSubmitLogictics">
          <a-form-item label="物流公司">
            <a-select
              v-decorator="[
                'logisticsCompany',
                { rules: [{ required: true, message: '请选择物流公司!' }] },
              ]"
              placeholder="请选择物流公司"
            >
              <a-select-option value="100101">
                京东
              </a-select-option>
              <a-select-option value="100102">
                顺丰
              </a-select-option>
              <a-select-option value="100103">
                德邦
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="物流单号">
            <a-input
              placeholder="请输入物流单号"
              v-decorator="['logisticsCode', { rules: [{ required: true, message: '请输入物流单号!' }] }]"
            />
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 2, offset: 2 }">
            <a-button type="primary" html-type="submit">
              提交
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <!-- 上传发票 -->
      <div v-show="type==='4'">
        <a-form :form="uploadTicketForm" :label-col="{ span: 2 }" :wrapper-col="{ span: 12 }" @submit="handleSubmitUploadTicket">
          <a-form-item label="发票张数">
            <a-input
              placeholder="请输入发票张数"
              @change="changeInput"
              type="number"
              v-decorator="['invoiceNum', { rules: [{ required: true, message: '请输入发票张数!' }] }]"
            />
          </a-form-item>
          <a-form-item label="上传发票">
            <a-upload
              list-type="picture-card"
              :file-list="fileList"
              :action="url.frontUrl"
              :data="uploadData"
              :headers="headers"
              @preview="handlePreview"
              @change="handleChange"
              v-decorator="['enterpriseLicense', { rules: [{ required: true, message: '请上传发票图片!' }] }]"
            >
              <div v-if="fileList.length<uploadTicketNum">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  Upload
                </div>
              </div>
            </a-upload>
            <!-- <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancelUpload">
              <img alt="example" style="width:100%;padding-top:20px" :src="previewImage" />
            </a-modal> -->
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 2, offset: 2 }" v-if="fileList.length==uploadTicketNum&&uploadTicketNum>0">
            <a-button type="primary" html-type="submit">
              提交
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <!-- 发票明细 -->
      <div v-show="type === '3'">
        <a-card :bordered="false">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="24">
                <!-- <a-col :lg="6" :md="8" :sm="12">
                  <a-form-item label="申请单号ID">
                      <a-input type="text" placeholder="请输入申请单号ID" v-model="searchForm.applyId"></a-input>
                  </a-form-item>
                </a-col> -->
                <a-col :lg="6" :md="8" :sm="12">
                  <span class="table-page-search-submitButtons">
                    <!-- <a-button type="primary" icon="search" @click="getInvoiceApplyDetailedList">查询</a-button> -->
                    <!-- <a-button type="primary" style="margin-left: 8px" icon="export" @click="exportDetail('发票明细')">导出</a-button> -->
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <s-table
            ref="table"
            :pagination="false"
            size="default"
            :rowKey="(record, index) => index" 
            :columns="scopeColumns"
            :data="applyDetailedList"
            bordered
          >
            <div slot="all" slot-scope="text, record">
              <div>
                <p>公司名称: {{record.invoiceName}}</p>
                <p>纳税人号: {{record.creditCode}}</p>
                <p>注册地址: {{record.registerAddress}}</p>
                <p>联系电话: {{record.contactPhone}}</p>
                <p>开户银行: {{record.bankName}}</p>
                <p>银行帐号: {{record.bankNo}}</p>
              </div>
            </div>
            <span slot="payeeName" slot-scope="text, record">
              <a @click="jumpToPage(record)">{{text}}</a>
            </span>
          </s-table>
        </a-card>
      </div>
      <!-- 开票信息 -->
      <div v-show="type === '5'">
        <h3>企业信息：</h3>
        <a-form class="detail-form">
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="企业名称">
            <span>{{detailInfo.invoiceName}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="纳税人号">
            <span>{{detailInfo.creditCode}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="注册地址">
            <span>{{detailInfo.registerAddress}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="联系电话">
            <span>{{detailInfo.contactPhone}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="开户银行">
            <span>{{detailInfo.bankName}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="银行账号">
            <span>{{detailInfo.bankNo}}</span>
          </a-form-item>
        </a-form>
        <h3>发票信息：</h3>
        <a-form class="detail-form">
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="服务内容">
            <span>{{detailInfo.scopeText}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="发票类型">
            <span>{{detailInfo.invoiceType==='10'?'普票':'专票'}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="实际开票金额">
            <span>{{detailInfo.actualInvoiceAmount}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="备注">
            <span>{{detailInfo.remark}}</span>
          </a-form-item>
        </a-form>
        <h3>收件人信息：</h3>
        <a-form class="detail-form" v-if="detailInfo.invoiceType==='10'">
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="邮箱地址">
            <span>{{detailInfo.sendEmail}}</span>
          </a-form-item>
        </a-form>
        <a-form class="detail-form" v-else>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="收件人">
            <span>{{detailInfo.contactsName}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="联系电话">
            <span>{{detailInfo.contactsPhone}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="联系地址">
            <span>{{detailInfo.contactsAddress}}</span>
          </a-form-item>
        </a-form>
      </div>
    </section>
  </section>
</template>
<script>
  import STable from '@/components/table/'
  import AInput from "ant-design-vue/es/input/Input"
  import {getArrItem} from '@/utils/util.js'
  import Vue from 'vue'
  import { ACCESS_TOKEN } from "@/store/mutation-types"
  import {
    getInvoiceApplyMain,
    getInvoiceApplyDetailedList,
    getApplyInvoiceTradeDetails,
    getApplyInvoiceDetails,
    updateApplyInvoiceStatus,
    updateApplyInvoiceLogistics,
    getInvoicePicture,
    uploadInvoicePicture
  } from '@/api/api';
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  export default {
    name: "BusinessDetail",
    components: {
      AInput,
      STable
    },
    data () {
      const cellStyle = {
        maxWidth: '120px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow:'ellipsis',
        cursor:'pointer'
      };
      return {
        ticketStatus: undefined,
        isUpdate: false,
        invoiceLimit: [
          {
            value: '102701',
            label: '万元'
          }, {
            value: '102702',
            label: '十万元'
          }, {
            value: '102703',
            label: '百万元'
          }],
          invoiceStatus: [{
            value: '102801',
            label: '已申请'
          }, {
            value: '102811',
            label: '处理中'
          }, {
            value: '102821',
            label: '处理完成'
          }, {
            value: '102831',
            label: '已撤销'
          }
        ],
        detailInfo: {
          id: '',
          invoiceName: '',
          applyAmount: '',
          invoiceNum: '',
          contactsName: '',
          contactsPhone: '',
          contactsAddress: '',
          status: '',
          createTime: ''
        },
        type: '1',
        record: {},
        form: this.$form.createForm(this, { name: 'fapiao' }),
        // 发票明细
        invoiceType: [
          {
            value: '102511',
            label: '增值税普通发票'
          }, {
            value: '102501',
            label: '增值税专用发票'
          }
        ],
        dealStatus: [
          {
            value: '102901',
            label: '未处理'
          }, {
            value: '102911',
            label: '处理中'
          }, {
            value: '102921',
            label: '处理完成'
          }
        ],
        searchForm: {
        },
        applyDetailedList: (parameter) => {
          return getApplyInvoiceDetails(Object.assign(parameter, this.queryParam,{applyId:this.id}))
            .then(res => {
              return res.result
            })
        },
        scopeColumns: [
          {
            title: '主单批次号',
            align: 'center',
            dataIndex: 'batchId',
            scopedSlots: { customRender: 'payeeName' },
          },
          {
            title: '申请开票金额(元)',
            align: 'center',
            dataIndex: 'applyAmount',
            customRender: (text) => text.toFixed(2)
          },
          {
            title: '付款金额(元)',
            dataIndex: 'payAmount',
            align: 'center',
            customRender: (text) => text.toFixed(2)
          },
          {
            title: '支付笔数',
            align: 'center',
            dataIndex: 'payNum',
            customRender: (text) => text
          },
          {
            title: '支付时间',
            align: 'center',
            dataIndex: 'orderPayTime',
          }
        ],
        headers: {'X-Access-Token': Vue.ls.get(ACCESS_TOKEN)},
        url: {
          frontUrl: window._CONFIG['domianURL'] + '/api/tax/common/file/upload',
        },
        formLayout: 'horizontal',
        logicticsForm: this.$form.createForm(this),
        uploadTicketForm: this.$form.createForm(this),
        previewVisible: false,
        previewImage: '',
        fileList: [],
        uploadTicketNum: 1,
        uploadData: {
          fileType: '103203'
        },
      }
    },
    props: {
      id: {
        type: String,
        default: '',
        required: false
      },
    },
    created() {
      this.getApplyInvoiceTradeDetails();
      this.getInvoicePicture();
      // if (this.$store.state.user.userRules.includes('invoiceNewCenter:qyfpckjbxx')) {
      //   this.type = '1'
      // } else if (this.$store.state.user.userRules.includes('invoiceNewCenter:qyfpckwlxx')) {
      //   this.type = '2'
      // } else if (this.$store.state.user.userRules.includes('invoiceNewCenter:qyfpckfpmx')) {
      //   this.type = '3'
      // } else if (this.$store.state.user.userRules.includes('invoiceNewCenter:qyfpckscfp')) {
      //   this.type = '4'
      // } else if (this.$store.state.user.userRules.includes('invoiceNewCenter:qyfpckfpxx')) {
      //   this.type = '5'
      // } else {
      //   this.type = '0'
      // }
    },
    methods: {
      // 发票明细跳转
      jumpToPage (record) {
        this.$router.push({path: '/tradingCenter/transactionTicket', query: {batchId: record.batchId}})
      },
      // 修改状态
      updateStatus () {
        this.isUpdate = true
      },
      // 确认修改状态
      sureUpdateStatus () {
        if(this.ticketStatus===undefined) {
          this.$message.error('请选择状态')
        } else {
          let params = {
            id: this.id,
            status: this.ticketStatus
          }
          updateApplyInvoiceStatus(params).then(res => {
            if(res.success){
              this.$message.success(res.message)
              this.getApplyInvoiceTradeDetails()
              this.isUpdate = false
              this.$emit('refresh')
            } else {
              this.$message.error(res.message)
            }
          })
        }
      },
      /**
       * @description: 基本详情左侧菜单点击事件
       * @param : e
       * @return: void
       * @Author: HuangCheng
       */
      handleMenuClick(e) {
        this.type = e.key;
      },
      /**
       * @description: 获取企业详情
       * @Author: HuangCheng
       */
      getApplyInvoiceTradeDetails() {
        getApplyInvoiceTradeDetails(this.id).then(res=>{
          if (res.success) {
            this.detailInfo = res.result;
            // console.log('企业详情', this.detailInfo)
            // this.detailInfo.applyAmount = (this.detailInfo.applyAmount).toFixed(2)
            this.logicticsForm.setFieldsValue({
              logisticsCode: this.detailInfo.logisticsCode,
              logisticsCompany: this.detailInfo.logisticsCompany === '' ? undefined : this.detailInfo.logisticsCompany
            })
          } else {
            this.$message.error(res.message);
          }
        })
      },
      /**
       * @description: 重置发票明细搜索表单
       * @return: void
       * @Author: HuangCheng
       */
      resetSearchForm() {
        this.searchForm = {
        }
        this.getInvoiceApplyDetailedList();
      },
      // 物流信息
      handleSubmitLogictics(e) {
        e.preventDefault();
        this.logicticsForm.validateFields((err, values) => {
          if (!err) {
            updateApplyInvoiceLogistics(Object.assign(values,{id:this.id})).then(res => {
              if(res.success){
                this.$message.success(res.message)
                this.getApplyInvoiceTradeDetails()
                this.$emit('refresh')
              } else {
                this.$message.error(res.message)
              }
            })
          }
        });
      },
      // 上传发票信息
      // 输入发票张数
      changeInput (e) {
        this.uploadTicketNum = e.target.value
      },
      // 获取发票信息
      getInvoicePicture () {
        getInvoicePicture(this.id).then(res => {
          if(res.success){
            if(res.result.length>0){
              this.fileList = res.result
              this.fileList.forEach((item,index)=>{
                let s = index + 1
                item.url = item.invoicePictureUrl
                item.name = 'image.png'
                item.status = 'done'
                item.uid = '-'+ s
              })
              this.uploadTicketNum = this.fileList.length
              this.uploadTicketForm.setFieldsValue({
                invoiceNum: res.result.length,
                enterpriseLicense: this.fileList
              })
            } else {
              this.uploadTicketForm.setFieldsValue({
                invoiceNum: '1',
              })
            }
          }
        })
      },
      // 上传发票
      handleCancelUpload() {
        this.previewVisible = false;
      },
      async handlePreview(file) {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        if(file.response){
          this.previewImage = file.thumbUrl;
          window.open(file.url)
        } else {
          this.previewImage = file.url;
          window.open(file.url)
        }
        this.previewVisible = true;
      },
      handleChange({ fileList }) {
        this.fileList = fileList;
        this.fileList.forEach(item=>{
          if(item.response){
            item.url = 'https://oss.zfycloud.com' + "/" + item.response.result
          }
        })
      },
      handleSubmitUploadTicket(e) {
        e.preventDefault();
        this.uploadTicketForm.validateFields((err, values) => {
          if (!err) {
            let urlList = values.enterpriseLicense.fileList || values.enterpriseLicense
            let resUrl = []
            urlList.forEach(item => {
              resUrl.push(item.url)
            })
            let params = {
              invoiceNum: values.invoiceNum,
              pictureUrls: resUrl,
              id: this.id
            }
            uploadInvoicePicture(params).then(res=> {
              if(res.success){
                this.$message.success(res.message)
                this.getApplyInvoiceTradeDetails()
                this.getInvoicePicture()
                this.$emit('refresh')
              } else {
                this.$message.error(res.message)
              }
            })
          }
        });
      },
    }
  }
</script>
<style lang="less">
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
  .business-detail {
    height: 100%;
    display: flex;
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: transparent;
      border-right: 2px solid #1890ff;
    }
    .detail-content {
      flex: 1;
      padding: 20px;
      overflow: auto;
      .detail-form {
        .ant-form-item {
          margin-bottom: 0;
        }
      }
      .config-form {
        .ant-form-item-control {
          height: 40px;
        }
      }
      .taxList {
        .ant-empty-normal {
          margin: 0;
        }
      }
    }
  }
</style>
<style lang="less" scoped>
.picture-common{
  width: 200px;
}
</style>
