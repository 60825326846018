<!--
 * @Description: 付款信息
 * @Author: HuangCheng
 * @Date: 2020-02-21 15:25:15
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-08-19 08:55:54
 -->

<template>
  <a-card :bordered="false">
    <!-- <div class="table-page-search-wrapper">
      <a-form layout="inline" :form="addRechargeForm">
        <a-row :gutter="48">
          <a-col :lg="6" :md="8" :sm="12">
            <a-form-item label="银行名称">
              <a-input  v-model="queryParam.id" placeholder="请输入银行名称"/>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="8" :sm="12">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="handleToSearchRecharge">查询</a-button>
              <a-button style="margin-left: 18px" icon="reload" @click="resetSearchForm">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form> 
    </div> -->
    <a-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data-source="loadDataList"
      bordered
      :scroll="{ x: 2400 }"
    >
      <span slot="action" slot-scope="text, record">
        <a-button @click="handleDispose(record)" v-if="record.status==='101821'||record.status==='101802'" type="link">处理</a-button>
        <a-divider v-if="record.status==='101821'||record.status==='101802'" type="vertical" />
        <a-button v-if="record.status==='101821'||record.status==='101802'" @click="handleExamine(record)" type="link">修改</a-button>
        <a-divider v-if="record.status==='101821'||record.status==='101802'" type="vertical" />
        <a-button @click="selectIt(record)" type="link">查看</a-button>
      </span>
    </a-table>
    <!-- 修改 -->
    <a-modal title="修改" width="600px" destroyOnClose :visible="visibleExamineModal" @ok="handleRechargeModalOk" @cancel="handleExamineModalCancel">
      <a-form :form="examinForm">
        <a-row class="form-row">
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="银行名称" :labelCol="{lg: {span: 4}, sm: {span: 5}}" :wrapperCol="{lg: {span: 20}, sm: {span: 19} }">
              <a-input placeholder="请输入银行名称"
                v-decorator="['qsBankName', {rules: [{ required: true, message: '请输入银行名称', whitespace: true}]}]" />
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="银行卡号" :labelCol="{lg: {span: 4}, sm: {span: 5}}" :wrapperCol="{lg: {span: 20}, sm: {span: 19} }">
              <a-input placeholder="请输入银行卡号"
                v-decorator="['qsBankCode', {rules: [{ required: true, message: '请输入银行卡号', whitespace: true}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <!-- 查看 -->
    <a-modal title="详情" width="1400px" :footer="null" destroyOnClose :visible="addRechargeVisible" @cancel="handerSelectClose">
      <a-alert
        v-if="showAlert"
        :message="alertMsg"
        type="success"
        closable
        :after-close="handleClose"
        style="margin-bottom:10px"
      />
      <a-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="columnsDetail"
      :data-source="loadDataListDetail"
      bordered
    >
      <span slot="action" slot-scope="text, record">
        <a-button @click="isPaySuccess(record)" type="link">查询付款是否成功</a-button>
      </span>
    </a-table>
    </a-modal>
  </a-card>
</template>
<script>
  import Vue from 'vue'
  import STable from '@/components/table/'
  import AInput from "ant-design-vue/es/input/Input"
  import debounce from 'lodash/debounce';
  import moment from 'moment';
  import {
    getTradeWithdrawal,
    updateQsBankInfo,
    getTradeWithdrawalDetail,
    updateQsBank,
    queryResult,
    disposeWithdrawal,
    examineRecharge,
    addEnterpriseWalletRecharge,
    getEnterpriseDownBox,
    saveSysBusinessScope,
    deleteSysBusinessScope,
  } from '@/api/api';
  export default {
    name: "RechargeOrder",
    // mixins: [JeecgListMixin],
    components: {
      AInput,
      STable
    },
    props: {
      id: {
        type: String,
        default: '',
        required: false
      },
    },
    data () {
      const cellStyle = {
        maxWidth: '120px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow:'ellipsis',
        cursor:'pointer',
      };
      return {
        maxLenth: 17,
        queryParam: {
          id: '',
          businessScope: '',
          status: undefined,
        },
        date: [],
        // 表头
        columns: [
          {
            title: '收款人姓名',
            align: 'center',
            dataIndex: 'payeeName',
            width: '150px'
          },
          {
            title: '收款人银行名称',
            align: 'center',
            dataIndex: 'payeeBankName',
            width: '150px'
          },
          {
            title: '收款人银行卡号',
            align: 'center',
            dataIndex: 'payeeBankNo',
            width: '220px'
          },
          {
            title: '提现金额(元)',
            align: 'center',
            dataIndex: 'payAmount',
            customRender: (text) => text.toFixed(2),
            width: '180px'
          },
          {
            title: '手续费金额(元)',
            align: 'center',
            dataIndex: 'feeAmount',
            customRender: (text) => text.toFixed(2),
            width: '180px'
          },
          {
            title: '到账金额(元)',
            align: 'center',
            dataIndex: 'incomeAmount',
            customRender: (text) => text.toFixed(2),
            width: '180px'
          },
          {
            title: '提现状态',
            align: 'center',
            dataIndex: 'status',
            customRender: (text) => {
              if(text==='101801'){
                return '提现未开始'
              }
              if(text==='101802'){
                return '提现处理中'
              }
              if(text==='101811'){
                return '提现成功'
              }
              if(text==='101821'){
                return '提现失败'
              }
              if(text==='101822'){
                return '提现失败已退回'
              }
            },
            width: '150px'
          },
          {
            title: '清算银行名称',
            align: 'center',
            dataIndex: 'qsBankName',
            width: '250px'
          },
          {
            title: '清算银行行号',
            align: 'center',
            dataIndex: 'qsBankCode',
            width: '250px'
          },
          {
            title: '付款失败原因',
            align: 'center',
            dataIndex: 'payError',
            width: '250px'
          },
          {
            title: '付款时间',
            align: 'center',
            dataIndex: 'payTime',
            width: '200px'
          },
          {
            title: '用户姓名',
            align: 'center',
            dataIndex: 'userNikeName',
            width: '150px'
          },
          {
            title: '钱包类型',
            align: 'center',
            dataIndex: 'walletType',
            customRender: (text) => {
              if(text==='100401'){
                return '银行账户'
              }
              if(text==='100402'){
                return '支付宝账户'
              }
              if(text==='100403'){
                return '微信账户'
              }
            },
            width: '150px'
          },
          {
            title: '操作',
            align: 'center',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
            fixed: 'right',
            width: '250px'
          }
        ],
        columnsDetail: [
          {
            title: '收款人姓名',
            align: 'center',
            dataIndex: 'payeeName',
          },
          {
            title: '收款人银行名称',
            align: 'center',
            dataIndex: 'payeeBankName',
          },
          {
            title: '收款人银行卡号',
            align: 'center',
            dataIndex: 'payeeBankNo',
          },
          {
            title: '到账金额',
            align: 'center',
            dataIndex: 'incomeAmount',
          },
          {
            title: '提现状态',
            align: 'center',
            dataIndex: 'status',
            customRender: (text) => {
              if(text==='101801'){
                return '提现未开始'
              }
              if(text==='101802'){
                return '提现处理中'
              }
              if(text==='101811'){
                return '提现成功'
              }
              if(text==='101821'){
                return '提现失败'
              }
              if(text==='101822'){
                return '提现失败已退回'
              }
            },
          },
          {
            title: '清算银行名称',
            align: 'center',
            dataIndex: 'qsBankName',
          },
          {
            title: '清算银行行号',
            align: 'center',
            dataIndex: 'qsBankCode',
          },
          {
            title: '付款失败原因',
            align: 'center',
            dataIndex: 'payError',
          },
          {
            title: '付款时间',
            align: 'center',
            dataIndex: 'resTime',
          },
          {
            title: '备注',
            align: 'center',
            dataIndex: 'remark',
          },
          {
            title: '操作',
            align: 'center',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
            width: '200px'
          }
        ],
        loadDataListDetail: [],
        loadDataList: [],
        currentId:'',
        examinForm: this.$form.createForm(this),
        addRechargeForm: this.$form.createForm(this),
        visibleExamineModal: false,
        addRechargeVisible: false,
        conpanyLists: [],
        detailId: '',
        showAlert: false,
        alertMsg: ''
      }
    },
    created () {
      this.getTradeWithdrawal()
    },
    methods: {
      // 获取电子回单
      getTradeWithdrawal () {
        getTradeWithdrawal(this.id).then(res => {
          if(res.success) {
            this.loadDataList = res.result
          }
        })
      },
      // 获取电子回单详情
      getTradeWithdrawalDetail () {
        getTradeWithdrawalDetail(this.detailId).then(res => {
          if(res.success) {
            this.loadDataListDetail = res.result
          }
        })
      },
      // 搜索企业
      handleSearch (value) {
        this.getTradeWithdrawal()
      },
      /**
       * @description: 查询企业充值列表
       * @return: void
       * @Author: HuangCheng
       */      
      handleToSearchRecharge() {
        this.getTradeWithdrawal()
      },
      // 重置
      resetSearchForm () {
        this.queryParam = {
          id: '',
          businessScope: '',
          status: undefined,
        }
        this.getTradeWithdrawal()
      },
      /**
       * @description: 修改
       * @param : record 当前行数据
       * @return: void
       * @Author: HuangCheng
       */
      handleExamine(record) {
        setTimeout(()=>{
            this.examinForm.setFieldsValue({
              qsBankName: record.qsBankName,
              qsBankCode: record.qsBankCode,
            });
        },0)
        this.currentId = record.id;
        this.visibleExamineModal = true;
      },
      // 修改
      handleRechargeModalOk() {
        this.examinForm.validateFields((err, fieldsValue) => {
          if (err) {
            return;
          }
          const description = this.examinForm.getFieldsValue();
          const params = Object.assign({}, description, {
            id: this.currentId,
          });
          updateQsBank(params).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.handleExamineModalCancel();
              this.handleToSearchRecharge();
            } else {
              this.$message.error(res.message);
            }
          })
        })
      },
      /**
       * @description: 弹窗关闭
       * @return: void
       * @Author: HuangCheng
       */
      handleExamineModalCancel() {
        this.examinForm.resetFields();
        this.visibleExamineModal = false;
      },
      // 获取企业下拉信息
      getEnterpriseDownBox (enterpriseName) {
        getEnterpriseDownBox(enterpriseName).then(res => {
          this.conpanyLists = []
          this.conpanyLists = res.result
        })
      },
      // 处理
      handleDispose (record) {
        this.$confirm({
          title: '确认处理?',
          onOk:() => {
            disposeWithdrawal({id:record.id}).then(res => {
              if(res.success) {
                this.$message.success(res.message)
                this.handleToSearchRecharge()
              } else {
                this.$message.error(res.message)
              }
            })
          },
          onCancel() {},
        });
      },
      // 查看
      selectIt (record) {
        this.detailId = record.id
        this.getTradeWithdrawalDetail()
        this.addRechargeVisible = true
      },
      handerSelectClose () {
        this.addRechargeVisible=false
        this.showAlert = false
      },
      // 查询付款是否成功
      isPaySuccess (record) {
        queryResult(record.id).then(res => {
          if(res.success) {
            this.showAlert = true
            this.alertMsg = res.result.msg
          } else {
            this.$message.error(res.message)
          }
        })
      },
      handleClose () {
        this.showAlert = false
      }
    }
  }
</script>