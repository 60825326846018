<!--
 * @Description: 用户/账户管理--充值
 * @Author: HuangCheng
 * @Date: 2020-03-11 19:37:25
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-08 15:05:44
 -->
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper" style="margin-bottom:20px">
      <a-form layout="inline">
        <a-row :gutter="24">
          <!-- <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="姓名">
              <a-input class="w-100" v-model="searchForm.userName" placeholder="请输入姓名"/>
            </a-form-item>
          </a-col> -->
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="充值ID">
              <a-input class="w-100" v-model="searchForm.id" placeholder="请输入充值ID"/>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="钱包类型">
              <a-select class="w-100" style="width:200px" defaultValue="" placeholder="请选择钱包类型" v-model="searchForm.walletType">
                <a-select-option v-for="item in walletType" :value="item.value" :key="item.value">{{item.label}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="充值方式">
              <a-select class="w-100" style="width:200px"  defaultValue="" placeholder="请选择充值方式" v-model="searchForm.rechargeType">
                <a-select-option v-for="item in rechargeType" :value="item.value" :key="item.value">{{item.label}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="状态">
              <a-select class="w-100" style="width:200px"  defaultValue="" placeholder="请选择状态" v-model="searchForm.status">
                <a-select-option v-for="item in invoiceStatus" :value="item.value" :key="item.value">{{item.label}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="时间段">
              <a-range-picker v-model="date" @change="handleDateChange" />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="12">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="handleToSearchList">查询</a-button>
              <a-button style="margin-left: 8px" icon="reload" @click="resetSearchForm">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="tableColumns"
      :data="loadAccountList"
      bordered
    >
    </s-table>
  </a-card>
</template>
<script>
  import moment from 'moment'
  import STable from '@/components/table/';
  import AInput from "ant-design-vue/es/input/Input";
  import {getArrItem} from '@/utils/util.js';
  import {
    getUserRechargeList,
    getRechargeChanneList
  } from '@/api/api';

  export default {
    name: "RechargeManage",
    components: {
      AInput,
      STable
    },
    data () {
      const cellStyle = {
        maxWidth: '120px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow:'ellipsis',
        cursor:'pointer'
      };
      return {
        searchForm: {
          userName: '',
          id: '',
          rechargeTimeStart: '',
          rechargeTimeEnd: ''
        },
        date: [],
        walletType: [
          {
            value: '100401',
            label: '银行账户'
          },{
            value: '100402',
            label: '支付宝账户'
          },{
            value: '100403',
            label: '微信账户'
          },
        ],
        rechargeType: [
          {
            value: '100901',
            label: '线上充值'
          },
          {
            value: '100911',
            label: '线下充值'
          }
        ],
        invoiceStatus: [
          {
            value: '101001',
            label: '充值中/充值审核中'
          },
          {
            value: '101011',
            label: '充值成功/充值审核成功'
          },
          {
            value: '101021',
            label: '充值失败/充值审核失败'
          }
        ],
        tableColumns: [
            {
            title: '企业名称',
            dataIndex: 'enterpriseName',
            align: 'center',
          },
          {
            title: '充值ID',
            dataIndex: 'id',
            align: 'center',
            customCell: () => {
              return {
                style: cellStyle
              }
            },
            customRender: (text) => <a-tooltip placement="top" title={text}>{text}</a-tooltip>
          },
          {
            title: '钱包类型',
            dataIndex: 'walletType',
            align: 'center',
            customRender: (text) => getArrItem(text, this.walletType)
          },
          {
            title: '充值方式',
            dataIndex: 'rechargeType',
            align: 'center',
            customRender: (text) => getArrItem(text, this.rechargeType)
          },
          {
            title: '充值金额',
            dataIndex: 'rechargeAmount',
            align: 'center',
          },
          {
            title: '充值状态',
            dataIndex: 'status',
            align: 'center',
            customRender: (text) => getArrItem(text, this.invoiceStatus)
          },
          {
            title: '充值时间',
            align: 'center',
            dataIndex: 'rechargeTime',
            customCell: () => {
              return {
                style: cellStyle
              }
            },
            customRender: (text) => <a-tooltip placement="top" title={text}>{text}</a-tooltip>
          },
          {
            title: '审核时间',
            dataIndex: 'auditTime',
            align: 'center',
            customCell: () => {
              return {
                style: cellStyle
              }
            },
            customRender: (text) => <a-tooltip placement="top" title={text}>{text}</a-tooltip>
          },
        ],
      }
    },
    methods: {
      disabledDate(current) {
        return current && current > moment().endOf('day');
      },
      /**
       * @description: 时间段选择
       * @param : date Moment
       * @param : dateString 时间数组
       * @return: void
       * @Author: HuangCheng
       */
      handleDateChange(date, dateString) {
        this.searchForm.rechargeTimeStart = `${dateString[0]} 00:00:00`;
        this.searchForm.rechargeTimeEnd = `${dateString[1]} 23:59:59`;
      },
      /**
       * @description: 加载列表数据
       * @param : parameter 查询参数
       * @return: promise
       * @Author: HuangCheng
       */
      loadAccountList(parameter) {
        return getRechargeChanneList(Object.assign(parameter, this.searchForm))
          .then(res => {
            // if (!this.$store.state.user.userRules.includes('user:setStar')) {
            //   if (res.result.data.length>0) {
            //     res.result.data.forEach(item=>{
            //       let phone=/(\d{3})\d{4}(\d{4})/
            //       let enterpriseName = /(.{2}).{1,}(.{2})/
            //       let addr = /.{1,}/
            //       let account = /.{1,}/
            //       let userName = /(.{1}).{1,}/
            //       let email = /(.{3}).{1,}/
            //       let idcard = /(\d{3})\d{11}(\w{4})/
            //       // item.enterpriseName = item.enterpriseName.replace(enterpriseName, "$1******$2")
            //       // item.phoneNo = item.phoneNo.replace(phone, "$1****$2")
            //       item.rechargeAmount = String(item.rechargeAmount).replace(account, "***")
            //       // item.idcardNo = item.idcardNo.replace(idcard, "$1***********$2")
            //     })
            //   }
            // }
            return res.result
          })
      },
      /**
       * @description: 查询用户充值列表
       * @Author: HuangCheng
       */
      handleToSearchList() {
        this.$refs.table.refresh(true);
      },
      /**
       * @description: 重置搜索条件
       * @return: void
       * @Author: HuangCheng
       */
      resetSearchForm() {
        this.searchForm = {
          userName: '',
          id: '',
          rechargeTimeStart: '',
          rechargeTimeEnd: ''
        };
        this.date = [];
        this.handleToSearchList();
      },
    },
  }
</script>