<!--
 * @Description: 开票管理
 * @Author: HuangCheng
 * @Date: 2020-02-21 15:25:15
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-22 14:28:57
 -->

<template>
 
    <div style="padding:24px;">
      <commonTitle title="发票记录" />
    <div class="table-page-search-wrapper" style="margin-bottom:50px;max-width:1450px">
      <a-form layout="inline" :form="addRechargeForm">
        <a-row :gutter="48">
          <a-col :lg="8" :md="8" :sm="12">
            <a-form-item label="企业名称">
              <a-input v-model="queryParam.enterpriseName" placeholder="企业名称" style="width:260px"/>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="8" :sm="12">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="handleToSearchRecharge">查询</a-button>
              <a-button style="margin-left: 18px" icon="reload" @click="resetSearchForm">重置</a-button>
              <!-- <a-button style="margin-left: 18px" type="primary" :loading="exportLoading" icon="export" @click="exports('发票列表')">导出</a-button> -->
            </span>
          </a-col>
        </a-row>
      </a-form> 
    </div>
    <div >
    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.index"
      :columns="columns"
      :data="loadDataList"
      bordered
      
    >
      <span slot="action" slot-scope="text, record">
        <a @click="ticketDetail(record)">详情</a>
      </span>
    </s-table>
    </div>
    <!-- 列表明细 -->
    <a-modal
      :title="title"
      :visible="visibleDetail"
      @cancel="handleCancel"
      width="100%"
      class="detail-modal"
      :footer="null"
      :dialog-style="{ top: '0px' }"
    >
      <Detail @refresh="handleToSearchList" v-if="visibleDetail" :id="currentId" />
    </a-modal>
  </div>
</template>
<script>
  import Vue from 'vue'
  import STable from '@/components/table/'
  import AInput from "ant-design-vue/es/input/Input"
  import debounce from 'lodash/debounce';
  import moment from 'moment';
  import Detail from './components/detail.vue';
  import commonTitle from "../components/commonTitle";
   import {getArrItem} from '@/utils/util.js';
  import {
    getEnterpriseInvoiceTradesAmountList,
    exportEnterpriseInvoiceTradesAmount,
    getChanneApplyInvoiceTradeList,
  } from '@/api/api';
  export default {
    name: "RechargeOrder",
    // mixins: [JeecgListMixin],
    components: {
      AInput,
      STable,
      Detail,
      commonTitle
    },
    data () {
      const cellStyle = {
        maxWidth: '120px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow:'ellipsis',
        cursor:'pointer',
      };
      return {
        queryParam: {
          id: '',
          enterpriseName: '',
          batchId: ''
        },
        // 表头
        columns: [
          {
            title: '申请单号',
            dataIndex: 'id',
            align: 'center',
            width: '280px',
            customCell: () => {
              return {
                style: cellStyle
              }
            },
            customRender: (text) => <a-tooltip placement="top" title={text}>{text}</a-tooltip>
          },
          {
            title: '企业名称',
            align: 'center',
            dataIndex: 'invoiceName',
            width: '260px',
          },
          {
            title: '实际开票金额(元)',
            align: 'center',
            dataIndex: 'actualInvoiceAmount',
            width: '150px',
            // customRender: (text) => text.toFixed(2)
          },
          
          {
            title: '发票类型',
            align: 'center',
            dataIndex: 'invoiceType',
            width: '100px',
            customRender: (text) => text==="10"?'普票':'专票'
          },
          {
            title: '状态',
            align: 'center',
            dataIndex: 'status',
            width: '150px',
            customRender: (text) => this.invoiceStatus[text]
            
          },
          
          
          {
            title: '申请时间',
            align: 'center',
            dataIndex: 'createTime',
            width: '170px',
            customCell: () => {
              return {
                style: cellStyle
              }
            },
            customRender: (text) => <a-tooltip placement="top" title={text}>{text}</a-tooltip>
          },
          {
            title: '操作',
            align: 'center',
            dataIndex: 'action',
            width: '210px',
            scopedSlots: { customRender: 'action' },
            
          }
        ],
        loadDataList: (parameter) => {
          return getChanneApplyInvoiceTradeList(Object.assign(parameter, this.queryParam))
            .then(res => {
              // if (!this.$store.state.user.userRules.includes('user:setStar')) {
              //   if (res.result.data.length>0) {
              //     res.result.data.forEach((item,index)=>{
              //       item.index = index+1
              //       let phone=/(\d{3})\d{4}(\d{4})/
              //       let enterpriseName = /(.{2}).{1,}(.{2})/
              //       let account = /.{1,}/
              //       let userName = /(.{1}).{1,}/
              //       let bank = /(\d{3})\d{1,}(\d{4})/
              //       let idcard = /(\d{3})\d{11}(\w{4})/
              //       item.enterpriseAuthName = item.enterpriseAuthName.replace(enterpriseName, "$1******$2")
              //       item.payAmount = String(item.payAmount).replace(account, "***")
              //     })
              //   }
              // } else {
              //   if (res.result.data.length>0) {
              //     res.result.data.forEach((item,index)=>{
              //       item.index = index+1
              //       item.payAmount = item.payAmount.toFixed(2)
              //     })
              //   }
              // }
              return res.result
            })
        },
        addRechargeForm: this.$form.createForm(this),
        visibleDetail: false,
        currentId: '',
        title: '',
        exportLoading: false,
        invoiceStatus:{
          '102801':'已申请',
          '102811':'处理中',
          '102831':'撤销申请',
          '102821':'处理完成',
          '102822':'已发送',
        },
      }
    },
    methods: {
      // 导出
      exports (fileName) {
        this.exportLoading = true
        if(!fileName || typeof fileName != "string"){
          fileName = "导出文件"
        }
        let param = {...this.queryParam};
        exportEnterpriseInvoiceTradesAmount(param).then((data)=>{
          this.exportLoading = false
          // console.log(data)
          if (!data) {
            this.$message.warning("文件下载失败")
            return
          } else {
            this.$message.success('导出成功')
          }
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(new Blob([data]), fileName+'.xls')
          }else{
            let url = window.URL.createObjectURL(new Blob([data]))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName+'.xls')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link) //下载完成移除元素
            window.URL.revokeObjectURL(url) //释放掉blob对象
          }
        })
      },
      // 查看详情
      checkDetail(record) {
        this.title = '列表明细' + '(' +record.enterpriseName + ')'
        this.currentId = record
        this.visibleDetail = true
      },
      ticketDetail (record) {
      window.localStorage.setItem('submenu','ticketDetail')
      window.sessionStorage.setItem('ticketInfo',JSON.stringify(record))
      this.$router.push('/ticketDetail')
    },
      handleCancel () {
        this.visibleDetail = false
      },
      /**
       * @description: 查询列表
       * @return: void
       * @Author: HuangCheng
       */      
      handleToSearchRecharge() {
        this.$refs.table.refresh(true)
      },
      // 重置
      resetSearchForm () {
        this.queryParam = {
          id: '',
          enterpriseName: '',
          batchId: ''
        }
        this.$refs.table.refresh(true)
      },
    }
  }
</script>